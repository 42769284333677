<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 p-0">
                    <div class="login-card">
                        <div>
                            <div>
                                <a class="logo">
                                    <img height="500" class="img-fluid for-light" src="../assets/images/logo/logo_login.png" alt="loginpage" style="width:40%;"/>
                                    <img class="img-fluid for-dark" src="../assets/images/logo/logo_dark.png" alt="loginpage"/>
                                </a>
                            </div>
                            <div class="login-main">
                                <form class="theme-form">
                                    <div v-if="this.register==false">
                                        <h4>Sign in to account</h4>
                                        <p>Enter your email & password to login</p>
                                        <div class="form-group">
                                            <label class="col-form-label">Email Address</label>
                                            <input class="form-control" type="email" required=""  placeholder="youremail@gmail.com" v-model="user.email.value" />
                                            <span class="validate-error" v-if=" !user.email.value || !validEmail(user.email.value)">{{ user.email.errormsg }}</span>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label">Password</label>
                                            <div class="form-input position-relative">
                                                <input class="form-control" :type="passwordFieldType" name="login[password]"
                                                    required="" placeholder="*********" v-model="user.password.value" />
                                                <span class="validate-error" v-if=" user.password.value.length < 7 " >{{ user.password.errormsg }}</span >
                                                <div class="show-hide" @click=" togglePasswordVisibility " >
                                                    <span class="show">{{ showPasswordText }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group mb-0">
                                            <div class="checkbox p-0">
                                                <input id="checkbox1" type="checkbox" v-model="user.remember.value" />
                                                <label class="text-muted" for="checkbox1">Remember me</label>
                                            </div>
                                            <!-- <router-link class="link" to="/authentication/forgetpassword">Forgot password?</router-link> -->
                                            <div class="text-end mt-3">
                                                <button class="btn btn-primary btn-block w-100" type="submit" @click.prevent="loginSubmit" >Sign in</button>
                                            </div>
                                            <div class="py-3" style="font-size:0.8rem;font-weight:400" align="center">
                                                Don't have an account? <div class="font-primary" style="cursor:pointer;" @click="openRegisterTab(true)">Register Here</div>
                                            </div>
                                        </div>   
                                    </div>
                                    <div v-else>
                                        <h4>Register</h4>
                                        <p>Fill in all the required details below to proceed</p>
                                        <div class="form-group">
                                            <label class="col-form-label">Name</label>
                                            <input class="form-control" v-model="this.newName" type="text" required="" placeholder="Name"/>
                                        </div>
                                        <div class="d-flex">
                                            <div class="form-group">
                                                <label class="col-form-label">Ic Number</label>
                                                <input class="form-control" v-model="this.newIcNo" type="text" required="" placeholder="980131045664"/>
                                            </div>
                                            <div class="p-1"></div>
                                            <div class="form-group">
                                                <label class="col-form-label">Phone</label>
                                                <input class="form-control" v-model="this.newPhone" type="text" required="" placeholder="014667894"/>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label">Email</label>
                                            <input class="form-control" v-model="this.newEmail" type="text" required="" placeholder="ahmad@gmail.com"/>
                                        </div>
                                        <div class="form-group mb-0">
                                            <div class="text-end mt-3">
                                                <button class="btn btn-primary btn-block w-100" type="button" @click="registerUser()">Register</button>
                                            </div>
                                        </div>
                                        <div class="py-3" style="font-size:0.8rem;font-weight:400" align="center">
                                            Back to<span class="font-primary" style="cursor:pointer;" @click="openRegisterTab(false)"> Sign In</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { register } from "@/helper/apiservice/axios-http";
import { login } from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "login",

    data() {
        return {
            showPassword: false,
            result: {
                email: "",
                password: "",
            },
            user: {
                remember: {
                    value: false,
                    errormsg: "",
                },
                email: {
                    value: "",
                    errormsg: "",
                },
                password: {
                    value: "",
                    errormsg: "",
                },
            },
            newName: null,
            newIcNo: null,
            newPhone: null,
            newEmail: null,
            register: false,
        };
    },

    created() {},

    computed: {
        passwordFieldType() {
            return this.showPassword ? "text" : "password";
        },
        showPasswordText() {
            return this.showPassword ? "Hide" : "Show";
        },
    },

    methods: {
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },

        validEmail: function (email) {
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },

        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },

        async loginSubmit() {
            if (
                !this.user.password.value ||
                this.user.password.value.length < 7
            ) {
                this.user.password.errormsg = "min length 7";
            } else {
                this.user.password.errormsg = "";
            }

            if (!this.user.email.value) {
                this.user.email.errormsg = "Invalid email address";
            } else if (!this.validEmail(this.user.email.value)) {
                this.user.email.errormsg = "Valid email required.";
            } else {
                this.user.email.errormsg = "";
            }

            if (!this.user.email.errormsg && !this.user.password.errormsg) {
                const dataPass = {
                    email: this.user.email.value,
                    password: this.user.password.value,
                    remember: this.user.remember.value,
                };

                try {
                    await login(dataPass).then((response) => {
                        if (response.data.message === "Email or password incorrect") {
                            this.notificationWarning("wrong credentials!");
                        } else if (response.data.success == true) {
                            // STORE USER CREDENTIALS GLOBALLY IN LOCAL STORAGE
                            localStorage.setItem( "token_credential", response.data.data.token );
                            localStorage.setItem( "token_expired",  response.data.data.expired );
                            
                            localStorage.setItem("details", JSON.stringify(response.data.data.details));
                            localStorage.setItem("user_id", response.data.data.id);
                            localStorage.setItem("role", response.data.data.role);

                            window.location.href="/dashboard/summary";
                        } else {
                            this.notificationError(response.data.message);
                        }
                    });
                } catch (error) {
                    console.log(error)
                    this.notificationError("Something went wrong!");
                }
            }
        },

        openRegisterTab(state) {
            this.register = state;
        },

        async registerUser() {
            try {
                const dataPass = {
                    name : this.newName,
                    email : this.newEmail,
                    icNumber: this.newIcNo,
                    phone : this.newPhone,
                };
                await register(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
    },
};
</script>
