<style scoped>
.inactivePage {
    color: #cccccc;
}
.activePage {
    color: #CA952B;
}
.activeButtonPage {
    color: #CA952B;
}
.inactiveButtonPage {
    color: #cccccc;
}
.form-control::placeholder {
    color: #d9d9d9 !important;
}
.selected {
    color: #CA952B;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 3px solid #CA952B;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.search-table {
    table-layout: fixed;
    margin: 0px auto 0px auto;
}
.search-table,
td,
th {
    border-collapse: collapse;
}
th {
    padding: 10px 7px;
    font-size: 15px;
    color: #52526c;
    background: #f5f6f9;
}
td {
    padding: 5px 10px;
    height: 35px;
}

.search-table-outter {
    overflow-x: scroll;
}

/* file upload button */
input[type="file"]::file-selector-button {
    border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
}

/* file upload button hover state */
input[type="file"]::file-selector-button:hover {
    background-color: #f3f4f6;
}

/* file upload button active state */
input[type="file"]::file-selector-button:active {
    background-color: #e5e7eb;
}
</style>

<template>
    <div class="h-5" style="height: 10px"></div>

    <div class="container-fluid">
        <div class="py-3"></div>
        <h5>Reward Details</h5>
        <div class="py-1"></div>
        <div class="row">
            <div class="p-2 col-lg-7 col-sm-12" style="cursor:pointer">
                <div class="card">
                    <div style="height:40vh;">
                        <img src="../../assets/images/faq/learning-1.png" style="width:100%;height:100%;border-top-left-radius: 15px;border-top-right-radius: 15px;"/>
                    </div>
                    <div class="p-2">
                        <h6 class="">PERCUTIAN KE NEGARA BESAR</h6>
                        <div class="">Valid till 9th september 2024</div>
                        <div class="p-1"></div>
                        <div class="">1000 points</div>
                    </div>
                </div>
            </div>
            <div class="p-2 col-lg-5 col-sm-12" style="cursor:pointer">
                <div class="card p-2">
                    <h6 class="py-3">Highlights</h6>
                    <div class="p-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Let</div>
                    <h6 class="py-3">Terms & conditions</h6>
                    <div class="p-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text </div>
                    <div class="py-3"></div>
                    <div class="row">
                        <div class="col-md-7 col-sm-12"></div>
                        <div class="col-md-5 col-sm-12">
                            <button class="btn btn-primary" style="width:100%">Redeem</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "Campaign Details",

    data() {
        return {
        };
    },

    beforeMount() {
    },

    mounted() {
    },

    created() {},

    computed: {
    },

    methods: {
        //--------API HANDLING -----------------//
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },
    },
};
</script>
