<style scoped>
.round {
    position: relative;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 20%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: -1px;
    width: 24px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 5px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.selected {
    color: #04318c;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 3px solid #04318c;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

/* .round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
} */

.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
    border-color: #66bb6a;
}

/* The Modal (background) */
.modal2 {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content2 {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  overflow:hidden; 
  border: 1px solid #888;
  width: 40%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.image-container {
    position: relative;
    display: inline-block; /* or use width: 100%; to make it full width */
}

.img-fluid {
    display: block;
}

.centered-content {
    position: absolute;
    top: 27%;
    left: 50%;
    height: 20vh;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 60%;
}

.logo {
    display: block;
    margin: 0 auto;
}

.centered-text {
    width:100%;
    background-color: rgba(255, 255, 255); /* Optional: to make the background semi-transparent */
    padding: 15px;
    border-radius: 15px; /* Optional: to give the div rounded corners */
    margin-top: 10px; /* Adjust this value to control the spacing between the logo and the text */
}

.select-home {
  width:100%;
  border: 1px solid #BBBBBB;
  color:#BBBBBB;
}

.select-type {
  width:50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-type {
  background:#CA952B;
  color:#FEFEFE;
  width:100%;
}

.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  padding: 0px !important;
}

.details-image {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor:pointer;
  width: 100%;
  height:100%;
  max-height: 60vh;
}
</style>
<template>
    <div class="p-1"></div>
    <div class="p-3">
      <h3 v-if="this.viewProduct==false">My Shop</h3>
      <h3 style="text-transform: capitalize ;" v-else>{{ this.selectedTab }} Details</h3>
      <div class="py-2"></div>
    </div>
    <!-- PRODUCT LIST -->
    <div v-if="this.viewProduct==false">
        <!-- TAB MENU -->
        <div class="p-3 row rounded">
            <div class="p-1 col-6 col-sm-4 col-md-3" @click="selectTab('subscription')">
              <div class="p-2 rounded" align="center" :class="{ 'selected-type': selectedTab === 'subscription' }" style="cursor:pointer">Package Subscription</div>
            </div>
            <div class="p-1 col-6 col-sm-4 col-md-3" @click="selectTab('product')">
              <div class="p-2 rounded" align="center" :class="{ 'selected-type': selectedTab === 'product' }" style="cursor:pointer">Product</div>
            </div>
        </div>
        <!-- SUBSCRIPTION PLAN -->
        <div class="p-2"></div>
        <div class="p-3" v-if="selectedTab == 'subscription' && this.current_subs_id!=null">
          <div class="card p-3">
            <div class="d-flex py-2" style="justify-content: space-between;">
              <h5>Current Subscription Details</h5>
            </div>
            <div style="border-bottom: 1px solid #cccccc"></div>
            <div class="row py-2">
                <h6 class="col-md-2 col-sm-12 py-3" style="position:relative;top:30px">Current subscription</h6>
                <div class="col-md-10 col-sm-12 py-2 d-flex">
                  <img style="border-radius:50%;height:80px;" :src="this.current_subs_image" />
                  <div class="px-2"></div>
                  <div style="position:relative;top:25px;font-size:1.2rem">{{ this.current_subs_name }}</div>
                </div>
                <h6 class="col-md-2 col-sm-12 py-2">Package description</h6>
                <div class="col-md-10 col-sm-12 py-2">{{ this.current_subs_description }}</div>
                <h6 class="col-md-2 col-sm-12 py-3">Redemption progress</h6>
                <div class="col-md-10 col-sm-12 py-3 d-flex">
                    <div class="px-1" style="width:15%;position:relative;top:15px;" align="center">
                        <svg version="1.1" fill="#CA952B" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 107.33" style="enable-background:new 0 0 122.88 107.33;width: 70%;" xml:space="preserve">
                            <g>
                                <path class="st0" d="M12.49,28.62l-0.26,0.21c1.72,2.11,2.43,4.6,2.14,7.46c-0.29,2.86-1.5,5.15-3.61,6.87l0.21,0.26 c2.11-1.72,4.6-2.43,7.46-2.13c12.88,1.34,3.67,8.8,4.99-4.07c0.29-2.86,1.5-5.15,3.61-6.87l-0.21-0.26 c-2.11,1.72-4.6,2.43-7.46,2.14C16.5,31.93,14.21,30.73,12.49,28.62L12.49,28.62L12.49,28.62z M31.26,64.84l8.86-31.19 c0.32-1.14,1.36-1.89,2.49-1.89v0H80.4c1.26,0,2.3,0.9,2.54,2.08l9.1,31.15c0.4,1.37-0.39,2.8-1.76,3.2 c-0.24,0.07-0.48,0.1-0.72,0.1v0.01H33.7c-1.43,0-2.59-1.16-2.59-2.59C31.11,65.4,31.16,65.11,31.26,64.84L31.26,64.84z M62,103.86 l8.86-31.19c0.32-1.14,1.36-1.89,2.49-1.89v0h37.79c1.26,0,2.3,0.9,2.54,2.08l9.1,31.15c0.4,1.37-0.39,2.8-1.76,3.2 c-0.24,0.07-0.48,0.1-0.72,0.1v0.01H64.44c-1.43,0-2.59-1.16-2.59-2.59C61.85,104.43,61.9,104.14,62,103.86L62,103.86z M75.3,75.96 l-7.43,26.18h48.99l-7.65-26.18H75.3L75.3,75.96z M0.15,103.86l8.86-31.19c0.32-1.14,1.36-1.89,2.49-1.89v0h37.79 c1.26,0,2.3,0.9,2.54,2.08l9.1,31.15c0.4,1.37-0.39,2.8-1.76,3.2c-0.24,0.07-0.48,0.1-0.72,0.1v0.01H2.59 c-1.43,0-2.59-1.16-2.59-2.59C0,104.43,0.05,104.14,0.15,103.86L0.15,103.86z M13.45,75.96l-7.43,26.18H55l-7.65-26.18H13.45 L13.45,75.96z M44.56,36.94l-7.43,26.18h48.99l-7.65-26.18H44.56L44.56,36.94z M44.31,0l-0.26,0.21c1.72,2.11,2.43,4.6,2.14,7.46 c-0.29,2.86-1.5,5.15-3.61,6.87l0.21,0.26c2.11-1.72,4.6-2.43,7.47-2.13c2.87,0.3,5.16,1.5,6.87,3.6l0.26-0.21 c-1.72-2.11-2.43-4.6-2.14-7.46c0.29-2.86,1.5-5.15,3.61-6.87l-0.21-0.26c-2.11,1.72-4.6,2.43-7.46,2.14 C48.32,3.31,46.03,2.11,44.31,0L44.31,0L44.31,0z M86.63,2.31l-0.47,0.39c3.14,3.86,4.45,8.41,3.91,13.65 c-0.54,5.24-2.74,9.43-6.6,12.57l0.39,0.47c3.86-3.14,8.41-4.45,13.66-3.9c5.24,0.54,9.43,2.75,12.57,6.6l0.47-0.39 c-3.14-3.86-4.45-8.41-3.91-13.65c0.54-5.24,2.74-9.43,6.6-12.57L112.85,5c-3.86,3.14-8.41,4.45-13.65,3.91 C93.96,8.37,89.77,6.17,86.63,2.31L86.63,2.31L86.63,2.31z"/>
                            </g>
                        </svg>
                    </div>
                    <div style="width:85%">
                        <div><label for="progress">Gold Available for redeem:</label></div>
                        <div><progress id="progress" value="32" max="100" style="width:90%"></progress></div>
                        <div class="py-3"></div>
                        <div class="row">
                            <div class="col-4 col-sm-8"></div>
                            <div class="col-8 col-sm-4">
                              <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#redeemGold" type="button" style="position:relative;bottom:10px;width: 100%; color: #fefefe;">Redeem</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <!-- GOLD LISTING -->
        <!-- <div class="p-2"></div>
        <h3 class="p-3" style="text-transform: capitalize;">{{ this.selectedTab }} list</h3> -->
        <div class="row p-3" style="width:100%;">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 py-2" v-for="(item, index) in this.listProduct" :key="index" @click="detailProduct(true, item)">
              <div class="bg-white rounded" style="border: 1px solid #bbbbbb;">
                  <div style="height:250px">
                    <img class="img-fluid for-light" style="border-top-right-radius: 5px;border-top-left-radius: 5px;cursor:pointer;width: 100%;height:100%;" @click="detailProduct()" :src="item.image">
                  </div>
                  <div class="p-3">
                  <div style="color:#2F2F3B;font-size:1rem;font-weight:450;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">{{ item.name }}</div>
                  <div style="color:#CA952B;font-size:0.8rem">{{ item.weight }}g</div>
                  </div>
                  <div class="p-3 d-flex" style="justify-content: space-between;">
                  <div style="cursor:pointer" @click="redirectShop()">
                      <div style="color:#2F2F3B;font-size:1.2rem;font-weight:450">RM {{ item.price }}</div>
                  </div>
                  <div class="d-flex" style="position: relative;top:5px;">
                      <h6>4.3</h6>
                      <div class="px-1"></div>
                      <svg style="position: relative;bottom:2px;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#F1BE46" class="bi bi-star-fill" viewBox="0 0 16 16">
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                      </svg>
                  </div>
                  </div>
              </div>
            </div>
        </div>
        <div class="p-2"></div>
    </div>
    <!--VIEW PRODUCT DETAILS-->
    <div class="px-3" v-else>
      <div class="card p-3">
        <div class="row">
          <div class="col-12 col-sm-6" align="center">
              <img class="img-fluid for-light details-image" :src="this.productDetails.image">
          </div>
          <div class="col-12 col-sm-6 py-3">
            <!-- PRODUCT DETAILS -->
            <div style="height:90%">
              <h4 class="py-1" style="color:#CA952B">{{ this.productDetails.name }}</h4>
              <div style="font-size:1rem">Product Weight: <span>{{ this.productDetails.weight }}g</span></div>
              <!-- <div class="py-3">{{ this.productDetails.weight }}g</div> -->
              <div style="font-size:1rem">Price:</div>
              <h5>RM {{ this.productDetails.price }}/month</h5>
              <div class="py-1" style="font-size:1rem">Product Description:</div>
              <div>{{ this.productDetails.name }}</div>
            </div>
            <!-- BACK & PURCHASE BUTTON -->
            <div class="row" style="height:10%">
              <div class="col-md-12 col-xl-6"></div>
              <div class="col-md-12 col-xl-6 row">
                <div class="col-6 p-2">
                  <button class="btn btn-dark btn-block w-100" type="submit" @click.prevent="detailProduct(false, null)">Back</button>
                </div>
                <div class="col-6 p-2">
                  <button class="btn btn-primary btn-block w-100" type="submit" @click.prevent="createOrder()">Purchase</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
</template>
<script>
import { listProduct, currentSubscription, createSubscription, purchaseProduct } from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    components: {
    },
    data() {
        return {
            agentId: localStorage.getItem('user_id'),
            viewProduct: false,
            selectedTab: 'subscription',
            productDetails: [],
            listProductRaw: null,
            listProduct: null,

            current_subs_id: null,
            current_subs_name: null,
            current_subs_price: null,
            current_subs_image: null,
            current_subs_description : null,
            redemption_progress: null,
        };
    },
    computed: {
    },
    mounted() {
    },
    created() {
      this.loadListProduct();
      this.loadCurrentsubs();
    },
    methods: {
      notificationError(message) {
          toast(message, {
              theme: "colored",
              type: "error",
          });
      },
      notificationWarning(message) {
          toast(message, {
              theme: "colored",
              type: "warning",
          });
      },
      notificationSuccess(message) {
          toast(message, {
              theme: "colored",
              type: "success",
          });
      },
      selectTab(tab) {
        if (tab == '' || tab == null) {
          this.selectedTab = this.selectedTab;
        } else {
          this.selectedTab = tab;
        }

        this.listProduct = this.listProductRaw.filter(
            (item) => item.type === this.selectedTab
        );
      },

      detailProduct(status, product) {
        if (status==true) {
          // Fill Product Details
          this.productDetails=product;
        } else {
          // Clear Product Details
          this.productDetails=[];
        } 
        this.viewProduct=status;
        
      },
      async loadListProduct() {
        this.tableloader = true;
          try {
              await listProduct(this.$router).then((response) => {
                if (response.data.message === "success") {
                    this.listProductRaw = response.data.data;
                    this.tableloader = false;
                    this.selectTab();
                } else {
                    this.notificationError("Something went wrong!");
                }
              });
          } catch (error) {
            this.notificationError("Something went wrong!");
          }
      },
      async loadCurrentsubs() {
        try {
          const dataPass = {
            user_id : localStorage.getItem("user_id"),
          };
          await currentSubscription(dataPass).then((response) => {
              console.log(response.data.data)
              if (response.data.success == true) {
                this.current_subs_id = response.data.data.subscriptions.current_subscription_id;
                this.current_subs_name = response.data.data.subscriptions.current_subscription_name;
                this.current_subs_image = response.data.data.subscriptions.current_subscription_image;
                this.current_subs_price = response.data.data.subscriptions.current_subscription_price;
                this.current_subs_description = response.data.data.subscriptions.status;
                this.subs_record = response.data.data.subscriptions.subscription_payment_list;
                this.redemption_progress = this.subs_record.length;
              } else {
                this.notificationError("Something went wrong!");
              }
          });
        } catch (error) {
          this.notificationError("Something went wrong!");
        }
      },
      async createOrder() {
        // TEMPORARY TO THANK YOU PAGE(DONE ORDER)
        // window.location.href = "/shop/success";

        try {
          const dataPass = {
            product_id : this.productDetails.id,
            agent_id : this.agentId,
          };

          if (this.selectedTab == 'subscription') {
            //call subscription API
            await createSubscription(dataPass).then((response) => {
              if (response.data.success == true) {
                window.location.href = response.data.data.short_url;
              } else {
                this.notificationError("Something went wrong!");
              }
            });
          } else {
            //call purchase product API
            await purchaseProduct(dataPass).then((response) => {
              if (response.data.success == true) {
                window.location.href = response.data.data.short_url;
              } else {
                this.notificationError("Something went wrong!");
              }
            });
          }
        } catch (error) {
          this.notificationError("Something went wrong!");
        }
      },
    },
};
</script>
