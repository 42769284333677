<style scoped>
.inactivePage {
    color: #cccccc;
}
.activePage {
    color: #CA952B;
}
.activeButtonPage {
    color: #CA952B;
}
.inactiveButtonPage {
    color: #cccccc;
}
.form-control::placeholder {
    color: #d9d9d9 !important;
}
.selected {
    color: #CA952B;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 3px solid #CA952B;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.search-table {
    table-layout: fixed;
    margin: 0px auto 0px auto;
}
.search-table,
td,
th {
    border-collapse: collapse;
}
th {
    padding: 10px 7px;
    font-size: 15px;
    color: #52526c;
    background: #f5f6f9;
}
td {
    padding: 5px 10px;
    height: 35px;
}

.search-table-outter {
    overflow-x: scroll;
}

/* file upload button */
input[type="file"]::file-selector-button {
    border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
}

/* file upload button hover state */
input[type="file"]::file-selector-button:hover {
    background-color: #f3f4f6;
}

/* file upload button active state */
input[type="file"]::file-selector-button:active {
    background-color: #e5e7eb;
}
</style>

<template>
    <div class="h-5" style="height: 10px"></div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="">
                    <div class="py-4">
                        <div style="font-size: 1.5rem; font-weight: 500;" >
                            <span v-if="this.role==='admin'">Manage Order</span>
                            <span v-else>Gold Redemption Record</span>
                        </div>
                    </div>
                    
                </div>
                <!-- Table items list -->
                <div v-if="this.tableloader === false">
                    <div class="card">
                        <!-- TITLE AND DOWNLOAD BUTTON -->
                        <div class="p-4 row" style="align-items: center">
                            <div class="col-6 col-md-9 d-flex" style=" font-size: 1rem; font-weight: 500; align-items: left; ">
                                <span v-if="this.role==='admin'">Order</span><span v-else>Gold</span> List
                            </div>
                            <div class="col-6 col-md-3 d-flex" style="cursor: pointer; align-items: right">
                                <button class="btn btn-primary"  type="button" style=" width: 100%; color: #fefefe;" @click="exportCSV()">
                                    Download
                                </button>
                            </div>
                        </div>
                        <div style="border-bottom:1px dotted #CCCCCC"></div>
                        <!-- SEARCH AND FILTER -->
                        <div class="py-3 px-4 row" style="align-items: center">
                            <div class="col-9">
                                <div class="faq-form" style="width: 100%">
                                    <input class="form-control" v-model="inputText" @input="FilterHandling(inputText)" type="text" placeholder="Search name.."/>
                                    <vue-feather class="search-icon" type="search" ></vue-feather>
                                </div>
                            </div>
                            <div class="col-3 d-flex" style="cursor: pointer; justify-content: right;">
                                <div style=" font-size: 1rem; font-weight: 450; padding-right: 10px; " >
                                    Filter
                                </div>
                                <div style="position: relative; top: 2px">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#CA952B" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div style="border-bottom:1px dotted #CCCCCC"></div>
                        <!-- Tab Title Filter -->
                        <div class="row py-1 px-4">
                            <div style="border-bottom: 1px solid #cccccc">
                                <div class="col-sm-10 row">
                                    <div style="cursor: pointer" class="p-3 col-sm-3 col-lg-2" align="center" :class="{ selected: selectedTab === '', }" @click="changeTab('')">
                                        All Orders
                                    </div>
                                    <div style="cursor: pointer" class="p-3 col-sm-3 col-lg-2" align="center" :class="{ selected: selectedTab === 'pending', }" @click="changeTab('pending')" >
                                        Packing Order
                                    </div>
                                    <div style="cursor: pointer" class="p-3 col-sm-3 col-lg-2" align="center" :class="{ selected: selectedTab === 'shipping', }" @click="changeTab('shipping')" >
                                        Shipping
                                    </div>
                                    <div style="cursor: pointer" class="p-3 col-sm-3 col-lg-2" align="center" :class="{ selected: selectedTab === 'completed', }" @click="changeTab('completed')" >
                                        Completed
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- DATA Table -->
                        <div class="search-table-outter wrapper p-4" v-if="this.goldTableDataResult">
                            <table class="search-table inner">
                                <tr>
                                    <th style="min-width: 50px;">#</th>
                                    <th style="min-width: 250px;">Agent Name</th>
                                    <th style="min-width: 150px;">Date</th>
                                    <th style="min-width: 250px;">Receiver</th>
                                    <th style="min-width: 150px;text-align:center">Status</th>
                                    <th style="min-width: 250px;text-align:center">Shipping Method</th>
                                    <th style="min-width: 250px;text-align:center">Tracking No</th>
                                    <th style="min-width: 150px;text-align:center">Invoice No</th>
                                    <th style="min-width: 150px;text-align:center">Action</th>
                                </tr>
                                <tr v-for="(item,index) in this.goldTableDataResult" :key="index" >
                                    <td>
                                        <input type="checkbox" id="orderNumber" name="orderNumber" value="7801">
                                        <label for="orderNumber"></label>
                                    </td>
                                    <td style="text-align:center">
                                        <div class="py-2" style="text-align: left;">{{ item.name_agent }}</div>
                                    </td>
                                    <td>
                                        <div class="py-2;">{{ item.date }}</div>
                                    </td>
                                    <td>
                                        <div>{{ item.name_receiver }}</div>
                                    </td>
                                    <td style="text-align: center">
                                        <div class="py-2" style="text-align: center;">{{ item.status }}</div>
                                    </td>
                                    <td style="text-align: center">
                                        <div class="py-2" style="text-align: center;">{{ item.shipping_method == null ? '-' : item.shipping_method }}</div>
                                    </td>
                                    <td style="text-align:center">
                                        <div class="py-2;">{{ item.tracking_no == null ? '-' : item.tracking_no }}</div>
                                    </td>
                                    <td>
                                        <div align="center">{{ item.invoice_no == null ? '-' : item.invoice_no }}</div>
                                    </td>
                                    <td style="text-align: center;">
                                        <div style="display: flex;justify-content: center;">
                                            <div style="cursor: pointer">
                                                <svg xmlns="http://www.w3.org/2000/svg" @click="editOrder(item)" width="20" height="20" fill="#CA952B" class="bi bi-pen-fill" viewBox="0 0 16 16" data-bs-toggle="modal" data-bs-target="#editOrder" >
                                                    <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
                                                </svg>
                                            </div>
                                            <div v-if="this.role==='admin'" style="width: 20px"></div>
                                            <div v-if="this.role==='admin'" class="deleteYearCard" style="cursor: pointer">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#CA952B" class="bi bi-trash-fill" viewBox="0 0 16 16" >
                                                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <!-- EMPTY DATA -->
                        <div v-else align="center" style="height: 300px">
                            <img style=" border-radius: 5px; width: 240px; height: 220px;" alt="" src="../../assets/images/no_data_image.png"/>
                            <h6>No Data</h6>
                        </div>

                        <!-- TABLE PAGINATION -->
                        <div class="row pagination" v-if="maxPage > 1">
                            <div class="col-md-7 col-sm-0 p-3">
                                <div class="inactivePage" style="padding-left: 30px">
                                    {{ "Page " + this.currentPage + " out of " + this.maxPage }}
                                </div>
                            </div>
                            <div class="col-md-5 col-sm-12 p-3">
                                <div class="d-flex" style=" padding-right: 30px; justify-content: flex-end; " >
                                    <button class="p-1" :class="{ ctiveButtonPage: this.currentPage != '1', inactiveButtonPage: this.currentPage == '1', }" @click="prevPage()" style=" width: 30%; border-top-left-radius: 8px; border-bottom-left-radius: 8px; border: 1px solid #dee2e6; ">
                                        Previous
                                    </button>
                                    <div @click="setFirstPage()" v-if="this.currentPage != 1" class="inactivePage p-1" align="center" style=" cursor: pointer; width: 10%; border: 1px solid #dee2e6; ">
                                        ...
                                    </div>
                                    <div @click="goToPage(pageNumber)" v-for="pageNumber in limitedPages" :key="pageNumber" :class="{ activePage: currentPage === pageNumber, inactivePage: currentPage !== pageNumber, }" class="p-1" align="center"  style=" cursor: pointer; width: 10%; border: 1px solid #dee2e6; " >
                                        {{ pageNumber }}
                                    </div>
                                    <div @click="setLastPage()" v-if="this.currentPage != this.maxPage"  class="inactivePage p-1" align="center" style=" cursor: pointer; width: 10%; border: 1px solid #dee2e6; " >
                                        ...
                                    </div>
                                    <button class="p-1" :class="{ activeButtonPage: currentPage === '1' && currentPage !== this.maxPage, inactiveButtonPage: currentPage === this.maxPage, }" @click="nextPage()" style=" width: 30%; border-top-right-radius: 8px; border-bottom-right-radius: 8px; border: 1px solid #dee2e6; " >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else style="padding-top: 30px">
                    <h6 class="mb-0 text-center">Please Wait...</h6>
                    <div class="loader-box">
                        <div class="loader-3"></div>
                    </div>
                </div>

                <!-- POPUP UPDATE GOLD ORDER -->
                <div class="modal fade modal-bookmark" id="editOrder" tabindex="-1" role="dialog" aria-hidden="true" >
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Update Order</h5>
                                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" ></button>
                            </div>
                            <div style=" max-height: 60vh; overflow: scroll; overflow-x: hidden; ">
                                <div class="modal-body row" style="justify-content: space-between">
                                    <div class="py-2 col-12 col-sm-6" style="font-size: 1rem">
                                        <div style="font-weight: 450">Order Status</div>
                                        <select v-model="this.orderStatus" class="form-select form-control-primary" name="select" required >
                                            <option value="" disabled selected>Select option</option>
                                            <option value="shipping">Shipping</option>
                                            <option value="completed">Completed</option>
                                        </select>
                                    </div>
                                    <div class="py-2 col-6 col-sm-6" style="font-size: 1rem">
                                        <div style="font-weight: 450">Shipping Method</div>
                                        <input v-model="this.shipping_method" class="form-control" type="text" placeholder="JNT, Poslaju, Ninja Van etc" required/>
                                    </div>
                                    <div class="py-2 col-6 col-sm-6" style="font-size: 1rem">
                                        <div style="font-weight: 450">Tracking Number</div>
                                        <input v-model="this.trackingNo" class="form-control" placeholder="MY19947230" type="text" required/>
                                    </div>
                                </div>
                            </div>
                            <!-- ACTION BUTTON -->
                            <div class="row p-3">
                                <div class="col-12 d-flex" style="justify-content: flex-end" >
                                    <div>
                                        <button @click="updateOrder()" data-bs-dismiss="modal" class="btn btn-primary"  type="button" style=" width: 100%; color: #fefefe; ">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    listGoldRedemption,
    updateGoldOrderStatus,
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "Setting Product",

    data() {
        return {
            role: localStorage.getItem('role'),
            user_id: localStorage.getItem('user_id'),
            searchText: "",
            selectedTab: "",
            goldTableData: null,
            goldTableDataResult: null,
            tableloader: false,
            indexNumbering: 1,
            formData: [],
            selectedPackage: "",
            currentPage: 1,
            itemsPerPage: 5,
            maxPage: 1,

            redemption_id: null,
            shipping_method: null,
            orderStatus: "",
            trackingNo: null,
        };
    },
    watch: {
    },
    beforeMount() {
        this.loadListGoldRedemption();
    },

    mounted() {},

    created() {},

    computed: {
        limitedPages() {
            const totalPages = Math.ceil(
                this.goldTableData.length / this.itemsPerPage
            );
            const displayPages = 3; // Number of pages to display
            const threshold = 3; // Threshold after which pages should shift
            let startPage = Math.max(
                1,
                this.currentPage - Math.floor(displayPages / 2)
            );
            let endPage = Math.min(totalPages, startPage + displayPages - 1);
            if (this.currentPage >= threshold) {
                startPage = this.currentPage - 1;
                endPage = Math.min(
                    totalPages,
                    this.currentPage + displayPages - 2
                );
            }
            return Array.from(
                { length: endPage - startPage + 1 },
                (_, index) => startPage + index
            );
        },
    },

    methods: {
        //FILTER CHANGE TAB
        changeTab(tabProgressName) {
            this.selectedTab = tabProgressName;
            this.FilterHandling();
        },

        // PREVIOUS PAGE FUNCTION
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.PageIndexNumber();
                this.PaginationHandling();
            }
        },

        // NEXT PAGE FUNCTION
        nextPage() {
            if (this.currentPage < this.maxPage) {
                this.currentPage++;
                this.PageIndexNumber();
                this.PaginationHandling();
            }
        },

        // SEND TO FIRST PAGE
        setFirstPage() {
            this.currentPage = 1;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        // SEND TO LAST PAGE
        setLastPage() {
            this.currentPage = this.maxPage;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        goToPage(page) {
            this.currentPage = page;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        PageIndexNumber() {
            if (this.currentPage == 1) {
                this.indexNumbering = 1;
            } else {
                if (this.currentPage == 2) {
                    this.indexNumbering = this.itemsPerPage;
                } else {
                    this.indexNumbering =
                        (this.currentPage - 1) * this.itemsPerPage;
                }
            }
        },

        // FILTER FUNCTION DATA MANAGEMENT
        FilterHandling(text) {
            //FILTER FIRST ORIGINAL DATA GIVEM
            if (this.selectedTab != "") {
                this.goldTableDataResult = this.goldTableData.filter(
                    (item) => item.status === this.selectedTab
                );
            } else {
                //If FILTER ALL, FETCH ALL DATA FROM API
                this.goldTableDataResult = this.goldTableData;
            }

            if (text != undefined) {
                this.searchText = text;
            }

            // Filter based on search text
            if (this.goldTableDataResult !== null) {
                // Filter based on search text
                if (this.searchText.trim() !== "") {
                    const searchText = this.searchText.toLowerCase().trim();
                    this.goldTableDataResult =
                        this.goldTableDataResult.filter(
                            (item) =>(item.name_agent && item.name_agent.toLowerCase().includes(searchText))
                        );
                }
            }

            //RESET PAGE BACK TO 1
            this.currentPage = 1;
            // Slice the data based on pagination
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            //ASSIGN HOW MANY PAGES FROM THE FILTERED DATA RESULT
            this.maxPage = Math.ceil(
                this.goldTableDataResult.length / this.itemsPerPage
            );

            //Assign FINAL Value OF PAGE DATA
            if (this.goldTableDataResult.length > 0) {
                this.goldTableDataResult = this.goldTableDataResult.slice(
                    startIndex,
                    endIndex
                );
            } else {
                this.goldTableDataResult = null;
            }
        },

        PaginationHandling() {
            //FILTER THE SELECTED DATA
            if (this.selectedTab != "") {
                this.goldTableDataResult = this.goldTableData.filter(
                    (item) => item.status === this.selectedTab
                );
            } else {
                this.goldTableDataResult = this.goldTableData;
            }

            // Apply text search filter if searchText is not empty
            if (this.searchText != "") {
                const searchText = this.searchText.toLowerCase().trim();
                this.goldTableDataResult = this.goldTableDataResult.filter(
                    (item) =>(item.name_agent && item.name_agent.toLowerCase().includes(searchText))
                );
            }

            // Slice the data based on pagination
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            this.maxPage = Math.ceil(
                this.goldTableDataResult.length / this.itemsPerPage
            );

            //Assign FINAL Value OF PAGE DATA
            if (this.goldTableDataResult.length > 0) {
                this.goldTableDataResult = this.goldTableDataResult.slice(
                    startIndex,
                    endIndex
                );
                this.goldTableDataResult.forEach((dataObj) => {
                    if (this.currentPage > 1) {
                        this.indexNumbering++;
                    }

                    dataObj.numbering = this.indexNumbering;
                    if (this.currentPage == 1) {
                        this.indexNumbering++;
                    }
                });
            } else {
                this.goldTableDataResult = null;
            }
        },

        formatDate(inputDateStr) {
            // Create a new Date object from the input string
            const inputDate = new Date(inputDateStr);

            // Extract year, month, and day components from the Date object
            const year = inputDate.getFullYear();
            const month = inputDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month (0 = January)
            const day = inputDate.getDate();

            // Pad single-digit month, day, and minutes values with leading zeros
            const formattedMonth = month.toString().padStart(2, '0');
            const formattedDay = day.toString().padStart(2, '0');

            // Construct the formatted date string in dd/mm/yyyy format
            const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

            return formattedDate;
        },

        //--------API HANDLING -----------------//
        checkImageIcon(image) {
            return image || this.packageImgIconPath;
        },
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },

        handleFileChange1(event) {
            // Clear existing files
            this.selectedFiles1 = [];

            // Iterate through selected files
            Array.from(event.target.files).forEach((file) => {
                // Check if the file is an image
                if (file.type.startsWith("image/")) {
                    // Create a FileReader to read the image file
                    const reader = new FileReader();

                    // Define FileReader onload event to set image preview
                    reader.onload = () => {
                        this.selectedFiles1.push({
                            file: file,
                            preview: reader.result, // Set the image preview URL
                        });
                        this.packageImgIcon = this.selectedFiles1[0].preview;
                    };

                    // Read the image file as a data URL
                    reader.readAsDataURL(file);
                }
            });
        },

        exportCSV() {
             //FILTER THE SELECTED DATA
             if (this.selectedTab != "") {
                this.goldTableData = this.goldTableData.filter(
                    (item) => item.status === this.selectedTab
                );
            } else {
                this.goldTableData = this.goldTableData;
            }

            // Apply text search filter if searchText is not empty
            if (this.searchText != "") {
                const searchText = this.searchText.toLowerCase().trim();
                this.goldTableData = this.goldTableData.filter(
                    (item) =>(item.name_agent && item.name_agent.toLowerCase().includes(searchText))
                );
            }

            const rows = this.goldTableData.map(item => [
                item.name_agent, 
                item.date, 
                item.name_receiver, 
                item.status, 
                item.shipping_method || '-', 
                item.tracking_no || '-', 
                item.invoice_no || '-'
            ]);

            // CSV header
            const csvContent = [
                ["Agent Name", "Date", "Receiver", "Status", "Shipping Method", "Tracking No", "Invoice No"],
                ...rows
            ]
            .map(e => e.join(","))
            .join("\n");

            // Create a Blob from the CSV content
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "gold_order_record.csv");
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.loadListGoldRedemption();
        },

        async loadListGoldRedemption() {
            this.tableloader = true;
            const id = null;

            // admin no need give user id since whole data will be extract
            if (this.role != 'admin') {
                id = this.user_id;
            }

            const dataPass = {
                userId: id,
            };

            try {
                await listGoldRedemption(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.goldTableData = response.data.data;
                        this.tableloader = false;
                        this.currentPage = 1;
                        this.PaginationHandling();
                        this.FilterHandling();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        // delete data
        async deletePackage(id) {
            const dataPass = {
                packageId: id,
            };

            try {
                await packageDelete(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.clearModalPackage();
                        this.loadListProduct();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        editOrder(orderDetail) {
            this.redemption_id=orderDetail.id;
            if(orderDetail.status!='pending') {
                this.orderStatus = orderDetail.status;
            } else {
                this.orderStatus = '';
            }
            
            this.trackinNo = orderDetail.tracking_no;
            
        },

        async updateOrder() {
            this.tableloader = true;
            // console.log(this.orderStatus);
            // console.log(this.redemption_id);
            // console.log(this.trackingNo);
            const dataPass = {
                orderId: this.redemption_id,
                status : this.orderStatus,
                trackingNumber: this.trackingNo,
                shippingMethod: this.shipping_method,
            };

            try {
                await updateGoldOrderStatus(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.goldTableData = response.data.data;
                        this.tableloader = false;
                        this.currentPage = 1;
                        this.loadListGoldRedemption();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                console.log(error)
                this.notificationError("Something went wrong!");
            }
        },
    },
};
</script>
