<style scoped>
.full-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh; /* Full viewport height */
}

.centered {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}
</style>
<template>
    <div class="p-3 full-page">
      <div class="card centered p-3">
        <div align="center">
          <svg xmlns="http://www.w3.org/2000/svg" style="width:50%" fill="currentColor" class="bi bi-cart-check-fill" viewBox="0 0 16 16">
            <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0m7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708"/>
          </svg>
        </div>
        <div class="p-2"></div>
        <div style="font-size:1.2rem;color: #50C878">Order completed!</div>
        <div>View your order <span style="color:#CA952B;" @click="redirectToProfile()">here</span>.</div>
      </div>
    </div>
</template>
<script>
import { listProduct } from "@/helper/apiservice/axios-http";
export default {
    components: {
    },
    data() {
        return {
        };
    },
    computed: {
    },
    mounted() {
    },
    created() {
    },
    methods: {
      redirectToProfile() {
        window.location.href = "/user/profile";
      }
    },
};
</script>
