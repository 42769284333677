<template>
    <div>
        <div class="p-3">
            <h3>Agent Family Tree</h3>
            <div class="py-1"></div>
            <div class="rounded p-3 bg-white" style="height:70vh">
                <div  id="chart_div" @click="handleClick" style="overflow: scroll;height:100%"></div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'OrgChart',
    mounted() {
        this.drawChart();
    },
    methods: {
        drawChart() {
            google.charts.load('current', { packages: ['orgchart'] });
            google.charts.setOnLoadCallback(() => {
                var data = new google.visualization.DataTable();
                data.addColumn('string', 'Name');
                data.addColumn('string', 'Manager');
                data.addColumn('string', 'ToolTip');

                // Add data rows with data attributes for event handling
                data.addRows([
                    [
                        {
                            //value
                            'v': 'Ikram',
                            //function:- html code for adjustment styling put here
                            'f': `<div class="custom-orgchart-card" data-name="Ikram" style="cursor:pointer;">` +
                                    `<img src="https://via.placeholder.com/50" style="vertical-align:middle; margin-right:10px; border-radius:50%;">` +
                                    `<div class="custom-orgchart-name">Ikram</div>` +
                                    `<div class="custom-orgchart-role">HQ</div>` +
                                `</div>`,
                            'allowCollapse' : 'true',
                        },
                        // parent
                        '', 
                        //label
                        'HQ',
                        
                    ],
                    [
                        {
                            //value
                            'v': 'Mike',
                            //function:- html code for adjustment styling put here
                            'f': `<div data-name="Mike" style="cursor:pointer;font-size:1">` +
                                    `<img src="https://via.placeholder.com/50" style="vertical-align:middle; margin-right:10px; border-radius:50%;">` +
                                    `Mike` +
                                    `<div style="color:red; font-style:italic">HQ</div>` +
                                    `</div>`,
                            'p' :{'style': 'background-color:violet;'},
                            'allowCollapse' : 'true',
                        },
                        // parent
                        '', 
                        //label
                        'HQ',
                    ],
                    [
                        {
                            'v': 'Jim',
                            'f': `<div data-name="Jim" style="cursor:pointer;font-size:1">` +
                                    `<img src="https://via.placeholder.com/50" style="vertical-align:middle; margin-right:10px; border-radius:50%;">` +
                                `Jim` +
                                `<div style="color:red; font-style:italic">Agent</div>` +
                                `</div>`,
                        },
                        'Mike', 
                        'Agent'
                    ],
                    [
                        {
                            'v': 'Amir',
                            'f': `<div data-name="Amir" style="cursor:pointer;font-size:1">` +
                                    `<img src="https://via.placeholder.com/50" style="vertical-align:middle; margin-right:10px; border-radius:50%;">` +
                                `Amir` +
                                `<div style="color:red; font-style:italic">Agent</div>` +
                                `</div>`,
                        },
                        'Mike', 
                        'Agent'
                    ],
                    [
                        {
                            'v': 'Saied',
                            'f': `<div data-name="Saied" style="cursor:pointer;font-size:1">` +
                                    `<img src="https://via.placeholder.com/50" style="vertical-align:middle; margin-right:10px; border-radius:50%;">` +
                                `Saied` +
                                `<div style="color:red; font-style:italic">Agent</div>` +
                                `</div>`,
                        }, 
                        'Mike', 
                        'Agent'
                    ],
                    [
                        {
                            'v': 'Rusydan',
                            'f': `<div data-name="Rusydan" style="cursor:pointer;font-size:1">` +
                                    `<img src="https://via.placeholder.com/50" style="vertical-align:middle; margin-right:10px; border-radius:50%;">` +
                                `Rusydan` +
                                `<div style="color:red; font-style:italic">Agent</div>` +
                                `</div>`,
                        },
                        'Mike', 
                        'Agent'
                    ],
                    [
                        {
                            'v': 'Nurin',
                            'f': `<div data-name="Nurin" style="cursor:pointer;font-size:1">` +
                                    `<img src="https://via.placeholder.com/50" style="vertical-align:middle; margin-right:10px; border-radius:50%;">` +
                                `Nurin` +
                                `<div style="color:red; font-style:italic">Agent</div>` +
                                `</div>`,
                        },
                        'Rusydan', 
                        'Agent'
                    ],
                    [
                        {
                            'v': 'Merto',
                            'f': `<div data-name="Merto" style="cursor:pointer;font-size:1">` +
                                    `<img src="https://via.placeholder.com/50" style="vertical-align:middle; margin-right:10px; border-radius:50%;">` +
                                `Merto` +
                                `<div style="color:red; font-style:italic">Agent</div>` +
                                `</div>`,
                        },
                        'Rusydan', 
                        'Agent'
                    ],
                    [
                        {
                            'v': 'vought',
                            'f': `<div data-name="vought" style="cursor:pointer;font-size:1">` +
                                    `<img src="https://via.placeholder.com/50" style="vertical-align:middle; margin-right:10px; border-radius:50%;">` +
                                `vought` +
                                `<div style="color:red; font-style:italic">Agent</div>` +
                                `</div>`,
                        },
                        'Rusydan', 
                        'Agent'
                    ],
                    [
                        {
                            'v': 'alias',
                            'f': `<div data-name="alias" style="cursor:pointer;font-size:1">` +
                                    `<img src="https://via.placeholder.com/50" style="vertical-align:middle; margin-right:10px; border-radius:50%;">` +
                                `alias` +
                                `<div style="color:red; font-style:italic">Agent</div>` +
                                `</div>`,
                        },
                        'Rusydan', 
                        'Agent'
                    ],
                    [
                        {
                            'v': 'Daniel',
                            'f': `<div data-name="Rusydan" style="cursor:pointer;font-size:1">` +
                                    `<img src="https://via.placeholder.com/50" style="vertical-align:middle; margin-right:10px; border-radius:50%;">` +
                                `Daniel` +
                                `<div style="color:red; font-style:italic">Agent</div>` +
                                `</div>`,
                        },
                        'Nurin', 
                        'Agent'
                    ],
                    [
                        {
                            'v': 'Dee',
                            'f': `<div data-name="Dee" style="cursor:pointer;font-size:1">` +
                                    `<img src="https://via.placeholder.com/50" style="vertical-align:middle; margin-right:10px; border-radius:50%;">` +
                                `Dee` +
                                `<div style="color:red; font-style:italic">Agent</div>` +
                                `</div>`,
                        },
                        'Daniel', 
                        'Agent'
                    ],
                    [
                        {
                            'v': 'Icam',
                            'f': `<div data-name="Icam" style="cursor:pointer;font-size:1">` +
                                    `<img src="https://via.placeholder.com/50" style="vertical-align:middle; margin-right:10px; border-radius:50%;">` +
                                `Icam` +
                                `<div style="color:red; font-style:italic">Agent</div>` +
                                `</div>`,
                        },
                        'Dee', 
                        'Agent'
                    ],
                ]);

                var chart = new google.visualization.OrgChart(document.getElementById('chart_div'));

                chart.draw(data, { 
                    'allowHtml': true, 
                    'allowCollapse': true 
                });
                // Collapse all nodes after drawing
                this.collapseAllNodes(chart);
            });
        },
        collapseAllNodes() {
            // Use setTimeout to ensure the chart is fully rendered before trying to collapse nodes
            setTimeout(() => {
            const nodes = document.querySelectorAll('#chart_div .google-visualization-orgchart-node');
            nodes.forEach(node => {
                const collapseButton = node.querySelector('.google-visualization-orgchart-collapse');
                if (collapseButton) {
                collapseButton.dispatchEvent(new Event('click')); // Programmatically trigger the click event
                }
            });
            }, 500); // Adjust the delay as needed
        },
        handleClick(event) {
            // Check if the clicked element has the data-name attribute
            const name = event.target.closest('[data-name]')?.getAttribute('data-name');
            if (name) {
                this.showDetails(name);
            }
        },
        showDetails(name) {
            console.log(name);
            // Or you can do more sophisticated things like opening a modal, etc.
        }
    }
}
</script>
<style scoped>
    #chart_div {
    width: 100%;
    height: 500px;
    }

    /* Custom card style */
    .custom-orgchart-card {
    background-color: #f0f0f0; /* Customize the background color */
    border: 2px solid #007bff; /* Customize the border */
    border-radius: 8px; /* Customize the border radius */
    padding: 10px; /* Add some padding */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
    text-align: center; /* Center the text */
    transition: transform 0.2s; /* Add a hover effect */
    }

    .custom-orgchart-card:hover {
    transform: scale(1.05); /* Slight zoom on hover */
    }

    /* Custom image style */
    .custom-orgchart-img {
    width: 50px;
    height: 50px;
    border-radius: 50%; /* Make the image circular */
    margin-bottom: 10px; /* Add some space below the image */
    }

    /* Custom name style */
    .custom-orgchart-name {
    font-size: 1.2rem; /* Customize the font size */
    font-weight: bold; /* Make the name bold */
    color: #333; /* Customize the text color */
    }

    /* Custom role style */
    .custom-orgchart-role {
    font-size: 0.9rem;
    color: #666; /* Lighter color for the role */
    font-style: italic; /* Italicize the role */
    }
</style>