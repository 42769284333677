import { createWebHistory, createRouter } from "vue-router";
import Body from "../components/body";
import WebsiteBody from "../components/webBody";
//// WEBSITE
import Home from "../pages/website/HomePage.vue";
import aboutUs from "../pages/website/AboutUs.vue";
import contactUs from "../pages/website/ContactUs.vue";
import ourProduct from "../pages/website/OurProduct.vue";
import termsAndConditions from "../pages/website/blankPage.vue";
////
//// dashboard
// import Calendar from "../pages/calenderView.vue";
import mainDashboard from "../pages/mainDashboard/dashboard.vue";
//// MY SHOP
// import Calendar from "../pages/calenderView.vue";
import shop from "../pages/myShop/myShop.vue";
import successOrder from "../pages/myShop/successOrder.vue";
////
//// setting
import settingCompany from "../pages/setting/settingCompany.vue";
import manageOrder from "../pages/setting/manageOrder.vue";
import settingProduct from "../pages/setting/settingProduct.vue";
import settingCampaign from "../pages/setting/settingCampaign.vue";
////
//// register User
import Registration from "../pages/userRegistration/Registration.vue";
////
//// auth
import login from "../auth/login";
////
//// reporting
import familyTree from "../pages/familyTree/familyTree";
import commingSoonReportAds from "../pages/comingsoon/comingsoon_report_ads";
import cashFlow from "../pages/Reporting/cashFlow";
////
////campaign
import campaignList from "../pages/campaign/campaignList.vue";
import campaignDetails from "../pages/campaign/campaignDetails.vue";
////
////
//// register user
import registerReferral from "../pages/register/referralRegistration";
import ForgetPassword from "../pages/authentication/forget_password";
import userProfile from "../pages/userProfile/profile.vue";

import Error404 from "../pages/error/error404";

const routes = [
     // WEBSITE PAGES
    {
        path: "/cashGold",
        component: WebsiteBody,
        children: [
            {
                path: "homepage",
                name: "homepage",
                component: Home,
                meta: {
                    title: "Home | Cash Gold Website",
                },
            },
            {
                path: "aboutUs",
                name: "aboutUs",
                component: aboutUs,
                meta: {
                    title: "About Us | Cash Gold Website",
                },
            },
            {
                path: "contactUs",
                name: "contactUs",
                component: contactUs,
                meta: {
                    title: "Contact Us | Cash Gold Website",
                },
            },
            {
                path: "productList",
                name: "productList",
                component: ourProduct,
                meta: {
                    title: "Our Product | Cash Gold Website",
                },
            },
            {
                path: "tnc",
                name: "tnc",
                component: termsAndConditions,
                meta: {
                    title: "Terms And Conditions | Cash Gold Website",
                },
            },
        ],
    },
    // DASHBOARD
    {
        path: "/dashboard",
        component: Body,
        children: [
            {
                path: "summary",
                name: "summary",
                component: mainDashboard,
                meta: {
                    title: "Summary Dashboard | Cash Gold System",
                },
            },
        ],
    },
    // SETTING PAGE
    {
        path: "/setting",
        component: Body,
        children: [
            {
                path: "product",
                name: "product",
                component: settingProduct,
                meta: {
                    title: "Setting Product | Cash Gold System",
                },
            },
            {
                path: "manageOrder",
                name: "manageOrder",
                component: manageOrder,
                meta: {
                    title: "Manage Order | Cash Gold System",
                },
            },
            {
                path: "campaign",
                name: "campaign",
                component: settingCampaign,
                meta: {
                    title: "Setting Campaign | Cash Gold System",
                },
            },
            {
                path: "company",
                name: "company",
                component: settingCompany,
                meta: {
                    title: "Company Setup | Cash Gold System",
                },
            },
        ],
    },
    //REGISTER THROUGH SYSTEM
    {
        path: "/user",
        component: Body,
        children: [
            {
                path: "register",
                name: "register",
                component: Registration,
                meta: {
                    title: "List of Client | Cash Gold System",
                },
            },
        ],
    },
    // REPORTING
    {
        path: "/report",
        component: Body,
        children: [
            {
                path: "familyTree",
                name: "familyTree",
                component: familyTree,
                meta: {
                    title: "Family Tree | Cash Gold System",
                },
            },
            {
                path: "ads",
                name: "ads",
                component: commingSoonReportAds,
                meta: {
                    title: "Ads Performance | Cash Gold System",
                },
            },
            {
                path: "cashflow",
                name: "cashflow",
                component: cashFlow,
                meta: {
                    title: "Cash Flow Report | Cash Gold System",
                },
            },
        ],
    },
    // USERS PROFILE
    {
        path: "/user",
        component: Body,
        children: [
            {
                path: "profile",
                name: "profile",
                component: userProfile,
                meta: {
                    title: "Users Profile | Cash Gold System",
                },
            },
        ],
    },
    // LOGIN PAGE
    {
        path: "/auth",
        children: [
            {
                path: "login",
                name: "login",
                component: login,
                meta: {
                    title: "Login | Cash Gold System",
                },
            },
            {
                path: "forget-password",
                name: "forget-password",
                component: ForgetPassword,
                meta: {
                    title: "Forget Password | Cash Gold System",
                },
            },
        ],
    },
    //  MY SHOP
    {
        path: "/shop",
        component: Body,
        children: [
            {
                path: "main",
                name: "main",
                component: shop,
                meta: {
                    title: "My Shop | Cash Gold System",
                },
            },
            {
                path: "success",
                name: "success",
                component: successOrder,
                meta: {
                    title: "Order Success | Cash Gold System",
                },
            },
        ],
    },
    // CAMPAIGN DETAIL
    {
        path: "/campaign",
        component: Body,
        children: [
            {
                path: "list",
                name: "list",
                component: campaignList,
                meta: {
                    title: "Campaign List | Cash Gold System",
                },
            },
            {
                path: "details",
                name: "details",
                component: campaignDetails,
                meta: {
                    title: "Campaign Details | Cash Gold System",
                },
            },
        ],
    },
    //  REGISTER USING LINK
    {
        path: "/registration",
        children: [
            {
                path: "referral",
                name: "referral",
                component: registerReferral,
                meta: {
                    title: "Registration | Cash Gold System",
                },
            },
            // {
            //     path: "checkout",
            //     name: "checkout",
            //     component: checkout,
            //     meta: {
            //         title: "Checkout | Cash Gold System",
            //     },
            // },
        ],
    },
    // ERROR 404
    {
        path: "/error",
        children: [
            {
                path: "404",
                name: "404",
                component: Error404,
                meta: {
                    title: "Not Found | Cash Gold System",
                },
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.title) document.title = to.meta.title;
    const path1 = ["/", "/auth/login", "/auth/forget-password"];
    const path2 = ["/auth/login", "/auth/forget-password"];
    const path3 = ["/"];
    // Page that does not require token_credential
    const path4 = [
        "/cashGold/homepage", 
        "/cashGold/aboutUs", 
        "/cashGold/contactUs",
        "/cashGold/productList",
        "/cashGold/tnc",
        "/registration/referral"];

    const now = new Date();
    const nowTime =
        now.getFullYear() +
        "-" +
        String(now.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(now.getDate()).padStart(2, "0") +
        " " +
        String(now.getHours()).padStart(2, "0") +
        ":" +
        String(now.getMinutes()).padStart(2, "0") +
        ":" +
        String(now.getSeconds()).padStart(2, "0");
        
    if (path1.includes(to.path) && !path4.includes(to.path) && localStorage.getItem("token_credential") && localStorage.getItem("token_expired") >= nowTime) {
        return next("/dashboard/summary");
    } else if (path3.includes(to.path) && !path4.includes(to.path) && localStorage.getItem("token_expired") <= nowTime) {
        localStorage.removeItem("token_expired");
        localStorage.removeItem("token_credential");
        return next("/cashGold/homepage");
    } else if ( !path2.includes(to.path) && !path4.includes(to.path) && (!localStorage.getItem("token_credential") || localStorage.getItem("token_credential") === undefined) && (!localStorage.getItem("token_expired") || localStorage.getItem("token_expired") === undefined) ) {
        return next("/cashGold/homepage");
    } else if (to.path) {
        function collectPaths(routes) {
            let paths = [];
            routes.forEach((route) => {
                if (route.children) {
                    route.children.map((child) => child.path).forEach(function (path) {
                        paths.push(route.path.concat("/").concat(path));
                    });
                }
            });
            return paths;
        }

        const allowedPaths = collectPaths(routes);

        if (!allowedPaths.includes(to.path)) {
            next("/error/404");
        } else {
            next();
        }
    }
});

export default router;
