<template>
    <li class="profile-nav onhover-dropdown pe-0 py-0">
        <div class="media profile-media">
            <img class="b-r-10" style="height: 35px; width: 35px" src="@/assets/images/dashboard/cartoon.svg" alt=""/>
            <div class="media-body">
                <span>{{ this.user.name }}</span>
                <p class="mb-0">{{ this.user.role }}<i class="middle fa fa-angle-down"></i></p>
            </div>
        </div>
        <ul class="profile-dropdown onhover-show-div">
            <li>
                <router-link :to="'/user/profile'"><vue-feather type="user"></vue-feather><span>My Profile</span></router-link>
            </li>
            <li>
                <a @click="userLogout()"><vue-feather type="log-in"></vue-feather ><span>Log out</span></a>
            </li>
        </ul>
    </li>
</template>

<script>
import { logout } from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "Profile",

    data() {
        return {
            //fetch user details from local storage
            user: JSON.parse(localStorage.getItem("details")),
        };
    },
    created(){
    },
    methods: {
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },

        async userLogout() {
            try {
                await logout().then((response) => {
                    if (response.data.success == true) {
                        localStorage.removeItem("token_expired");
                        localStorage.removeItem("token_credential");
                        localStorage.removeItem("user_id");
                        localStorage.removeItem("role");
                        localStorage.removeItem("details");
                        this.$router.push("/auth/login");
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
    },
};
</script>
