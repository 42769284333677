<style scoped>
.round {
    position: relative;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 20%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: -1px;
    width: 24px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 5px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.selected {
    color: #04318c;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 3px solid #04318c;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

/* .round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
} */

.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
    border-color: #66bb6a;
}

/* The Modal (background) */
.modal2 {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content2 {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  overflow:hidden; 
  border: 1px solid #888;
  width: 40%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.image-container {
    position: relative;
    display: inline-block; /* or use width: 100%; to make it full width */
}

.img-fluid {
    display: block;
}

.centered-content {
    position: absolute;
    top: 27%;
    left: 50%;
    height: 20vh;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 60%;
}

.logo {
    display: block;
    margin: 0 auto;
}

.centered-text {
    width:100%;
    background-color: rgba(255, 255, 255); /* Optional: to make the background semi-transparent */
    padding: 15px;
    border-radius: 15px; /* Optional: to give the div rounded corners */
    margin-top: 10px; /* Adjust this value to control the spacing between the logo and the text */
}

.select-home {
  width:100%;
  border: 1px solid #BBBBBB;
  color:#BBBBBB;
}

.select-type {
  width:50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-type {
  background:#CA952B;
  color:#FEFEFE;
  width:100%;
}

.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  padding: 0px !important;
}

.banner {
  margin-top: 0px;
}
.bottom {
  margin-bottom: 0px;
}

@media only screen and (max-width: 767px) {
  .banner {
    margin-top: 90px;
  }
  .bottom {
    margin-bottom: 90px;
  }
}
</style>
<template> 
  <div class="row banner p-3">
    
    <div class="col-12 col-sm-1 py-1"></div>
    <div class="col-12 col-sm-10">
      <div class="row py-3">
        <div class="col-12 col-md-5 px-1">
          <div class="py-3"></div>
          <h1>Bridging Expertise </h1>
          <h1>and Innovation</h1>
          <div class="py-3" style="width:100%">Welcome to Cashgold Plus Program, helmed by Rushidan Mansur, an ex-banker with 30 years of financial leadership. Our team, comprised of seasoned banking professionals, leverages collective expertise to redefine your financial journey.</div>
          <div class="py-2"></div>
        </div>
        <div class="col-12 col-md-2"></div>
        <div class="col-12 col-md-5 px-1">
          <div class="py-2"></div>
          <img class="img-fluid for-light" src="../../assets/images/website/aboutUs1.png" style="width:100%;height:100%" alt="loginpage"/>
        </div>
      </div>
      <div class="row py-3">
        <div class="col-12 col-md-5 px-1">
          <div class="py-2"></div>
          <img class="img-fluid for-light" src="../../assets/images/website/aboutUs2.png" style="width:100%;height:100%" alt="loginpage"/>
        </div>
        <div class="col-12 col-md-2"></div>
        <div class="col-12 col-md-5 px-1">
          <div class="py-3"></div>
          <h1>Industry-Standard Systems </h1>
          <div class="py-3" style="width:100%">Rooted in our banking background, our platform adheres to the highest industry standards. Your trust is our priority, and our commitment to precision and security reflects in every facet of our operation.</div>
          <div class="py-2"></div>
        </div>
      </div>
      <div class="row py-3">
        <div class="col-12 col-md-5 px-1">
          <div class="py-3"></div>
          <h1>Expert Team</h1>
          <div class="py-3" style="width:100%">Our diverse team of banking professionals collaborates to provide a seamless experience. From financial analysts, system manager to customer service specialists, each member contributes unique skills to elevate your financial pursuits.</div>
        </div>
        <div class="col-12 col-md-2"></div>
        <div class="col-12 col-md-5 px-1">
          <div class="py-2"></div>
          <img class="img-fluid for-light" src="../../assets/images/website/aboutUs3.png" style="width:100%;height:100%" alt="loginpage"/>
        </div>
      </div>
      <div class="row py-3">
        <div class="col-12 col-md-5 px-1">
          <div class="py-2"></div>
          <img class="img-fluid for-light" src="../../assets/images/website/aboutUs4.png" style="width:100%;height:100%" alt="loginpage"/>
        </div>
        <div class="col-12 col-md-2"></div>
        <div class="col-12 col-md-5 px-1">
          <div class="py-3"></div>
          <h1>Industry-Standard Systems </h1>
          <div class="py-3" style="width:100%">Rooted in our banking background, our platform adheres to the highest industry standards. Your trust is our priority, and our commitment to precision and security reflects in every facet of our operation.</div>
          <div class="py-2"></div>
        </div>
      </div>
      <div class="row py-3">
        <div class="col-12 col-md-5 px-1">
          <div class="py-3"></div>
          <h1>Our Vision</h1>
          <div class="py-3" style="width:100%">At CASHGOLD, we envision a future where access to gold is a pathway to financial prosperity. Our innovative program is more than a venture; it's a commitment to empower your financial aspirations.</div>
        </div>
        <div class="col-12 col-md-2"></div>
        <div class="col-12 col-md-5 px-1">
          <div class="py-2"></div>
          <img class="img-fluid for-light" src="../../assets/images/website/aboutUs5.png" style="width:100%;height:100%" alt="loginpage"/>
        </div>
      </div>
      <div class="row py-3">
        <div class="col-12 col-md-5 px-1">
          <div class="py-2"></div>
          <img class="img-fluid for-light" src="../../assets/images/website/aboutUs6.png" style="width:100%;height:100%" alt="loginpage"/>
        </div>
        <div class="col-12 col-md-2"></div>
        <div class="col-12 col-md-5 px-1">
          <div class="py-3"></div>
          <h1>Join Our Journey</h1>
          <div class="py-3" style="width:100%">Discover the fusion of tradition and modernity at Cash Gold. With a foundation laid by industry experts, fueled by passion, and built on trust, we invite you to redefine your financial journey with us.</div>
          <div class="py-2"></div>
        </div>
      </div>
      <div class="p-4"></div>
      <div class="" style="position: relative;">
        <img class="img-fluid for-light" src="../../assets/images/website/aboutUs7.png" style="width:100%;" alt="loginpage"/>
        <button class="btn btn-primary btn-block" style="border-color:#FFF8E5!important;background-color:#FFF8E5!important;color:#303030;position: absolute;top: 68%; left: 12%; transform: translate(-50%, -50%);" align="center">Learn More</button>
      </div>
      <div class="p-4"></div>
    </div>
    <div class="col-12 col-sm-1"></div>
  </div>
</template>
<script>
import { toast } from "vue3-toastify";
import { ref } from "vue";

export default {
    components: {
    },
    data() {
        return {
          selectedTab: 'Featured'
        };
    },
    computed: {
    },
    mounted() {
    },
    created() {
    },
    methods: {
      selectTab(tab) {
        this.selectedTab = tab;
      },
      redirectDetails() {
        this.$router.push("/setting/scheduler");
      }
    },
};
</script>
