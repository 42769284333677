<style scoped>
.inactivePage {
    color: #cccccc;
}
.activePage {
    color: #CA952B;
}
.activeButtonPage {
    color: #CA952B;
}
.inactiveButtonPage {
    color: #cccccc;
}
.form-control::placeholder {
    color: #d9d9d9 !important;
}
.selected-type {
  border-bottom:2px solid #CA952B;
  width:100%;
}
.search-table {
    table-layout: fixed;
    margin: 0px auto 0px auto;
}
.search-table,
td,
th {
    border-collapse: collapse;
}
th {
    padding: 10px 7px;
    font-size: 15px;
    color: #52526c;
    background: #f5f6f9;
}
td {
    padding: 5px 10px;
    height: 35px;
}

.search-table-outter {
    overflow-x: scroll;
}
</style>

<template>
    <div class="h-5" style="height: 10px"></div>

    <div class="container-fluid">
        <div class="py-3"></div>
        <h4>Setup Company</h4>
        <div class="py-1"></div>
        <div class="row">
            <div class="col-lg-12 rounded" style="background-color: #fefefe;">
                <!-- TAB  -->
                <div >
                    <div class="d-flex" style="width: 100%;">
                        <div class="select-type" @click="changeTab('company')">
                            <div class="p-3" align="center" :class="{ 'selected-type': selectedTab === 'company' }" style="cursor:pointer">Company Details</div>
                        </div>
                        <div class="select-type" @click="changeTab('gold')">
                            <div class="p-3" align="center" :class="{ 'selected-type': selectedTab === 'gold' }" style="cursor:pointer">Gold Setup</div>
                        </div>
                        <div class="select-type" @click="changeTab('delivery')">
                            <div class="p-3" align="center" :class="{ 'selected-type': selectedTab === 'delivery' }" style="cursor:pointer">Courier Setup</div>
                        </div>
                    </div>
                </div>
                <!-- TAB DETAILS -->
                <!-- company details -->
                <div class="p-3 row" v-if="this.selectedTab === 'company'">
                    <div class="col-lg-6 col-md-12">
                        <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Company Name</div>
                        <div style="display: flex; align-items: center;">
                            <input v-model="this.company_name" class="form-control" type="text" placeholder="Ahmad"/>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Phone</div>
                        <div style="display: flex; align-items: center;">
                            <input v-model="this.company_phone" class="form-control" type="number" placeholder="0136654321"/>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Email Address</div>
                        <div style="display: flex; align-items: center;">
                            <input class="form-control" type="text" v-model="this.company_email" placeholder="company@gmail.com"/>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Address 1</div>
                        <div style="display: flex; align-items: center;">
                            <input v-model="this.company_address1" class="form-control" type="text" placeholder="input your address"/>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Address 2</div>
                        <div style="display: flex; align-items: center;">
                            <input v-model="this.company_address2" class="form-control" type="text" placeholder="input your address"/>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Postcode</div>
                        <div style="display: flex; align-items: center;">
                            <input v-model="this.company_postcode" class="form-control" type="text" placeholder="57640"/>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">State</div>
                        <div style="display: flex; align-items: center;">
                            <input v-model="this.company_state" class="form-control" type="text" placeholder=""/>
                        </div>
                    </div>
                    <div class="py-3"></div>
                    <div class="" align="right">
                        <button class="btn" @click="updateCompany()" type="button" style=" width: 20%;color:#fefefe;background-color: #CA952B !important;border-color: #CA952B !important;">Save</button>
                    </div>
                </div>
                <!-- Gold -->
                <div class="p-3 row" v-if="this.selectedTab === 'gold'">
                    <div class="col-12">
                        <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Gold price(g)</div>
                        <div style="display: flex; align-items: center;">
                            <input class="form-control" value="1" style="width:10%" disabled/>
                            <div class="px-1" style="font-weight:500">g</div>
                            <div class="px-2" align="center">=</div>
                            <div class="px-1" style="font-weight:500">RM</div>
                            <input class="form-control" v-model="this.goldPriceGram" type="text" style="width:30%"/>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Min weight gold redeem(g)</div>
                        <div style="display: flex; align-items: center;width:51%">
                            <input v-model="this.minGoldRedeemSetup" class="form-control" type="text" placeholder="100"/>
                        </div>
                    </div>
                    <div class="py-3"></div>
                    <div class="" align="right">
                        <button @click="updateGold()" class="btn" type="button" style=" width: 20%;color:#fefefe;background-color: #CA952B !important;border-color: #CA952B !important;">Save</button>
                    </div>
                </div>
                <!-- Delivery Setup -->
                <div class="p-3" v-if="this.selectedTab === 'delivery'">
                    <div class="d-flex px-3" style="justify-content: space-between;">
                        <h5>Courier List</h5>
                        <button class="btn btn-primary btn-block" type="button" data-bs-toggle="modal" data-bs-target="#setCourier">Add courier</button>
                    </div>
                    
                    <div class="search-table-outter wrapper p-3">
                        <table class="search-table inner" style="width:100%;table-layout: fixed; min-width: 600px;">
                            <tr>
                                <th style="width:5%">#</th>
                                <th style="width:20%">Courier Name</th>
                                <th style="width:20%">Region</th>
                                <th style="width:20%">Weight(kg)</th>
                                <th style="width:20%">Price(RM)</th>
                                <th style="width:15%">Action</th>
                            </tr>
                            <tr v-for="(index) in 6" :key="index" >
                                <td>{{ index }}</td>
                                <td>Post Flash</td>
                                <td>Semenanjung</td>
                                <td>0.5kg</td>
                                <td>10.00</td>
                                <td>
                                    <div style="display: flex;">
                                        <div style="cursor: pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" @click=" intializeEditPackage(item) " width="20" height="20" fill="#CA952B" class="bi bi-pen-fill" viewBox="0 0 16 16" data-bs-toggle="modal" data-bs-target="#setCourier" >
                                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
                                            </svg>
                                        </div>
                                        <div style="width: 20px"></div>
                                        <div class="deleteYearCard" style="cursor: pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" @click=" deletePackage(item.id) " width="20" height="20" fill="#CA952B" class="bi bi-trash-fill" viewBox="0 0 16 16" >
                                                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
                                            </svg>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="py-3"></div>
        </div>
        <!-- POPUP ADD/EDIT COURIER -->
        <div class="modal fade modal-bookmark" id="setCourier" tabindex="-1" role="dialog" aria-hidden="true" >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Add Courier</h5>
                        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div>
                        <div class="modal-body row" style="justify-content: space-between">
                            <div class="py-2 col-12 col-sm-6">
                                <div style="font-weight: 450;">Courier Name</div>
                                <input v-model="this.courierName" class="form-control" type="text" placeholder="500"/>
                            </div>
                            <div class="py-2 col-12 col-sm-6">
                                <div style="font-weight: 450;width:50%">Region</div>
                                <select v-model="this.courierRegion" class="form-select form-control-primary" name="select" required >
                                    <option value="" disabled selected>Select region</option>
                                    <option value="subscription">Semenanjung</option>
                                    <option value="product">Sabah/Sarawak</option>
                                </select>
                            </div>
                            <div class="py-2 col-12 col-sm-6">
                                <div style="font-weight: 450;width:50%">Weight</div>
                                <input v-model="this.courierWeight" class="form-control" type="text" placeholder="500"/>
                            </div>
                            <div class="py-2 col-12 col-sm-6">
                                <div style="font-weight: 450;width:50%">Price</div>
                                <input v-model="this.courierPrice" class="form-control" type="text" placeholder="500"/>
                            </div>
                        </div>
                    </div>
                    <div class="row p-3">
                        <div class="col-12 d-flex" style="justify-content: flex-end" >
                            <div>
                                <button @click="saveCourier()" data-bs-dismiss="modal" class="btn btn-primary"  type="button">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { companyDetails, updateGold, updateCompany, } from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "Setup Client",

    components: {
    },

    data() {
        return {
            selectedTab: "company",
            company_name: null,
            company_phone: null,
            company_email: null,
            company_address1: null,
            company_address2: null,
            company_postcode: null,
            company_state: '',

            goldPriceGram : null,
            minGoldRedeemSetup: null,

            courierName : null,
            courierRegion : '',
            courierWeight : null,
            courierPrice : null,
        };
    },

    beforeMount() {
    },

    mounted() {
        this.companyDetails();
    },

    created() {
    },

    computed: {
    },

    methods: {
        //TOAST NOTIFICATION
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },

        //FILTER CHANGE TAB
        changeTab(tabProgressName) {
            this.selectedTab = tabProgressName;
            // this.FilterHandling();
        },
        //CHECK PASSWORD TO MATCH EACH OTHER
        checkPass() {
            if (this.new_password == this.confirm_password) {
                this.matchPassword = true;
            } else {
                this.matchPassword = false;
            }
        },
        //fetch user detaiils from API
        async companyDetails() {
            try {
                const dataPass = {
                    user_id : localStorage.getItem("user_id"),
                };
                await companyDetails().then((response) => {
                    if (response.data.success == true) {
                        this.company_name = response.data.data.name;
                        this.company_phone= response.data.data.phone;
                        this.company_email= response.data.data.email;
                        this.company_address1= response.data.data.address1;
                        this.company_address2= response.data.data.address2;
                        this.company_postcode= response.data.data.postCode;
                        this.company_state= response.data.data.state;
                        this.minGoldRedeemSetup = response.data.data.setupGold.minWeightGoldRedeem;
                        this.goldPriceGram = response.data.data.setupGold.price;
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                console.log(error)
                this.notificationError("Something went wrong!");
            }
        },
        async updateCompany() {
            try {
                const dataPass = {
                    name: this.company_name,
                    phone: this.company_phone,
                    email: this.company_email,
                    address1: this.company_address1,
                    address2: this.company_address2,
                    postCode: this.company_postcode,
                    state: this.company_state,
                };
                await updateCompany(dataPass).then((response) => {
                    if (response.data.success == true) {
                        this.notificationSuccess("Detail Updated");
                        this.companyDetails();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
        async updateGold() {
            try {
                const dataPass = {
                    price: this.goldPriceGram,
                    min_weight_gold: this.minGoldRedeemSetup,
                };
                await updateGold(dataPass).then((response) => {
                    if (response.data.success == true) {
                        this.notificationSuccess("Detail Updated");
                        this.companyDetails();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
    },
};
</script>