<template>
    <div>
        <div class="p-3" style="background-color: #CA952B">
            <div class="d-flex" style="color:#fefefe;justify-content: space-around;">
                <div class="p-3" style="width:15%">
                </div>
                <div class="p-3" style="width: 70%">
                    <div class="d-md-flex d-none">
                        <div style="width:60%">
                            <h5 class="py-2">Cashgold</h5>
                            <div style="width:60%; font-size:0.6rem; font-weight:300">
                                Welcome to Cashgold Plus Program, helmed by Rushidan Mansur, an ex-banker with 30 years of financial leadership. Our team, comprised of seasoned banking professionals, leverages collective expertise to redefine your financial journey.
                            </div>
                            <div class="py-4"></div>
                            <div class="d-flex">
                                <div class="py-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#fefefe" viewBox="0 0 448 512" width="15"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
                                </div>
                                <div class="px-2"></div>
                                <div class="py-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#fefefe" viewBox="0 0 448 512" width="15"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg>
                                </div>
                                <div class="px-2"></div>
                                <div class="py-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#fefefe" viewBox="0 0 448 512" width="15"><path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.3V327.7h-63V256h63v-54.6c0-62.2 37-96.5 93.7-96.5 27.1 0 55.5 4.8 55.5 4.8v61h-31.3c-30.8 0-40.4 19.1-40.4 38.7V256h68.8l-11 71.7h-57.8V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0 -48-48z"/></svg>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex" style="width:40%;">
                            <div style="width:50%">
                                <h6 class="py-2">My account</h6>
                                <div style="font-weight:200;font-size:0.6rem">
                                    <div class="py-1">Sign In</div>
                                </div>
                            </div>
                            <div style="width:50%">
                                <h6 class="py-2">Information</h6>
                                <div style="font-size:0.6rem;">
                                    <div class="py-1"><a href="/cashGold/tnc" style="color:#FEFEFE">Shipping & Collection Policy</a></div>
                                    <div class="py-1"><a href="/cashGold/tnc" style="color:#FEFEFE">Sellback policy</a></div>
                                    <div class="py-1"><a href="/cashGold/tnc" style="color:#FEFEFE">Exhange, Return & Refund Policy</a></div>
                                    <div class="py-1"><a href="/cashGold/tnc" style="color:#FEFEFE">Terms & Conditions</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="py-2"></div>
                    <div style="border-bottom:0.5px solid rgb(254,254,254,0.4)"></div>
                    <div class="py-2"></div>
                    <div class="d-flex py-2" style="justify-content: space-between;">
                        <div>Copyright © 2024 CashGold Sdn Bhd. All Rights Reserved.</div>
                        <div align="right">
                            <img class="img-fluid for-light" src="../assets/images/website/FPX-logo.png" style="cursor:pointer;width:15%;" alt="loginpage"/>
                        </div>
                    </div>
                </div>
                <div class="p-3" style="width:15%">
                    <!-- <div style="font-size:0.7rem">Search site</div>
                    <div>
                        <input class="form-control" type="text" style="height: 27px;"/>
                        <div class="d-flex">
                            <div class="p-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#fefefe" viewBox="0 0 448 512" width="20"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
                            </div>
                            <div class="p-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#fefefe" viewBox="0 0 448 512" width="20"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg>
                            </div>
                            <div class="p-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#fefefe" viewBox="0 0 448 512" width="20"><path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.3V327.7h-63V256h63v-54.6c0-62.2 37-96.5 93.7-96.5 27.1 0 55.5 4.8 55.5 4.8v61h-31.3c-30.8 0-40.4 19.1-40.4 38.7V256h68.8l-11 71.7h-57.8V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0 -48-48z"/></svg>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- <footer class="footer" style="background-color: #CA952B;" :class="[ footer === 'footer-dark' ? 'footer-dark' : '', { close_icon: !togglesidebar },]">
            <div class="container-fluid">
                <div >
                    <div class="col-md-12 footer-copyright text-left" align="right">
                        <p class="mb-0" style="font-weight:450">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="10" height="10"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm117.1 346.8c-1.6 1.9-39.8 45.7-109.9 45.7-84.7 0-144.5-63.3-144.5-145.6 0-81.3 62-143.4 143.8-143.4 67 0 102 37.3 103.4 38.9a12 12 0 0 1 1.2 14.6l-22.4 34.7c-4 6.3-12.8 7.4-18.2 2.3-.2-.2-26.5-23.9-61.9-23.9-46.1 0-73.9 33.6-73.9 76.1 0 39.6 25.5 79.7 74.3 79.7 38.7 0 65.3-28.3 65.5-28.6 5.1-5.6 14.1-5 18.5 1.1l24.5 33.6a12 12 0 0 1 -.6 14.9z"/></svg> Landcartel 2024
                        </p>
                    </div>
                </div>
            </div>
        </footer> -->
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
    name: "footerpage",
    data() {
        return {
            customizer: false,
        };
    },
    computed: {
        ...mapState({
            togglesidebar: (state) => state.menu.togglesidebar,
        }),
        ...mapGetters({
            footer: "layout/footer",
        }),
    },
};
</script>
