<style scoped>
.round {
    position: relative;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 20%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: -1px;
    width: 24px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 5px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.selected {
    color: #04318c;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 3px solid #04318c;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

/* .round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
} */

.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
    border-color: #66bb6a;
}

/* The Modal (background) */
.modal2 {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content2 {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  overflow:hidden; 
  border: 1px solid #888;
  width: 40%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.image-container {
    position: relative;
    display: inline-block; /* or use width: 100%; to make it full width */
}

.img-fluid {
    display: block;
}

.centered-content {
    position: absolute;
    top: 27%;
    left: 50%;
    height: 20vh;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 60%;
}

.logo {
    display: block;
    margin: 0 auto;
}

.centered-text {
    width:100%;
    background-color: rgba(255, 255, 255); /* Optional: to make the background semi-transparent */
    padding: 15px;
    border-radius: 15px; /* Optional: to give the div rounded corners */
    margin-top: 10px; /* Adjust this value to control the spacing between the logo and the text */
}

.select-home {
  width:100%;
  border: 1px solid #BBBBBB;
  color:#BBBBBB;
}

.select-type {
  width:50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-type {
  background:#CA952B;
  color:#FEFEFE;
  width:100%;
}

.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  padding: 0px !important;
}

</style>
<template> 
  <div class="d-flex" style="justify-content: space-around;">
    <div style="width:80%;">
      <div class="py-3"></div>
      <h4>Registration</h4>
      <div class="card p-3">
        <div class="row">
          <!-- REGISTER FILL IN FORM -->
          <div class="col-12" v-if="!this.registerSuccess">
            <div class="row">
              <div class="col-md-6 col-sm-12">
                  <img src="../../assets/images/banner/Become-an-Agent.png" style="width: 100%; height: 100%;" />
              </div>
              <div class="col-md-6 col-sm-12 ">
                <div class="row">
                  <div class="col-12">
                    <h2 style="color:#CA952B">Register and become part of our team now!</h2>
                    <h5>More excitements & rewards await!</h5>
                    <div>*Kindly fill in all the required details below and ensure that your email is correct as account information will be send to your email.</div>
                  </div>
                  <div class="py-3"></div>
                  <div class="py-1 col-12" style="font-size: 1rem">
                    <div style="font-weight: 450">Role<span style="color:red">*</span></div>
                    <!-- For agent default role to user only -->
                    <select v-model="this.user_role" class="form-select form-control-primary" name="select">
                        <option :value="item.id" v-for="(item, index) in this.roleListDropdown" :key="index" selected disabled>{{ item.name }}</option>
                    </select>
                  </div>
                  <div class="py-1 col-12" style="font-size: 1rem">
                    <div style="font-weight: 450"> Full Name<span style="color:red">*</span></div>
                    <input v-model="this.user_name" class="form-control" type="text" placeholder="Ahmad"/>
                  </div>
                  <div class="py-1 col-md-6 col-sm-12" style="font-size: 1rem">
                    <div style="font-weight: 450"> Email<span style="color:red">*</span></div>
                    <input v-model="this.user_email" class="form-control" type="text" placeholder="user@gmail.com"/>
                  </div>
                  <div class="py-1 col-md-6 col-sm-12" style="font-size: 1rem">
                    <div style="font-weight: 450"> Contact Number<span style="color:red">*</span></div>
                    <input v-model="this.user_phone" class="form-control" type="text" placeholder="0148876776" />
                  </div>
                  <div class="py-4"></div>
                  <div class="col-12 row">
                    <div class="col-lg-8 col-md-12"></div>
                    <div class="col-lg-4 col-md-12"><button class="btn btn-primary" style="width:100%" :disabled="disabledClick" @click="registerUser()">Submit</button></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- SUCCESS REGISTER  -->
          <div class="p-3" v-else>
            <div class="d-flex align-items-center justify-content-center">
              <div style="font-size: 1.1rem">
                <div align="center">
                  <svg xmlns="http://www.w3.org/2000/svg" style="width:100%; height:30vh" fill="#4BB543" class="bi bi-person-check" viewBox="0 0 16 16">
                    <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
                    <path d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"/>
                  </svg>
                </div>
                Acccount sucessfully registered. Please check your email for the account login details.
                <div align="center"><a href="/cashGold/homepage">Click here</a> to access to cashGold website.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { registerUser, getRoles} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";
import { ref } from "vue";

export default {
    components: {
    },
    data() {
        return {
          role: this.$route.query.role,
          referralId: this.$route.query.referral,
          roleListDropdown: [],
          user_role: '',
          user_name: '',
          user_phone: '',
          user_email: '',
          registerSuccess: false,
        };
    },
    computed: {
      disabledClick() {
        // This will return true if any of the fields are empty
        return !(this.user_name && this.user_email && this.user_phone);
      }
    },
    mounted() {
    },
    created() {
      this.getRoles();
      console.log(this.role)
    },
    methods: {
      //TOAST NOTIFICATION
      notificationError(message) {
          toast(message, {
              theme: "colored",
              type: "error",
          });
      },
      notificationWarning(message) {
          toast(message, {
              theme: "colored",
              type: "warning",
          });
      },
      notificationSuccess(message) {
          toast(message, {
              theme: "colored",
              type: "success",
          });
      },

      //get roles
      async getRoles() {
        this.tableloader = true;
        try {
            await getRoles(this.$router).then((response) => {
                if (response.data.success == true) {
                  this.roleListDropdown = response.data.data;
                  console.log(this.roleListDropdown);
                  // set default register role value depends on the link id
                  const matchingRole = this.roleListDropdown.filter(
                    (item) => item.id && item.id.toLowerCase().includes(this.role)
                  );
                  
                  if (matchingRole.length > 0) {
                    this.user_role = matchingRole[0].id; // Assuming there's only one match, take the id
                  }

                  this.roleListDropdown=matchingRole;
                  
                } else {
                    this.notificationError("Something went wrong!");
                }
            });
        } catch (error) {
            this.notificationError("Something went wrong!");
        }
      },
      // register User
      async registerUser() {
          const dataPass = {
              role: this.user_role,
              name : this.user_name,
              email: this.user_email,
              phone: this.user_phone,
              referralId: this.referral_id,
          };
          try {
              await registerUser(dataPass).then((response) => {
                  if (response.data.success == true) {
                    this.registerSuccess = true
                  } else {
                    this.notificationError("Something went wrong!");
                  }
              });
          } catch (error) {
              this.notificationError("Something went wrong!");
          }
      },
    },
};
</script>
