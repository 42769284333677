export var menuItems = {
    data: [],
};

// Check the role from localStorage
const role = localStorage.getItem("role");

// Clear existing menu items
menuItems.data = [];
if (role == 'agent') {
    // Agent-specific menu items
    menuItems.data.push(
        // GENERAL
        {
            headTitle1: "General",
            headTitle2: "Dashboard Menu",
            type: "headtitle",
        },
        {
            title: "Dashboard",
            icon: "stroke-home",
            iconf: "fill-home",
            type: "link",
            badgeType: "light-primary",
            active: false,
            path: "/dashboard/summary",
        },
        {
            title: "Users & Registration",
            icon: "stroke-user",
            iconf: "fill-user",
            type: "link",
            badgeType: "light-primary",
            active: false,
            path: "/user/register",
        },
        {
            title: "My Shop",
            icon: "stroke-user",
            iconf: "fill-user",
            type: "link",
            badgeType: "light-primary",
            active: false,
            path: "/shop/main",
        },
        // REPORTING
        {
            headTitle1: "REPORTING",
            headTitle2: "reporting menu",
            type: "headtitle",
        },
        {
            title: "Agent Family Tree",
            path: "/report/familyTree",
            icon: "stroke-user",
            iconf: "fill-file",
            type: "link",
            active: false,
        },
        {
            title: "Gold Redemption",
            path: "/setting/manageOrder",
            icon: "stroke-blog",
            iconf: "fill-file",
            type: "link",
            active: false,
        },
        {
            title: "Cash Flow",
            path: "/report/cashflow",
            icon: "stroke-internationalization",
            iconf: "fill-file",
            type: "link",
            active: false,
        },
    );
} else if (role == 'admin') {
    // HQ-specific menu items
    menuItems.data.push(
        // GENERAL
        {
            headTitle1: "General",
            headTitle2: "Dashboard Menu",
            type: "headtitle",
        },
        {
            title: "Dashboard",
            icon: "stroke-home",
            iconf: "fill-home",
            type: "link",
            badgeType: "light-primary",
            active: false,
            path: "/dashboard/summary",
        },
        {
            title: "Users & Registration",
            icon: "stroke-user",
            iconf: "fill-user",
            type: "link",
            badgeType: "light-primary",
            active: false,
            path: "/user/register",
        },
        // SETTING MENU
        {
            headTitle1: "Setting",
            headTitle2: "setting menu",
            type: "headtitle",
        },
        {
            title: "Product",
            path: "/setting/product",
            icon: "stroke-user",
            iconf: "fill-user",
            type: "link",
            badgeType: "light-primary",
            active: false,
        },
        {
            title: "Manage Order",
            path: "/setting/manageOrder",
            icon: "stroke-calendar",
            iconf: "fill-form",
            type: "link",
            badgeType: "light-primary",
            active: false,
        },
        {
            title: "Campaign",
            path: "/setting/campaign",
            icon: "stroke-social",
            iconf: "fill-form",
            type: "link",
            badgeType: "light-primary",
            active: false,
        },
        {
            title: "Company",
            path: "/setting/company",
            icon: "stroke-social",
            iconf: "fill-form",
            type: "link",
            badgeType: "light-primary",
            active: false,
        },
        // REPORTING
        {
            headTitle1: "REPORTING",
            headTitle2: "reporting menu",
            type: "headtitle",
        },
        {
            title: "Agent Family Tree",
            path: "/report/familyTree",
            icon: "stroke-user",
            iconf: "fill-file",
            type: "link",
            active: false,
        },
        {
            title: "Gold Redemption",
            path: "/report/ads",
            icon: "stroke-blog",
            iconf: "fill-file",
            type: "link",
            active: false,
        },
        {
            title: "Cash Flow",
            path: "/report/cashflow",
            icon: "stroke-internationalization",
            iconf: "fill-file",
            type: "link",
            active: false,
        },
    )
}

