<style scoped>
.inactivePage {
    color: #cccccc;
}
.activePage {
    color: #CA952B;
}
.activeButtonPage {
    color: #CA952B;
}
.inactiveButtonPage {
    color: #cccccc;
}
.form-control::placeholder {
    color: #d9d9d9 !important;
}
.selected {
    color: #CA952B;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 3px solid #CA952B;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.search-table {
    table-layout: fixed;
    margin: 0px auto 0px auto;
}
.search-table,
td,
th {
    border-collapse: collapse;
}
th {
    padding: 10px 7px;
    font-size: 15px;
    color: #52526c;
    background: #f5f6f9;
}
td {
    padding: 5px 10px;
    height: 35px;
}

.search-table-outter {
    overflow-x: scroll;
}
</style>

<template>
    <div class="h-5" style="height: 10px"></div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="">
                    <div class="p-4 row">
                        <div class="col-lg-10 col-md-9 col-6 d-flex align-items-center" style="font-size: 1.2rem; font-weight: 500;">
                            <span>User</span>
                        </div>
                        <div class="col-lg-2 col-md-3 col-6" align="right">
                            <button data-bs-toggle="modal" data-bs-target="#addClient" class="p-2" style="border-radius: 5px; background-color: #CA952B; color: #fefefe; width: 100%; border: none;  display: flex; justify-content: space-evenly; align-items: center;">
                                <svg xmlns="http://www.w3.org/2000/svg" style="width: 10%" fill="currentColor" class="bi bi-plus-square" viewBox="0 0 16 16">
                                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                </svg>
                                Register User
                            </button>
                        </div>
                    </div>
                </div>

                <!-- ROLE TAB -->
                <div class="row">
                    <div class="col-lg-3 col-sm-6 p-2" v-for="(item,index) in this.roleListDropdown" :key="index">
                        <div class="bg-white rounded  p-3 d-flex" style="box-shadow: 0 1px 2px rgba(56, 65, 74, .15);">
                            <div class="rounded-circle d-flex align-items-center justify-content-center fs-18 avatar-sm" style="font-size:2rem;background-color: rgb(202,149,43,0.6);width:28%;height:70px">
                                <span v-if="item.name=='admin'">A</span>
                                <span v-else>a</span>
                            </div>
                            <div class="px-3" style="position:relative;top:22px;left:10px;font-size:1rem;width:68%">{{ item.name }}</div>
                            <div class="px-3" style="position:relative;top:22px;font-size:1rem;width:4%;cursor:pointer" align="right" data-bs-toggle="modal" data-bs-target="#shareLink" @click="setRoleId(item.id)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
                                    <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- POPUP REGISTER USER MANUALLY -->
                <div class="modal fade modal-bookmark" id="addClient" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">
                                    <span v-if="this.clientId === null" >Add</span><span v-else>Register</span> User
                                </h5>
                                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div style="max-height: 60vh;overflow: hidden;">
                                <div class="modal-body row" style="justify-content: space-between">
                                    <div class="py-2 col-12" style="font-size: 1rem">
                                        <div style="font-weight: 450">Role<span style="color:red">*</span></div>
                                        <select v-model="this.user_role" class="form-select form-control-primary" name="select" required v-if="this.role=='admin'">
                                            <option value="" disabled selected>Select Role</option>
                                            <option :value="item.id" v-for="(item, index) in this.roleListDropdown" :key="index">{{ item.name }}</option>
                                        </select>
                                        <!-- For agent default role to user only -->
                                        <select v-model="this.agentRoleId" class="form-select form-control-primary" name="select" required v-else disabled>
                                            <option :value="this.agentRoleId" selected>Agent</option>
                                        </select>
                                    </div>
                                    <div class="py-2 col-12" style="font-size: 1rem">
                                        <div style="font-weight: 450"> Full Name<span style="color:red">*</span></div>
                                        <input v-model="this.user_name" class="form-control" type="text" placeholder="Ahmad"/>
                                    </div>
                                    <div class="py-2 col-6" style="font-size: 1rem">
                                        <div style="font-weight: 450"> Email<span style="color:red">*</span></div>
                                        <input v-model="this.user_email" class="form-control" type="text" placeholder="user@gmail.com"/>
                                    </div>
                                    <div class="py-2 col-6" style="font-size: 1rem">
                                        <div style="font-weight: 450"> Contact Number<span style="color:red">*</span></div>
                                        <input v-model="this.user_phone" class="form-control" type="text" placeholder="0148876776" />
                                    </div>
                                </div>
                            </div>
                            <div class="row p-3">
                                <div class="col-12 col-md-9" ></div>
                                <div class="col-12 col-md-3" >
                                    <div>
                                        <!-- Bind 'disabled' attribute to computed property 'isFormValid' -->
                                        <button @click="registerUser()" data-bs-dismiss="modal" class="btn-primary" type="button" style="width: 100%; color: #fefefe;" :disabled="!isFormValid">
                                            Register
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- POPUP SHARE LINK -->
                <div class="modal fade modal-bookmark" id="shareLink" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Share Link</h5>
                                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="py-2"></div>
                            <div class="p-3 row" align="center">
                                <div class="col-1 col-sm-3"></div>
                                <div class="col-10 col-sm-6">
                                    <div class="border rounded p-3 d-flex" style="width:100%;cursor:pointer;" align="left" @click="shareLinkWs()">
                                        <div style="width:20%;position: relative;top: 3px;" align="center">
                                            <svg xmlns="http://www.w3.org/2000/svg" style="width:30%" fill="#25D366" class="bi bi-whatsapp" viewBox="0 0 16 16">
                                                <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
                                            </svg>
                                        </div>
                                        <h6 style="width:80%;position: relative;top: 5px;">Share through whatsapp</h6>
                                    </div>
                                </div>
                                <div class="col-1 col-sm-3"></div>
                                <div class="p-2 col-12" align="center"></div>
                                <div class="col-1 col-sm-3"></div>
                                <div class="col-10 col-sm-6">
                                    <div class="border rounded p-3 d-flex" style="width:100%;cursor:pointer;" align="left" @click="copyLink()">
                                        <div style="width:20%;position: relative;top: 3px;" align="center">
                                            <svg xmlns="http://www.w3.org/2000/svg" style="width:30%" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
                                                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"/>
                                                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"/>
                                            </svg>
                                        </div>
                                        <h6 style="width:80%;position: relative;top: 5px;">Copy link</h6>
                                    </div>
                                </div>
                                <div class="col-1 col-sm-3"></div>
                                <div class="p-2 col-12" align="center">
                                    *Share your registration link to refer user and earn more exciting rewards!
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { registerUser,getRoles} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";
import SelectState from "@/components/dropdown/state.vue";
import { vSelect } from 'vue3-select';
import 'vue3-select/dist/vue3-select.css';

export default {
    name: "Setup Client",

    components: {
        SelectState,
        'v-select': vSelect,
        
    },

    data() {
        return {
            role: localStorage.getItem('role'),
            referral_id: localStorage.getItem('user_id'),
            role_id: null,
            user_role: '',
            user_name: null,
            user_phone: null,
            user_email: null,
            roleListDropdown: null,
            agentRoleId: null,
        };
    },

    beforeMount() {},

    mounted() {},

    created() {
        this.getRoles();
    },

    computed: {
        isFormValid() {
            // Check if all required fields are filled
            return (
                this.clientFullName !== null &&
                this.clientIcNumber !== null &&
                this.clientContactNumber !== null &&
                this.clientPackage !== ''
            );
        },
        limitedPages() {
            const totalPages = Math.ceil(
                this.clientTableData.length / this.itemsPerPage
            );
            const displayPages = 3; // Number of pages to display
            const threshold = 3; // Threshold after which pages should shift

            let startPage = Math.max(
                1,
                this.currentPage - Math.floor(displayPages / 2)
            );
            let endPage = Math.min(totalPages, startPage + displayPages - 1);

            if (this.currentPage >= threshold) {
                startPage = this.currentPage - 1;
                endPage = Math.min(
                    totalPages,
                    this.currentPage + displayPages - 2
                );
            }

            return Array.from(
                { length: endPage - startPage + 1 },
                (_, index) => startPage + index
            );
        },
    },

    methods: {
        shareLinkWs(){
            const registrationUrl = `${window.location.host}/registration/referral?referral=${this.referral_id}`;
            const whatsappMessage = `Join us using this link: ${registrationUrl}`;
            const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(whatsappMessage)}`;
            window.open(whatsappUrl, '_blank');
        },
        copyLink(){
            const textToCopy = `${window.location.host}/registration/referral?referral=${this.referral_id}&role=${this.role_id}`;
            navigator.clipboard.writeText(textToCopy).then(() => {
                this.notificationSuccess('Link copied to clipboard');
            }).catch(() => {
                this.notificationError('Failed to copy link');
            });
            // this.$router.push("/campaign/details?campaign_id="+this.referral_id);
        },
        setRoleId(roleId) {
            this.role_id=roleId;
        },

        //--------API HANDLING -----------------//
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },

        async getRoles() {
            this.tableloader = true;
            try {
                await getRoles(this.$router).then((response) => {
                    if (response.data.success == true) {
                        this.roleListDropdown = response.data.data;
                        
                        // set default register role value for agent
                        if (this.role=='agent') {
                            const matchingRole = this.roleListDropdown.filter(
                                (item) => item.name && item.name.toLowerCase().includes(this.role)
                            );
                            
                            if (matchingRole.length > 0) {
                                this.agentRoleId = matchingRole[0].id; // Assuming there's only one match, take the id
                            }
                            this.user_role=this.agentRoleId;
                            this.roleListDropdown = matchingRole;
                        }
                        console.log(this.roleListDropdown);
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
        // register User
        async registerUser() {
            const dataPass = {
                role: this.user_role,
                name : this.user_name,
                email: this.user_email,
                phone: this.user_phone,
                referralId: this.referral_id,
            };
            console.log(dataPass);
            try {
                await registerUser(dataPass).then((response) => {
                    if (response.data.success == true) {

                    } else {
                    this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
    },
};
</script>