<style scoped>
.inactivePage {
    color: #cccccc;
}
.activePage {
    color: #CA952B;
}
.activeButtonPage {
    color: #CA952B;
}
.inactiveButtonPage {
    color: #cccccc;
}
.form-control::placeholder {
    color: #d9d9d9 !important;
}
.selected {
    color: #CA952B;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 3px solid #CA952B;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.search-table {
    table-layout: fixed;
    margin: 0px auto 0px auto;
}
.search-table,
td,
th {
    border-collapse: collapse;
}
th {
    padding: 10px 7px;
    font-size: 15px;
    color: #52526c;
    background: #f5f6f9;
}
td {
    padding: 5px 10px;
    height: 35px;
}

.search-table-outter {
    overflow-x: scroll;
}
</style>

<template>
    <div class="h-5" style="height: 10px"></div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="py-3">
                    <h4>Cashflow</h4>
                    <div>View cash flow for Gold transactions</div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-xxl-4 py-2"> 
                        <div class="card small-widget mb-sm-0 p-3">
                        <div class="card-body primary">
                            <span class="f-light" style="font-size:1.3rem">Total Gold(RM)</span>
                            <div class="d-flex align-items-end gap-1">
                            <h4>2,435.00</h4><span class="font-primary f-12 f-w-500"><i class="icon-arrow-up"></i><span>+50%</span></span>
                            </div>
                            <div class="bg-gradient"> 
                            <svg class="stroke-icon svg-fill">
                                <use href="@/assets/svg/icon-sprite.svg#new-order"></use>
                            </svg>
                            </div>
                        </div>
                        </div>
                    </div>
                    <!-- HIDE THIS IF LOGIN = AGENT -->
                    <div class="col-sm-12 col-xxl-4 py-2"> 
                        <div class="card small-widget mb-sm-0 p-3">
                        <div class="card-body primary">
                            <span class="f-light" style="font-size:1.3rem">Total Gold(kg)</span>
                            <div class="d-flex align-items-end gap-1">
                            <h4>1450.56</h4><span class="font-primary f-12 f-w-500"><i class="icon-arrow-up"></i><span>+50%</span></span>
                            </div>
                            <div class="bg-gradient"> 
                            <svg class="stroke-icon svg-fill">
                                <use href="@/assets/svg/icon-sprite.svg#new-order"></use>
                            </svg>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-xxl-4 py-2"> 
                        <div class="card small-widget mb-sm-0 p-3">
                        <div class="card-body primary">
                            <span class="f-light" style="font-size:1.3rem">Total Withdrawal(RM)</span>
                            <div class="d-flex align-items-end gap-1">
                            <h4>365.00</h4><span class="font-primary f-12 f-w-500"><i class="icon-arrow-up"></i><span>+50%</span></span>
                            </div>
                            <div class="bg-gradient"> 
                            <svg class="stroke-icon svg-fill">
                                <use href="@/assets/svg/icon-sprite.svg#new-order"></use>
                            </svg>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="py-3"></div>
                <div v-if="this.tableloader === false">
                    <div class="card">
                        <div class="p-4 d-flex" style="align-items: center">
                            <div class="col-2 d-flex" style=" font-size: 1rem; font-weight: 500; align-items: center; ">
                                <span>Gold Transactions</span>
                            </div>
                            <div class="col-9 d-flex">
                                <div class="faq-form" style="width: 70%">
                                    <input class="form-control" v-model="inputText" @input="FilterHandling(inputText)" type="text" placeholder="Search name.."/>
                                    <vue-feather class="search-icon" type="search" ></vue-feather>
                                </div>
                            </div>
                            <!-- <div class="col-1 d-flex" style="cursor: pointer; align-items: center">
                                <div style=" font-size: 1rem; font-weight: 450; padding-right: 10px; " >
                                    Filter
                                </div>
                                <div style="position: relative; top: 2px">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#CA952B" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>
                                </div>
                            </div> -->
                        </div>

                        <!-- DATA Table -->
                        <div class="row py-1 px-4">
                            <div style="border-bottom: 1px solid #cccccc">
                                <div class="col-sm-10 row">
                                    <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{ selected: selectedTab === '', }" @click="changeTab('')">
                                        All
                                    </div>
                                    <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{ selected: selectedTab === '0', }" @click="changeTab('0')">
                                        Success
                                    </div>
                                    <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{ selected: selectedTab === '1', }" @click="changeTab('1')">
                                        Approval
                                    </div>
                                    <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{ selected: selectedTab === '2', }" @click="changeTab('2')">
                                        Ended
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- DATA Table -->
                        <div v-if="this.clientTableDataResult">
                            <div class="search-table-outter wrapper p-4" style="height: 420px">
                                <table class="search-table inner">
                                    <tr style="height: 20px">
                                        <th style="width: 2%">#</th>
                                        <th style="width: 40%;">Product</th>
                                        <th style="width: 18%;">Price</th>
                                        <th style="width: 20%px;">Date</th>
                                        <th style="width: 10%;text-align: center;">Status</th>
                                        <th style="width: 10%;text-align: center;">Action</th>
                                    </tr>
                                    <tr style="height: 60px" v-for="(item, index) in this.clientTableDataResult" :key="index">
                                        <td>{{ (currentPage - 1) * itemsPerPage + index + 1 }}.</td>
                                        <td> {{ (result = this.packageAvailable.find((item1) => item1.id === item.package )) ? result.pack_name : "-" }} </td>
                                        <td><a :href="`/client/cprofile?id=${item.client_id}`" >{{ item.full_name !== "" ? item.full_name : "-" }}</a></td>
                                        <td> 
                                            {{ item.period_intake ? formatDateTime(item.period_intake ) : "-" }}
                                        </td>
                                        <td style="width: 150px">
                                            <select :value="item.client_status" class="form-select form-control-primary" name="select" @change="setClientStatus(item, $event.target.value)">
                                                <option value="" disabled selected > Select Status </option>
                                                <option value="0"> Active </option>
                                                <option value="1"> Inactive </option>
                                                <option value="2">Hold</option>
                                                <option value="3"> Expired </option>
                                                <option value="4">Renew</option>
                                            </select>
                                            <!-- <div style=" display: flex; align-items: center; justify-content: center;">
                                                <div v-if=" item.client_status === '0'" class="p-1" align="center" style="width: 70%; background-color: #15aa2c; border-radius: 8px; color: #fefefe;">
                                                    Active
                                                </div>
                                                <div v-if="item.client_status ==='1'" class="p-1" align="center" style=" width: 70%; background-color: #cccccc; border-radius: 8px;color: #fefefe;">
                                                    Inactive
                                                </div>
                                                <div v-else-if="item.client_status ==='2'" class="p-1" align="center" style="width: 70%; background-color: #ffaa05;border-radius: 8px;color: #fefefe;">
                                                    Hold
                                                </div>
                                                <div v-else-if=" item.client_status === '3' " class="p-1" align="center" style=" width: 70%; background-color: #dc3545; border-radius: 8px; color: #fefefe; ">
                                                    Expired
                                                </div>
                                                <div v-else-if=" item.client_status === '4' " class="p-1" align="center" style=" width: 70%; background-color: #0000ff; border-radius: 8px; color: #fefefe; " >
                                                    Renew
                                                </div>
                                            </div> -->
                                        </td>
                                        <td style="width: 100px">
                                            <div style=" display: flex;justify-content: center; ">
                                                <div style="cursor: pointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" @click=" intializeEditClient( item ) " width="20" height="20" fill="#CA952B" class="bi bi-pen-fill" viewBox="0 0 16 16" data-bs-toggle="modal" data-bs-target="#addClient">
                                                        <path
                                                            d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"
                                                        />
                                                    </svg>
                                                </div>
                                                <div style="width: 20px"></div>
                                                <div class="deleteYearCard" style="cursor: pointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" @click=" intializeEditClient( item ) " data-bs-toggle="modal" data-bs-target="#deleteClient" width="20" height="20" fill="#CA952B" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div v-else align="center" style="height: 300px">
                            <img style=" border-radius: 5px; width: 240px; height: 220px; " alt="" src="../../assets/images/no_data_image.png"/>
                            <h6>No Data</h6>
                        </div>

                        <!-- TABLE PAGINATION -->
                        <div class="row pagination" v-if="maxPage > 1">
                            <div class="col-md-7 col-sm-0 p-3">
                                <div class="inactivePage" style="padding-left: 30px" >
                                    {{ "Page " + this.currentPage + " out of " + this.maxPage }}
                                </div>
                            </div>
                            <div class="col-md-5 col-sm-12 p-3">
                                <div class="d-flex" style=" padding-right: 30px; justify-content: flex-end; " >
                                    <button class="p-1" :class="{ activeButtonPage: this.currentPage != '1', inactiveButtonPage: this.currentPage == '1', }" @click="prevPage()" style=" width: 30%; border-top-left-radius: 8px; border-bottom-left-radius: 8px; border: 1px solid #dee2e6; " >
                                        Previous
                                    </button>
                                    <div @click="setFirstPage()" v-if="this.currentPage != 1" class="inactivePage p-1" align="center" style=" cursor: pointer; width: 10%; border: 1px solid #dee2e6; ">
                                        ...
                                    </div>
                                    <div @click="goToPage(pageNumber)" v-for="pageNumber in limitedPages" :key="pageNumber" :class="{ activePage: currentPage === pageNumber, inactivePage: currentPage !== pageNumber,}"
                                        class="p-1" align="center" style="cursor: pointer; width: 10%; border: 1px solid #dee2e6;" >
                                        {{ pageNumber }}
                                    </div>
                                    <div @click="setLastPage()" v-if="this.currentPage != this.maxPage" class="inactivePage p-1" align="center" style=" cursor: pointer; width: 10%; border: 1px solid #dee2e6;">
                                        ...
                                    </div>
                                    <button class="p-1" :class="{ activeButtonPage: currentPage === '1' && currentPage !== this.maxPage, inactiveButtonPage: currentPage === this.maxPage,}" @click="nextPage()" style=" width: 30%;  border-top-right-radius: 8px; border-bottom-right-radius: 8px; border: 1px solid #dee2e6;">
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else style="padding-top: 30px">
                    <h6 class="mb-0 text-center">Please Wait...</h6>
                    <div class="loader-box">
                        <div class="loader-3"></div>
                    </div>
                </div>

                <!-- POPUP ADD CLIENT -->
                <div class="modal fade modal-bookmark" id="addClient" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">
                                    <span v-if="this.clientId === null" >Add</span><span v-else>Update</span> Client
                                </h5>
                                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div style="max-height: 60vh;overflow: scroll;overflow-x: hidden;">
                                <div class="modal-body row" style="justify-content: space-between">
                                    <h5 class="py-2">Personal <span style="color:red">*</span></h5>
                                    <div class="py-2 col-12" style="font-size: 1rem" >
                                        <div style="font-weight: 450"> Participant </div>
                                        <!-- options use array data, label is the option display, reduce is the value holds
                                         @change event will not work at v-select, instead use @update:modelValue
                                        -->
                                        <v-select 
                                            v-model="this.clientParticipant" 
                                            :options="this.participantAvailable" 
                                            placeholder="Select Participant" 
                                            @update:modelValue="loadParticipantData()"
                                            label="full_name"
                                            :reduce="participant => participant.id"
                                        ></v-select>
                                        <!-- <select v-model="this.clientParticipant" class="form-select form-control-primary" name="select" @change="loadParticipantData()" required>
                                            <option value="" disabled selected required> Select Participant </option>
                                            <option v-for="item in this.participantAvailable" :key="item.id" :value="item.id"> {{ item.full_name }}</option>
                                        </select> -->
                                    </div>
                                    <div class="py-2 col-12" style="font-size: 1rem">
                                        <div style="font-weight: 450"> Full Name<span style="color:red">*</span></div>
                                        <input v-model="this.clientFullName" class="form-control" type="text" placeholder="Ahmad"/>
                                    </div>
                                    <div class="py-2 col-6" style="font-size: 1rem">
                                        <div style="font-weight: 450"> IC Number<span style="color:red">*</span></div>
                                        <input v-model="this.clientIcNumber" class="form-control" type="text" placeholder="981121089113"/>
                                    </div>
                                    <div class="py-2 col-6" style="font-size: 1rem">
                                        <div style="font-weight: 450"> Contact Number<span style="color:red">*</span></div>
                                        <input v-model="this.clientContactNumber" class="form-control" type="text" placeholder="0148876776" />
                                    </div>
                                    <div class="p-2 col-12">
                                        <div class="d-flex p-2" style="justify-content: space-between;">
                                            <div><h5>Company (Optional)</h5></div>
                                        </div>
                                    </div>
                                    <div class="row showCompany">
                                        <div class="py-2 col-6" style="font-size: 1rem">
                                            <div style="font-weight: 450"> Company Name</div>
                                            <input v-model="this.clientCompanyName" class="form-control" type="text" placeholder="Company name"/>
                                        </div>
                                        <div class="py-2 col-6" style="font-size: 1rem">
                                            <div style="font-weight: 450"> Business Industry</div>
                                            <select v-model="this.clientCompanyIndustry" class="form-select form-control-primary" name="select" required>
                                                <option value="" disabled selected> Select Business Industry </option>
                                                <option value="FASHION"> FASHION  </option>
                                                <option value="BEAUTY"> BEAUTY </option>
                                                <option value="BEAUTY PRODUCT"> BEAUTY PRODUCT </option>
                                                <option value="HEALTH & BEAUTY"> HEALTH & BEAUTY </option>
                                                <option value="HEALTH SUPPLEMENT"> HEALTH SUPPLEMENT </option>
                                                <option value="HEALTH"> HEALTH </option>
                                                <option value="HEALTHCARE"> HEALTHCARE </option>
                                                <option value="PRINTING"> PRINTING </option>
                                                <option value="SERVICE PRINTING"> SERVICE PRINTING </option>
                                                <option value="SERVICE"> SERVICE </option>
                                                <option value="SERVICE CONSULTANT" > SERVICE CONSULTANT </option>
                                                <option value="MARKETING SERVICE" > MARKETING SERVICE </option>
                                                <option value="ENTERTAINMENT"> ENTERTAINMENT </option>
                                                <option value="FOODS & BEVERAGES" > FOODS & BEVERAGES </option>
                                                <option value="PERFUME"> PERFUME </option>
                                                <option value="PRODUCT"> PRODUCT </option>
                                                <option value="PRODUCT HOME"> PRODUCT HOME </option>
                                                <option value="BABY PRODUCT"> BABY PRODUCT </option>
                                                <option value="GADGET"> GADGET </option>
                                                <option value="IT">IT</option>
                                                <option value="BUSINESS CONSULTING" > BUSINESS CONSULTING </option>
                                                <option value="FINANCIAL CONSULTANCY" > FINANCIAL CONSULTANCY </option>
                                                <option value="EDUCATION"> EDUCATION </option>
                                                <option value="HOUSEHOLD"> HOUSEHOLD </option>
                                                <option value="NOVEL PUBLISHER"> NOVEL PUBLISHER </option>
                                                <option value="CONSTRUCTION"> CONSTRUCTION </option>
                                                <option value="AUTOMOTIVE"> AUTOMOTIVE </option>
                                                <option value="OTHER"> OTHER </option>
                                            </select>
                                        </div>
                                        <div class="py-2 col-4" style="font-size: 1rem">
                                            <div style="font-weight: 450"> Position </div>
                                            <input v-model="this.clientCompanyPosition" class="form-control" type="text" placeholder="CEO"/>
                                        </div>
                                        <div class="py-2 col-4" style="font-size: 1rem">
                                            <div style="font-weight: 450"> Brand Name</div>
                                            <input v-model="this.clientCompanyBrand" class="form-control" type="text"  placeholder="Branding name"/>
                                        </div>
                                        <div class="py-2 col-4" style="font-size: 1rem">
                                            <div style="font-weight: 450"> SSM No. </div>
                                            <input v-model="this.clientCompanySsm" class="form-control" type="text" placeholder="9875531"/>
                                        </div>
                                        <div class="py-2 col-12" style="font-size: 1rem">
                                            <div style="font-weight: 450"> Address Detail </div>
                                            <textarea v-model="this.clientAddressDetail" class="form-control" type="text" placeholder="Insert Detail"></textarea>
                                        </div>
                                        <div class="py-2 col-6" style="font-size: 1rem" >
                                            <div style="font-weight: 450">
                                                Postcode
                                            </div>
                                            <input
                                                v-model="
                                                    this.clientAddressPostcode
                                                "
                                                class="form-control"
                                                type="text"
                                                placeholder="81750"
                                            />
                                        </div>
                                        <div
                                            class="py-2 col-6"
                                            style="font-size: 1rem"
                                        >
                                            <div style="font-weight: 450">
                                                City
                                            </div>
                                            <input
                                                v-model="this.clientAddressCity"
                                                class="form-control"
                                                type="text"
                                                placeholder="Shah Alam"
                                            />
                                        </div>
                                        <div
                                            class="py-2 col-6"
                                            style="font-size: 1rem"
                                        >
                                            <div style="font-weight: 450">
                                                twitter Url
                                            </div>
                                            <input
                                                v-model="this.clientSocTwitter"
                                                class="form-control"
                                                type="text"
                                                placeholder="username"
                                            />
                                        </div>
                                        <div
                                            class="py-2 col-6"
                                            style="font-size: 1rem"
                                        >
                                            <div style="font-weight: 450">
                                                Facebook Url
                                            </div>
                                            <input
                                                v-model="this.clientSocFb"
                                                class="form-control"
                                                type="text"
                                                placeholder="username"
                                            />
                                        </div>
                                        <div
                                            class="py-2 col-6"
                                            style="font-size: 1rem"
                                        >
                                            <div style="font-weight: 450">
                                                Instagram Url
                                            </div>
                                            <input
                                                v-model="this.clientSocIg"
                                                class="form-control"
                                                type="text"
                                                placeholder="username"
                                            />
                                        </div>
                                        <div
                                            class="py-2 col-6"
                                            style="font-size: 1rem"
                                        >
                                            <div style="font-weight: 450">
                                                Tiktok Url
                                            </div>
                                            <input
                                                v-model="this.clientSocTiktok"
                                                class="form-control"
                                                type="text"
                                                placeholder="username"
                                            />
                                        </div>
                                    </div>
                                    <div class="p-2 col-12">
                                        <div class="d-flex p-2" style=" justify-content: space-between; " >
                                            <div><h5>Package Detail <span style="color:red">*</span></h5></div>
                                        </div>
                                    </div>
                                    <div class="row showPackage">
                                        <div class="py-2 col-6" style="font-size: 1rem">
                                            <div style="font-weight: 450"> Package <span style="color:red">*</span></div>
                                            <select v-model="this.clientPackage" class="form-select form-control-primary" name="select" @change="loadPackageData()" required>
                                                <option value="" disabled selected required> Select Package </option>
                                                <option v-for="item in this.packageAvailable" :key="item.id" :value="item.id">{{ item.pack_name }}</option>
                                            </select>
                                        </div>
                                        <div class="py-2 col-6" style="font-size: 1rem">
                                            <div style="font-weight: 450"> Amount (RM)</div>
                                            <input v-model="this.clientPackageAmount" class="form-control" type="text" placeholder="80000"  disabled/>
                                        </div>
                                        <div class="py-2 col-6" style="font-size: 1rem">
                                            <div style="font-weight: 450"> Period Intake</div>
                                            <input v-model="this.clientPackageIntake" class="form-control" type="date" placeholder="2024" disabled/>
                                        </div>
                                        <div class="py-2 col-6" style="font-size: 1rem">
                                            <div style="font-weight: 450"> Period End </div>
                                            <input v-model="this.clientPackageEnd" class="form-control" type="date" placeholder="2024" disabled/>
                                        </div>
                                        <div class="py-2 col-6" style="font-size: 1rem">
                                            <div style="font-weight: 450"> Client Status</div>
                                            <select v-model="this.clientStatus" class="form-select form-control-primary" name="select">
                                                <option value="" disabled selected > Select Status </option>
                                                <option value="0"> Active </option>
                                                <option value="1"> Inactive </option>
                                                <option value="2">Hold</option>
                                                <option value="3"> Expired </option>
                                                <option value="4">Renew</option>
                                            </select>
                                        </div>
                                        <div class="py-2 col-6" style="font-size: 1rem" >
                                            <div style="font-weight: 450"> Sales Person </div>
                                            <select v-model="this.clientClosedBy" class="form-select form-control-primary" name="select" required>
                                                <option value="" disabled selected required > Select Seller </option>
                                                <option v-for="item in this.sellerAvailable" :key="item.usr_main_id" :value="item.usr_main_id">
                                                    {{ item.first_name + " " + item.last_name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="py-2 col-6" style="font-size: 1rem">
                                            <div style="font-weight: 450">Payment Status</div>
                                            <select v-model="this.clientPaymentStatus" class="form-select form-control-primary" name="select">
                                                <option value="" disabled selected>Select Payment Status</option>
                                                <option value="0"> Deposit</option>
                                                <option value="1"> Full Payment</option>
                                            </select>
                                        </div>
                                        <div class="py-2 col-6" style="font-size: 1rem" >
                                            <div style="font-weight: 450">T Shirt size</div>
                                            <input v-model="this.clientSizeTshirt" class="form-control" type="text" placeholder="XXL" required />
                                        </div>
                                        <div class="row" v-if="this.clientPaymentStatus=='0'">
                                            <h5 class="py-3">Payment Journey (Deposit)</h5>
                                            <div class="py-2 col-6" style="font-size: 1rem">
                                                <div style="font-weight: 450">Amount</div>
                                                <input v-model="this.clientDepositAmount" class="form-control" type="text" placeholder="1000" required/>
                                            </div>
                                            <div class="py-2 col-6" style="font-size: 1rem">
                                                <div style="font-weight: 450">Balance</div>
                                                <input v-model="calculatedBalance" class="form-control" type="text" placeholder="80000"  required/>
                                            </div>
                                            <div class="py-2 col-6" style="font-size: 1rem">
                                                <div style="font-weight: 450">Date</div>
                                                <input v-model="this.clientDepositDate" class="form-control" type="date" placeholder="2024" required/>
                                            </div>
                                            <div class="py-2 col-6" style="font-size: 1rem">
                                                <div style="font-weight: 450">Remarks</div>
                                                <input v-model="this.clientDepositRemarks" class="form-control" type="text" placeholder="Deposit info" required/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row p-3">
                                <div class="col-12 d-flex" style="justify-content: flex-end" >
                                    <div v-if="this.clientId !== null">
                                        <button @click="deleteClient(this.clientId)" data-bs-dismiss="modal" class="btn btn-danger" type="button" style="width: 100%">
                                            Delete
                                        </button>
                                    </div>
                                    <div style="padding-right: 20px"></div>
                                    <div>
                                        <!-- Bind 'disabled' attribute to computed property 'isFormValid' -->
                                        <button @click="saveClient()" data-bs-dismiss="modal" class="btn btn-primary" type="button" style="width: 100%; color: #fefefe;" :disabled="!isFormValid">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- POPUP DELETE CLIENT -->
                <div class="modal fade modal-bookmark" id="deleteClient" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title"> Delete Client </h5>
                                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="p-2" style="font-size:1rem;">Are you sure you want to remove <span style="font-weight:500">'{{ this.clientFullName }}'</span> from client data record?</div>
                            </div>
                            <div class="row p-3">
                                <div align="center">
                                    <div>
                                        <button @click="deleteClient(this.clientId)" data-bs-dismiss="modal" class="btn btn-danger" type="button" style="width: 50%">
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    listClient,
    clientAdd,
    clientEdit,
    clientDelete,
    clientStatusUpdate,
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "Setup Client",

    components: {
    },

    data() {
        return {
            searchText: "",
            selectedTab: "",
            clientTableData: null,
            clientTableDataResult: null,
            tableloader: false,
            indexNumbering: 1,
            currentPage: 1,
            itemsPerPage: 5,
            maxPage: 1,

            clientId: null,
            clientParticipant: "",
            clientFullName: null,
            clientIcNumber: null,
            clientContactNumber: null,
            clientCompanyName: null,
            clientCompanyIndustry: null,
            clientCompanyPosition: null,
            clientCompanyBrand: null,
            clientCompanySsm: null,
            clientAddressDetail: null,
            clientAddressPostcode: null,
            clientAddressCity: null,
            clientAddressState: "",
            clientSocTwitter: null,
            clientSocFb: null,
            clientSocIg: null,
            clientSocTiktok: null,
            clientPackage: "",
            clientPackageAmount: null,
            clientPackageIntake: null,
            clientPackageEnd: null,
            clientStatus: "",
            clientClosedBy: "",
            clientPaymentStatus: "",
            clientSizeTshirt: null,
            clientDepositRemarks: null,
            clientDepositDate: null,
            clientDepositAmount: null,

            sellerAvailable: [],
            participantAvailable: [],
            packageAvailable: [],

        };
    },

    beforeMount() {
        this.loadListClient();
    },

    mounted() {},

    created() {},

    computed: {
        isFormValid() {
            // Check if all required fields are filled
            return (
                this.clientFullName !== null &&
                this.clientIcNumber !== null &&
                this.clientContactNumber !== null &&
                this.clientPackage !== ''
            );
        },
        limitedPages() {
            const totalPages = Math.ceil(
                this.clientTableData.length / this.itemsPerPage
            );
            const displayPages = 3; // Number of pages to display
            const threshold = 3; // Threshold after which pages should shift

            let startPage = Math.max(
                1,
                this.currentPage - Math.floor(displayPages / 2)
            );
            let endPage = Math.min(totalPages, startPage + displayPages - 1);

            if (this.currentPage >= threshold) {
                startPage = this.currentPage - 1;
                endPage = Math.min(
                    totalPages,
                    this.currentPage + displayPages - 2
                );
            }

            return Array.from(
                { length: endPage - startPage + 1 },
                (_, index) => startPage + index
            );
        },
        calculatedBalance() {
            return this.clientPackageAmount - this.clientDepositAmount;
        },
    },

    methods: {
        //FILTER CHANGE TAB
        changeTab(tabProgressName) {
            this.selectedTab = tabProgressName;
            this.FilterHandling();
        },

        // PREVIOUS PAGE FUNCTION
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.PageIndexNumber();
                this.PaginationHandling();
            }
        },

        // NEXT PAGE FUNCTION
        nextPage() {
            if (this.currentPage < this.maxPage) {
                this.currentPage++;
                this.PageIndexNumber();
                this.PaginationHandling();
            }
        },

        // SEND TO FIRST PAGE
        setFirstPage() {
            this.currentPage = 1;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        // SEND TO LAST PAGE
        setLastPage() {
            this.currentPage = this.maxPage;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        goToPage(page) {
            this.currentPage = page;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        PageIndexNumber() {
            if (this.currentPage == 1) {
                this.indexNumbering = 1;
            } else {
                if (this.currentPage == 2) {
                    this.indexNumbering = this.itemsPerPage;
                } else {
                    this.indexNumbering =
                        (this.currentPage - 1) * this.itemsPerPage;
                }
            }
        },

        // FILTER FUNCTION DATA MANAGEMENT
        FilterHandling(text) {
            //FILTER FIRST ORIGINAL DATA GIVEM
            if (this.selectedTab != "") {
                this.clientTableDataResult = this.clientTableData.filter(
                    (item) => item.client_status === this.selectedTab
                );
            } else {
                //If FILTER ALL, FETCH ALL DATA FROM API
                this.clientTableDataResult = this.clientTableData;
            }

            if (text != undefined) {
                this.searchText = text;
            }

            // Filter based on search text
            if (this.clientTableDataResult !== null) {
                // Filter based on search text
                if (this.searchText.trim() !== "") {
                    const searchText = this.searchText.toLowerCase().trim();
                    this.clientTableDataResult =
                        this.clientTableDataResult.filter(
                            (item) =>
                                (item.full_name &&
                                    item.full_name
                                        .toLowerCase()
                                        .includes(searchText)) || // Check if package is not null
                                (item &&
                                    item.contact_number &&
                                    item.contact_number
                                        .toLowerCase()
                                        .includes(searchText)) || // Check if full_name is not null
                                (item &&
                                    item.company &&
                                    item.company
                                        .toLowerCase()
                                        .includes(searchText)) || // Check if contact_number is not null
                                (item &&
                                    item.brand_name &&
                                    item.brand_name
                                        .toLowerCase()
                                        .includes(searchText)) || // Check if contact_number is not null
                                (item &&
                                    item.business_industry &&
                                    item.business_industry
                                        .toLowerCase()
                                        .includes(searchText))
                        );
                }
            }

            //RESET PAGE BACK TO 1
            this.currentPage = 1;
            // Slice the data based on pagination
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            //ASSIGN HOW MANY PAGES FROM THE FILTERED DATA RESULT
            this.maxPage = Math.ceil(
                this.clientTableDataResult.length / this.itemsPerPage
            );

            //Assign FINAL Value OF PAGE DATA
            if (this.clientTableDataResult.length > 0) {
                this.clientTableDataResult = this.clientTableDataResult.slice(
                    startIndex,
                    endIndex
                );
            } else {
                this.clientTableDataResult = null;
            }
        },

        PaginationHandling() {
            //FILTER THE SELECTED DATA
            if (this.selectedTab != "") {
                this.clientTableDataResult = this.clientTableData.filter(
                    (item) => item.client_status === this.selectedTab
                );
            } else {
                this.clientTableDataResult = this.clientTableData;
            }

            // Apply text search filter if searchText is not empty
            if (this.searchText != "") {
                const searchText = this.searchText.toLowerCase().trim();
                this.clientTableDataResult = this.clientTableDataResult.filter(
                    (item) =>
                        (item.full_name &&
                            item.full_name
                                .toLowerCase()
                                .includes(searchText)) || // Check if package is not null
                        (item.contact_number &&
                            item.contact_number
                                .toLowerCase()
                                .includes(searchText)) || // Check if full_name is not null
                        (item.company &&
                            item.company.toLowerCase().includes(searchText)) || // Check if full_name is not null
                        (item.brand_name &&
                            item.brand_name
                                .toLowerCase()
                                .includes(searchText)) || // Check if full_name is not null
                        (item.business_industry &&
                            item.business_industry
                                .toLowerCase()
                                .includes(searchText)) // Check if contact_number is not null
                );
            }

            // Slice the data based on pagination
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            this.maxPage = Math.ceil(
                this.clientTableDataResult.length / this.itemsPerPage
            );

            //Assign FINAL Value OF PAGE DATA
            if (this.clientTableDataResult.length > 0) {
                this.clientTableDataResult = this.clientTableDataResult.slice(
                    startIndex,
                    endIndex
                );
                this.clientTableDataResult.forEach((dataObj) => {
                    if (this.currentPage > 1) {
                        this.indexNumbering++;
                    }

                    dataObj.numbering = this.indexNumbering;
                    if (this.currentPage == 1) {
                        this.indexNumbering++;
                    }
                });
            } else {
                this.clientTableDataResult = null;
            }
        },

        //--------API HANDLING -----------------//
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },

        formatDateTime(dateTimeString) {
            const [year, month, day] = dateTimeString.split("-");
            const formattedDateTime = `${day}-${month}-${year}`;
            return formattedDateTime;
        },

        searchById(array, id) {
            for (let i = 0; i < array.length; i++) {
                if (array[i].id === id) {
                    return array[i];
                }
            }
            return null; // If id is not found
        },

        loadParticipantData() {
            const result = this.searchById(
                this.participantAvailable,
                this.clientParticipant
            );
            if (result!=null) {
                this.clientCompanyBrand = result.brand_name;
                this.clientContactNumber = result.contact_number;
                this.clientFullName = result.full_name;
                this.clientCompanyIndustry = result.niche_market;
            } else {
                // CLEAR BACK AUTO FILLED VALUES AT INPUT
                this.clientCompanyBrand = null
                this.clientContactNumber = null;
                this.clientFullName = null;
                this.clientCompanyIndustry = '';
            }
        },

        loadPackageData() {
            const result = this.searchById(
                this.packageAvailable,
                this.clientPackage
            );
            this.clientPackageAmount = result.pack_price;
            this.clientPackageIntake = result.pack_intake_start;
            this.clientPackageEnd = result.pack_intake_end;
        },

        clearModalClient() {
            this.clientId = null;
            this.clientParticipant = "";
            this.clientFullName = null;
            this.clientIcNumber = null;
            this.clientContactNumber = null;
            this.clientCompanyName = null;
            this.clientCompanyIndustry = "";
            this.clientCompanyPosition = null;
            this.clientCompanyBrand = null;
            this.clientCompanySsm = null;
            this.clientAddressDetail = null;
            this.clientAddressPostcode = null;
            this.clientAddressCity = null;
            this.clientAddressState = "";
            this.clientSocTwitter = null;
            this.clientSocFb = null;
            this.clientSocIg = null;
            this.clientSocTiktok = null;
            this.clientPackage = "";
            this.clientPackageAmount = null;
            this.clientPackageIntake = null;
            this.clientPackageEnd = null;
            this.clientStatus = "";
            this.clientClosedBy = "";
            this.clientPaymentStatus = "";
            this.clientSizeTshirt = null;
            this.clientDepositRemarks = null;
            this.clientDepositDate = null;
            this.clientDepositAmount = null;
        },

        intializeEditClient(dataTable) {
            this.clientId = dataTable.client_id;
            this.clientParticipant = dataTable.prospect_id;
            this.clientFullName = dataTable.full_name;
            this.clientIcNumber = dataTable.ic_number;
            this.clientContactNumber = dataTable.contact_number;
            this.clientCompanyName = dataTable.company;
            this.clientCompanyIndustry = dataTable.business_industry;
            this.clientCompanyPosition = dataTable.position;
            this.clientCompanyBrand = dataTable.brand_name;
            this.clientCompanySsm = dataTable.ssm_no;
            this.clientAddressDetail = dataTable.address_detail1;
            this.clientAddressPostcode = dataTable.postcode;
            this.clientAddressCity = dataTable.city;
            this.clientAddressState = dataTable.state;
            this.clientSocTwitter = dataTable.url_soc_twitter;
            this.clientSocFb = dataTable.url_soc_fb;
            this.clientSocIg = dataTable.url_soc_instagram;
            this.clientSocTiktok = dataTable.url_soc_tiktok;
            this.clientPackage = dataTable.package;
            this.clientPackageAmount = dataTable.payment_amount;
            this.clientPackageIntake = dataTable.period_intake;
            this.clientPackageEnd = dataTable.period_end;
            this.clientStatus = dataTable.client_status;
            this.clientClosedBy = dataTable.sales_person;
            this.clientPaymentStatus = dataTable.payment_status;
            this.clientSizeTshirt = dataTable.shirt_size;
            this.clientDepositRemarks = dataTable.deposit_remarks;
            this.clientDepositDate = dataTable.deposit_date;
            this.clientDepositAmount = dataTable.deposit_amount;
        },

        async setClientStatus(clientData, newStatus) {
            if (clientData.clientPaymentStatus=='1') {
                clientData.clientDepositRemarks = null;
                clientData.clientDepositDate = null;
                clientData.clientDepositAmount = null;
            }

            const dataPass = {
                clientId: clientData.client_id,
                participantId: clientData.prospect_id,
                personal_detail: {
                    full_name: clientData.full_name,
                    ic_number: clientData.ic_number,
                    contact_number: clientData.contact_number,
                },
                company: {
                    company_name: clientData.company,
                    position: clientData.position,
                    business_industry: clientData.business_industry,
                    brand_name: clientData.brand_name,
                    ssm_no: clientData.ssm_no,
                    address_detail: clientData.address_detail1,
                    postcode: clientData.postcode,
                    state: clientData.state,
                    city: clientData.city,
                    url_soc_twitter: clientData.url_soc_twitter,
                    url_soc_fb: clientData.url_soc_fb,
                    url_soc_instagram: clientData.url_soc_instagram,
                    url_soc_tiktok: clientData.url_soc_tiktok,
                },
                package: {
                    package_name: clientData.package,
                    period_intake: clientData.period_intake,
                    period_end: clientData.period_end,
                    client_status: newStatus,
                    sales_person: clientData.sales_person,
                    payment_status: clientData.payment_status,
                    payment_amount: clientData.payment_amount,
                    shirt_size: clientData.shirt_size,
                    deposit_remarks: clientData.deposit_remarks,
                    deposit_date: clientData.deposit_date,
                    deposit_amount: clientData.deposit_amount,
                },
            };

            try {
                await clientEdit(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.clearModalClient();
                        this.loadListClient();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async loadListClient() {
            this.tableloader = true;

            try {
                await listClient().then((response) => {
                    if (response.data.message === "success") {
                        this.clientTableData = response.data.data;
                        this.sellerAvailable = response.data.seller;
                        this.participantAvailable = response.data.participant;
                        this.packageAvailable = response.data.package;
                        this.tableloader = false;
                        this.currentPage = 1;
                        this.PaginationHandling();
                        this.FilterHandling();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async deleteClient(id) {
            const dataPass = {
                clientId: id,
            };

            try {
                await clientDelete(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.clearModalClient();
                        this.loadListClient();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async saveClient() {
            if (this.clientPaymentStatus=='1') {
                this.clientDepositRemarks = null;
                this.clientDepositDate = null;
                this.clientDepositAmount = null;
            }

            const dataPass = {
                clientId: this.clientId,
                participantId: this.clientParticipant,
                personal_detail: {
                    full_name: this.clientFullName,
                    ic_number: this.clientIcNumber,
                    contact_number: this.clientContactNumber,
                },
                company: {
                    company_name: this.clientCompanyName,
                    position: this.clientCompanyPosition,
                    business_industry: this.clientCompanyIndustry,
                    brand_name: this.clientCompanyBrand,
                    ssm_no: this.clientCompanySsm,
                    address_detail: this.clientAddressDetail,
                    postcode: this.clientAddressPostcode,
                    state: this.clientAddressState,
                    city: this.clientAddressCity,
                    url_soc_twitter: this.clientSocTwitter,
                    url_soc_fb: this.clientSocFb,
                    url_soc_instagram: this.clientSocIg,
                    url_soc_tiktok: this.clientSocTiktok,
                },
                package: {
                    package_name: this.clientPackage,
                    period_intake: this.clientPackageIntake,
                    period_end: this.clientPackageEnd,
                    client_status: this.clientStatus,
                    sales_person: this.clientClosedBy,
                    payment_status: this.clientPaymentStatus,
                    payment_amount: this.clientPackageAmount,
                    shirt_size: this.clientSizeTshirt,
                    deposit_remarks: this.clientDepositRemarks,
                    deposit_date: this.clientDepositDate,
                    deposit_amount: this.clientDepositAmount,
                },
            };

            if (this.clientId === null) {
                try {
                    await clientAdd(dataPass).then((response) => {
                        if (response.data.message === "success") {
                            this.clearModalClient();
                            this.loadListClient();
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            } else {
                try {
                    await clientEdit(dataPass).then((response) => {
                        if (response.data.message === "success") {
                            this.clearModalClient();
                            this.loadListClient();
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            }
        },
    },
};
</script>