<style scoped>
.round {
    position: relative;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 20%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: -1px;
    width: 24px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 5px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.selected {
    color: #04318c;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 3px solid #04318c;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

/* .round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
} */

.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
    border-color: #66bb6a;
}

/* The Modal (background) */
.modal2 {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content2 {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  overflow:hidden; 
  border: 1px solid #888;
  width: 40%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.image-container {
    position: relative;
    display: inline-block; /* or use width: 100%; to make it full width */
}

.img-fluid {
    display: block;
}

.centered-content {
    position: absolute;
    top: 27%;
    left: 50%;
    height: 20vh;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 60%;
}

.logo {
    display: block;
    margin: 0 auto;
}

.centered-text {
    width:100%;
    background-color: rgba(255, 255, 255); /* Optional: to make the background semi-transparent */
    padding: 15px;
    border-radius: 15px; /* Optional: to give the div rounded corners */
    margin-top: 10px; /* Adjust this value to control the spacing between the logo and the text */
}

.select-home {
  width:100%;
  border: 1px solid #BBBBBB;
  color:#BBBBBB;
}

.select-type {
  width:50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-type {
  background:#CA952B;
  color:#FEFEFE;
  width:100%;
}

.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  padding: 0px !important;
}

.banner {
  margin-top: 0px;
}
.bottom {
  margin-bottom: 0px;
}

@media only screen and (max-width: 767px) {
  .banner {
    margin-top: 90px;
  }
  .bottom {
    margin-bottom: 90px;
  }
}
</style>
<template> 
    <div class="py-3"></div>
    <div class="p-3 row">
      <div class="col-lg-6 col-sm-12" align="center">
        <img class="img-fluid for-light banner" style="width:100%;max-height: 70vh;border-top-right-radius: 5px;border-top-left-radius: 5px;" src="../../assets/images/website/goldExample.png">
      </div>
      <div class="col-lg-6 col-sm-12 p-3">
        <div style="width:70%">
          <h4 class="py-1" style="color:#CA952B">CG Gold Signature – 1/4 Dinar</h4>
          <div style="font-size:1rem">Product Weight:</div>
          <h6>80g</h6>
          <div style="font-size:1rem">Price:</div>
          <h5>RM 500.00</h5>
          <div class="p-2"></div>
          <div style="border-bottom: 1px solid #CCCCCC"></div>
          <div class="p-2"></div>
          <div class="py-1" style="font-size:1rem">Product Description:</div>
          <div>
            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isnt anything embarrassing hidden in the middle of text.
          </div>
          <div class="py-5"></div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-3 col-md-8"></div>
          <div class="col-12 col-sm-6 col-md-4">
            <button class="btn btn-primary"  type="button" style=" width: 100%; color: #fefefe;" disabled>
                Coming soon
            </button>
          </div>
          <div class="col-12 col-sm-3 col-md-12"></div>
        </div>
      </div>
    </div>
    <!-- OTHER PRODUCT CHOICES -->
    <div class="p-3"></div>
    <h5 align="center">You may also like</h5>
    <div class="row p-3">
      <div class="col-12 col-md-2"></div>
      <div class="row col-12 col-md-8">
        <div class="p-2 col-12 col-sm-6 col-md-3" v-for="index in 4" :key="index">
          <div class="bg-white rounded" style="border: 1px solid #bbbbbb;">
            <img class="img-fluid for-light" style="width:100%;border-top-right-radius: 5px;border-top-left-radius: 5px;cursor:pointer" @click="redirectShop()" src="../../assets/images/website/goldExample.png">
            <div class="p-3">
              <div style="color:#2F2F3B;font-size:1rem;font-weight:450;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">CG Gold Signature – 1/4 Dinar</div>
              <div style="color:#CA952B;font-size:0.8rem">Dinar</div>
            </div>
            <div class="p-3 d-flex" style="justify-content: space-between;">
              <div style="cursor:pointer" @click="redirectShop()">
                <div style="color:#2F2F3B;font-size:1.2rem;font-weight:450">RM 300.00</div>
              </div>
              <!-- <div class="d-flex" style="position: relative;top:5px;">
                <h6>4.3</h6>
                <div class="px-1"></div>
                <svg style="position: relative;bottom:2px;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#F1BE46" class="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-2"></div>
    </div>
    <div class="p-3"></div>
</template>
<script>
export default {
    components: {
    },
    data() {
        return {
          selectedTab: 'Featured'
        };
    },
    computed: {
    },
    mounted() {
    },
    created() {
    },
    methods: {
      selectTab(tab) {
        this.selectedTab = tab;
      },
      redirectShop() {
        window.location.href = "https://ejen2u.com/";
      }
    },
};
</script>
