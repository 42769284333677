<style scoped>
.inactivePage {
    color: #cccccc;
}
.activePage {
    color: #CA952B;
}
.activeButtonPage {
    color: #CA952B;
}
.inactiveButtonPage {
    color: #cccccc;
}
.form-control::placeholder {
    color: #d9d9d9 !important;
}
.selected {
    color: #CA952B;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 3px solid #CA952B;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.search-table {
    table-layout: fixed;
    margin: 0px auto 0px auto;
}
.search-table,
td,
th {
    border-collapse: collapse;
}
th {
    padding: 10px 7px;
    font-size: 15px;
    color: #52526c;
    background: #f5f6f9;
}
td {
    padding: 5px 10px;
    height: 35px;
}

.search-table-outter {
    overflow-x: scroll;
}

.input-date {
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 7px;
}
</style>

<template>
    <div class="h-5" style="height: 10px"></div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="py-3">
                    <h4>Overview</h4>
                    <div>View summary of Cash Gold activities</div>
                </div>
                <!-- dashboard for admin -->
                <div v-if="this.role=='admin'">
                    <div class="py-2 row">
                        <div class="col-lg-7 col-sm-6"></div>
                        <div class="col-lg-5 col-sm-6 d-flex" align="right">
                            <div style="width: 48%;" class="date-picker col-5">
                                <label for="from-date"></label>
                                <input style="width:100%" type="date" id="from-date" class="input-date" @input="applyFilter()" v-model="fromDate">
                            </div>
                            <div style="width:4%;position:relative;top:20%;" align="center">-</div>
                            <div style="width: 48%;" class="date-picker col-5" align="right">
                                <label for="to-date"></label>
                                <input style="width:100%" type="date" id="to-date" class="input-date" @input="applyFilter()" v-model="toDate">
                            </div>
                        </div>
                        <div class="col-lg-7 col-sm-6"></div>
                        <div class="col-lg-5 col-sm-6" align="right" v-if="this.falseDate" style="color:#F06548">*Please input correct date</div>
                    </div>
                    <!-- Top Summary -->
                    <div class="row">
                        <div class="col-sm-12 col-xxl-4 py-2"> 
                            <div class="card small-widget mb-sm-0 p-3">
                            <div class="card-body primary">
                                <span class="f-light" style="font-size:1.3rem">Total Transactions</span>
                                <div class="d-flex align-items-end gap-1">
                                    <h4>{{ this.totalTransactions }}</h4>
                                    <!-- <span class="font-primary f-12 f-w-500"><i class="icon-arrow-up"></i><span>+50%</span></span> -->
                                </div>
                                <div class="bg-gradient"> 
                                <svg class="stroke-icon svg-fill">
                                    <use href="@/assets/svg/icon-sprite.svg#new-order"></use>
                                </svg>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-xxl-4 py-2"> 
                            <div class="card small-widget mb-sm-0 p-3">
                            <div class="card-body primary">
                                <span class="f-light" style="font-size:1.3rem">Total Agents</span>
                                <div class="d-flex align-items-end gap-1">
                                    <h4>{{ this.totalAgents }}</h4>
                                    <!-- <span class="font-primary f-12 f-w-500"><i class="icon-arrow-up"></i><span>+50%</span></span> -->
                                </div>
                                <div class="bg-gradient"> 
                                <svg class="stroke-icon svg-fill">
                                    <use href="@/assets/svg/icon-sprite.svg#new-order"></use>
                                </svg>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-xxl-4 py-2"> 
                            <div class="card small-widget mb-sm-0 p-3">
                            <div class="card-body primary">
                                <span class="f-light" style="font-size:1.3rem">Total Referral</span>
                                <div class="d-flex align-items-end gap-1">
                                    <h4>{{ this.totalReferral }}</h4>
                                    <!-- <span class="font-primary f-12 f-w-500"><i class="icon-arrow-up"></i><span>+50%</span></span> -->
                                </div>
                                <div class="bg-gradient"> 
                                <svg class="stroke-icon svg-fill">
                                    <use href="@/assets/svg/icon-sprite.svg#new-order"></use>
                                </svg>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="py-3"></div>
                    <div v-if="this.tableloader === false">
                        <div class="card">
                            <div class="p-4 row" style="align-items: center">
                                <div class="col-lg-2 col-md-4 col-sm-12" style=" font-size: 1rem; font-weight: 500; align-items: center; ">
                                    <span>Latest Transactions</span>
                                </div>
                                <div class="col-lg-8 col-md-6 col-sm-12 py-2">
                                    <div class="faq-form" style="width: 100%">
                                        <input class="form-control" v-model="inputText" @input="FilterHandling(inputText)" type="text" placeholder="Search name.."/>
                                        <vue-feather class="search-icon" type="search" ></vue-feather>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-12"></div>
                                <!-- <div class="col-1 d-flex" style="cursor: pointer; align-items: center">
                                    <div style=" font-size: 1rem; font-weight: 450; padding-right: 10px; " >
                                        Filter
                                    </div>
                                    <div style="position: relative; top: 2px">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#CA952B" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                        </svg>
                                    </div>
                                </div> -->
                            </div>

                            <!-- DATA Table -->
                            <!-- <div class="row py-1 px-4">
                                <div style="border-bottom: 1px solid #cccccc">
                                    <div class="col-sm-10 row">
                                        <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{ selected: selectedTab === '', }" @click="changeTab('')">
                                            All
                                        </div>
                                        <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{ selected: selectedTab === '0', }" @click="changeTab('0')">
                                            Success
                                        </div>
                                        <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{ selected: selectedTab === '1', }" @click="changeTab('1')">
                                            Approval
                                        </div>
                                        <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{ selected: selectedTab === '2', }" @click="changeTab('2')">
                                            Ended
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <!-- DATA Table -->
                            <div v-if="tableDataResult">
                                <div class="search-table-outter wrapper p-4" style="height: 420px; overflow-x: auto;">
                                    <table class="search-table inner" style="width: 100%; table-layout: fixed; min-width: 600px;">
                                        <thead>
                                            <tr style="height: 20px">
                                                <th style="min-width: 50px; width: 5%;">#</th>
                                                <th style="min-width: 350px;">Name</th>
                                                <th style="min-width: 200px;">Date paid</th>
                                                <th style="min-width: 350px;">Buyer name</th>
                                                <th style="min-width: 150px; text-align: center;">Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style="height: 60px" v-for="(item, index) in tableDataResult" :key="index">
                                                <td>{{ index + 1 }}.</td>
                                                <td>{{ item.product!=null ? item.product : '-' }}</td>
                                                <td>{{ item.date_paid!= null ? item.date_paid : '-' }}</td>
                                                <td>{{ item.buyer_name!=null ? item.buyer_name : '-' }}</td>
                                                <td style="text-align: center;">RM {{ item.price }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                            <div align="center" style="height: 300px" v-else>
                                <img style=" border-radius: 5px; width: 240px; height: 220px; " alt="" src="../../assets/images/no_data_image.png"/>
                                <h6>No Data</h6>
                            </div>

                            <!-- TABLE PAGINATION -->
                            <div class="row pagination" v-if="maxPage > 1">
                                <div class="col-md-7 col-sm-0 p-3">
                                    <div class="inactivePage" style="padding-left: 30px" >
                                        {{ "Page " + this.currentPage + " out of " + this.maxPage }}
                                    </div>
                                </div>
                                <div class="col-md-5 col-sm-12 p-3">
                                    <div class="d-flex" style=" padding-right: 30px; justify-content: flex-end; " >
                                        <button class="p-1" :class="{ activeButtonPage: this.currentPage != '1', inactiveButtonPage: this.currentPage == '1', }" @click="prevPage()" style=" width: 30%; border-top-left-radius: 8px; border-bottom-left-radius: 8px; border: 1px solid #dee2e6; " >
                                            Previous
                                        </button>
                                        <div @click="setFirstPage()" v-if="this.currentPage != 1" class="inactivePage p-1" align="center" style=" cursor: pointer; width: 10%; border: 1px solid #dee2e6; ">
                                            ...
                                        </div>
                                        <div @click="goToPage(pageNumber)" v-for="pageNumber in limitedPages" :key="pageNumber" :class="{ activePage: currentPage === pageNumber, inactivePage: currentPage !== pageNumber,}"
                                            class="p-1" align="center" style="cursor: pointer; width: 10%; border: 1px solid #dee2e6;" >
                                            {{ pageNumber }}
                                        </div>
                                        <div @click="setLastPage()" v-if="this.currentPage != this.maxPage" class="inactivePage p-1" align="center" style=" cursor: pointer; width: 10%; border: 1px solid #dee2e6;">
                                            ...
                                        </div>
                                        <button class="p-1" :class="{ activeButtonPage: currentPage === '1' && currentPage !== this.maxPage, inactiveButtonPage: currentPage === this.maxPage,}" @click="nextPage()" style=" width: 30%;  border-top-right-radius: 8px; border-bottom-right-radius: 8px; border: 1px solid #dee2e6;">
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-else style="padding-top: 30px">
                        <h6 class="mb-0 text-center">Please Wait...</h6>
                        <div class="loader-box">
                            <div class="loader-3"></div>
                        </div>
                    </div>
                </div>
                <!-- dashboard for agent -->
                <div class="row" v-else>
                    <!-- Campaign Programme -->
                    <div class="col-md-7 col-sm-12 row">
                        <!-- leaderboard -->
                        <div>
                            <div class="py-1"></div>
                            <div class="d-flex" style="justify-content: space-between;">
                                <h6>Leaderboard for {{ this.currentMonthYear }}</h6>
                            </div>
                            <div class="card" style="position: relative; text-align: center; height: 300px; width: 100%;">
                                <img src="../../assets/images/banner/bannerTopPerformer.jpg" style="width: 100%; height: 100%; object-fit: cover; border-radius: 15px;" />
                                <div class="d-flex" style="justify-content: center; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 100%;">
                                    <!-- 3rd -->
                                    <div style="position:relative;color:#CD7F32;top:30px;">
                                        <img style="border-radius:50%;max-width: 80%;border:3px solid #CD7F32" src="../../assets/images/user/1.jpg" />
                                        <h6 class="py-2" align="center">Ikram</h6>
                                    </div>
                                    <!-- 1st -->
                                    <div style="position:relative;color:#ffd700;margin: 0 10px;">
                                        <img style="border-radius:50%;max-width: 90%;border:3px solid #ffd700" src="../../assets/images/user/2.png" />
                                        <h6 class="py-2" align="center">Ikram</h6>
                                    </div>
                                    <!-- 2nd -->
                                    <div style="position:relative;color:#C0C0C0;top:25px;">
                                        <img style="border-radius:50%;max-width: 85%;border:3px solid #C0C0C0" src="../../assets/images/blog/9.jpg" />
                                        <h6 class="py-2" align="center">Ikram</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Card Summary  -->
                    <div class="col-md-5 col-sm-12 row">
                        <div class="py-1"></div>
                        <!-- Sales summary -->
                        <div class="col-sm-12 py-2"> 
                            <div class="card small-widget mb-sm-0 p-3" style="background-color: rgba(212, 170, 85, 0.8);color:#FEFEFE;">
                                <h6>Summary Sales {{ this.currentMonthYear }}</h6>
                                <div class="py-2 row"> 
                                    <div class="col-6">
                                        <div>Sales</div>
                                        <div class="bold">RM 1005.00</div>
                                    </div>
                                    <div class="col-6">
                                        <div>Commission</div>
                                        <div>RM 105.00</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 py-2"> 
                            <div class="card small-widget mb-sm-0 p-3" style="background-color: rgba(64, 81, 137, .18);">
                                <h6>900 points</h6>
                                <div class="py-2"> 
                                    <div>Campaign Progress</div>
                                    <div><progress id="progress" value="32" max="100" style="width:90%"></progress></div>
                                    <div>Collect Another 'CAMPAIGN POINTS BALANCE' points to unlock your reward of 'CAMPAIGN NAME'</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <h6>Rewards</h6>
                        <div class="row">
                            <div class="col-md-3 col-sm-12 p-3" v-for="(item,index) in this.campaignList" :key="index">
                                <div class="card" @click="redirectRewardDetails(item.id)" style="cursor:pointer">
                                    <div style="height:280px">
                                        <img :src="item.image" style="border-top-left-radius: 15px;border-top-right-radius: 15px;height:100%;width:100%"/>
                                    </div>
                                    <div class="p-2">
                                        <h6 class="">{{ item.name }}</h6>
                                        <div class="">Valid till {{ item.end_date }}</div>
                                        <div class="p-1"></div>
                                        <div class="">{{ item.point_required }} points</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    listCampaign,
    dashboardData,
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "Setup Client",

    components: {
    },

    data() {
        return {
            searchText: "",
            // selectedTab: "",
            tableData: null,
            tableDataResult: null,
            totalAgents: 0,
            totalTransactions: 0,
            totalReferral : 0,
            tableloader: false,
            indexNumbering: 1,
            currentPage: 1,
            itemsPerPage: 5,
            maxPage: 1,
            fromDate:null,
            toDate: null,
            falseDate: false,
            currentMonth: null,
            campaignList: null,
            role: localStorage.getItem('role'),
        };
    },

    beforeMount() {
        // this.loadListClient();
    },

    mounted() {},

    created() {
        this.loadDashboardData();

        //get today's month
        const options = { month: 'short', year: 'numeric', timeZone: 'Asia/Kuala_Lumpur' };
        const formatter = new Intl.DateTimeFormat('en-US', options);
        const today = new Date();
        this.currentMonthYear = formatter.format(today);

        if (this.role=='agent') {
            this.loadListCampaign();
        }
    },

    computed: {
        limitedPages() {
            const totalPages = Math.ceil(
                this.tableData.length / this.itemsPerPage
            );
            const displayPages = 3; // Number of pages to display
            const threshold = 3; // Threshold after which pages should shift

            let startPage = Math.max(
                1,
                this.currentPage - Math.floor(displayPages / 2)
            );
            let endPage = Math.min(totalPages, startPage + displayPages - 1);

            if (this.currentPage >= threshold) {
                startPage = this.currentPage - 1;
                endPage = Math.min(
                    totalPages,
                    this.currentPage + displayPages - 2
                );
            }

            return Array.from(
                { length: endPage - startPage + 1 },
                (_, index) => startPage + index
            );
        },
    },

    methods: {
        //FILTER CHANGE TAB
        // changeTab(tabProgressName) {
        //     this.selectedTab = tabProgressName;
        //     this.FilterHandling();
        // },
        
        // PREVIOUS PAGE FUNCTION
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.PageIndexNumber();
                this.PaginationHandling();
            }
        },

        // NEXT PAGE FUNCTION
        nextPage() {
            if (this.currentPage < this.maxPage) {
                this.currentPage++;
                this.PageIndexNumber();
                this.PaginationHandling();
            }
        },

        // SEND TO FIRST PAGE
        setFirstPage() {
            this.currentPage = 1;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        // SEND TO LAST PAGE
        setLastPage() {
            this.currentPage = this.maxPage;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        goToPage(page) {
            this.currentPage = page;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        PageIndexNumber() {
            if (this.currentPage == 1) {
                this.indexNumbering = 1;
            } else {
                if (this.currentPage == 2) {
                    this.indexNumbering = this.itemsPerPage;
                } else {
                    this.indexNumbering =
                        (this.currentPage - 1) * this.itemsPerPage;
                }
            }
        },

        // FILTER FUNCTION DATA MANAGEMENT
        FilterHandling(text) {
            //FILTER FIRST ORIGINAL DATA GIVEM
            // if (this.selectedTab != "") {
            //     this.tableDataResult = this.tableData.filter(
            //         (item) => item.client_status === this.selectedTab
            //     );
            // } else {
            //     //If FILTER ALL, FETCH ALL DATA FROM API
            //     this.tableDataResult = this.tableData;
            // }

            this.tableDataResult = this.tableData.data_transaction;

            if (text != undefined) {
                this.searchText = text;
            }

            // Filter based on search text
            if (this.tableDataResult !== null) {
                // Filter based on search text
                if (this.searchText.trim() !== "") {
                    const searchText = this.searchText.toLowerCase().trim();
                    this.tableDataResult =
                        this.tableData.data_transaction.filter(
                            (item) => (item.buyer_name && item.buyer_name.toLowerCase().includes(searchText))
                        );
                }
            }

            //RESET PAGE BACK TO 1
            this.currentPage = 1;
            // Slice the data based on pagination
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            //ASSIGN HOW MANY PAGES FROM THE FILTERED DATA RESULT
            this.maxPage = Math.ceil(
                this.tableDataResult.length / this.itemsPerPage
            );

            //Assign FINAL Value OF PAGE DATA
            if (this.tableDataResult.length > 0) {
                this.tableDataResult = this.tableDataResult.slice(
                    startIndex,
                    endIndex
                );
            } else {
                this.tableDataResult = null;
            }
        },

        PaginationHandling() {
            //FILTER THE SELECTED DATA
            // if (this.selectedTab != "") {
            //     this.tableDataResult = this.tableData.filter(
            //         (item) => item.client_status === this.selectedTab
            //     );
            // } else {
            //     this.tableDataResult = this.tableData;
            // }
            this.tableDataResult = this.tableData.data_transaction;
            console.log(this.tableDataResult)
            // Apply text search filter if searchText is not empty
            if (this.searchText != "") {
                const searchText = this.searchText.toLowerCase().trim();
                this.tableDataResult = this.tableData.data_transaction.filter(
                    (item) => (item.buyer_name && item.buyer_name.toLowerCase().includes(searchText))
                );
            }

            // Slice the data based on pagination
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            this.maxPage = Math.ceil(
                this.tableDataResult.length / this.itemsPerPage
            );

            //Assign FINAL Value OF PAGE DATA
            if (this.tableDataResult.length > 0) {
                this.tableDataResult = this.tableDataResult.slice(
                    startIndex,
                    endIndex
                );
                this.tableDataResult.forEach((dataObj) => {
                    if (this.currentPage > 1) {
                        this.indexNumbering++;
                    }

                    dataObj.numbering = this.indexNumbering;
                    if (this.currentPage == 1) {
                        this.indexNumbering++;
                    }
                });
            } else {
                this.tableDataResult = null;
            }
        },

        //WHEN DATE FROM TO TO IS APPLIED
        applyFilter() {
            if (this.fromDate!=null && this.toDate!=null) {
                if (this.fromDate<this.toDate) {
                    this.falseDate=false;
                    // console.log(this.fromDate);
                    // console.log(this.toDate);
                    this.loadDashboardData();
                } else {
                    this.falseDate=true;
                }
            }
        },

        redirectRewardDetails(campaignId) {
            this.$router.push("/campaign/details?campaign_id="+campaignId);
        },

        //--------API HANDLING -----------------//
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },

        formatDateTime(dateTimeString) {
            const [year, month, day] = dateTimeString.split("-");
            const formattedDateTime = `${day}-${month}-${year}`;
            return formattedDateTime;
        },

        async loadDashboardData() {
            this.tableloader = true;
            const dataPass = {
                date_from: this.fromDate,
                date_to: this.toDate,
            };
            try {
                await dashboardData(dataPass).then((response) => {
                    if (response.data.message == "success") {
                        this.tableData = response.data.data;
                        this.totalAgents = response.data.data.total_agents;
                        this.totalTransactions = response.data.data.total_transactions;
                        this.totalReferral = response.data.data.total_referrals;
                        this.tableloader = false;
                        this.currentPage = 1;
                        this.PaginationHandling();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async loadListCampaign() {
            this.tableloader = true;

            try {
                await listCampaign(this.$router).then((response) => {
                    if (response.data.success === true) {
                        this.campaignList = response.data.data;
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
    },
};
</script>