<template>
    <div>
        <footer
            class="footer"
            :class="[
                footer === 'footer-dark' ? 'footer-dark' : '',
                { close_icon: !togglesidebar },
            ]"
        >
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12 footer-copyright text-left">
                        <p class="mb-0">2024 Cash Gold Sdn Bhd</p>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
    name: "footerpage",
    data() {
        return {
            customizer: false,
        };
    },
    computed: {
        ...mapState({
            togglesidebar: (state) => state.menu.togglesidebar,
        }),
        ...mapGetters({
            footer: "layout/footer",
        }),
    },
};
</script>
