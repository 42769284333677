import routers from "../../router/index";
import axios from "axios";

const axiosInstance = axios.create({
    baseURL: "http://127.0.0.1:8000/api",
    // baseURL: "https://api.cashgold.com.my/api",
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, Accept",
        "Content-Type": "application/json",
        referrerPolicy: "no-referrer",
        Accept: "*/*",
    },
});
//
//
//
//
//
//
//enquiry form website
export const submitEnquiry = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/enquiry/create",
            {
                name: dataPass.name,
                email: dataPass.email,
                subject: dataPass.subject,
                message: dataPass.message,
            },
        );
        return $response;
    } catch (error) {
        // if (error.response.data.message === "Unauthorized") {
        //     localStorage.removeItem("token_expired");
        //     localStorage.removeItem("token_credential");
        //     routers.push("/auth/login");
        // }
    }
};
//
//
//
// auth
export const login = async (dataPass) => {
    try {
        const $response = await axiosInstance.post("/login", {
            email: dataPass.email,
            password: dataPass.password,
            remember: dataPass.remember,
        });
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const logout = async () => {
    try {
        const $response = await axiosInstance.post(
            "/logout",{},
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const register = async (dataPass) => {
    try {
        const $response = await axiosInstance.post("/register/agent", {
            name: dataPass.name,
            email: dataPass.email,
            icNumber: dataPass.icNumber,
            phone: dataPass.phone,
        });
        return $response;
    } catch (error) {
    }
};
//
//
//
//
//
//
//
//register user
export const registerUser = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/register/agent",
            {
                roleId: dataPass.role,
                name: dataPass.name,
                email: dataPass.email,
                phone: dataPass.phone,
                referralId: dataPass.referralId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const getRoles = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "master/roles",
            {},
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
// product
export const listProduct = async () => {
    try {
        const $response = await axiosInstance.post(
            "/product/list",
            {},
            {
                
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            // localStorage.removeItem("token_expired");
            // localStorage.removeItem("token_credential");
            // routers.push("/auth/login");
        }
    }
};

export const productAdd = async (formData) => {
    try {
          const token = localStorage.getItem("token_credential");
        if (!token) {
            throw new Error("Token not found");
        }
        const $response = await axiosInstance.post(
            "/product/add",
            {
                type: formData.type,
                image: formData.image[0]?.file, // Assuming single file
                name: formData.name,
                weight: formData.weight,
                description: formData.description,
                price: formData.price,
                rating: formData.rating,
                tier1: formData.tier1,
                tier2: formData.tier2,
                tier3: formData.tier3,
                tier4: formData.tier4,
                tier5: formData.tier5,
                status: formData.status,
               
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const productEdit = async (formData) => {
    try {
        const $response = await axiosInstance.post(
            "/product/update",
            {
                productId: formData.productId,
                type: formData.type,
                image: formData.image[0]?.file, // Assuming single file
                name: formData.name,
                weight: formData.weight,
                description: formData.description,
                price: formData.price,
                rating: formData.rating,
                tier1: formData.tier1,
                tier2: formData.tier2,
                tier3: formData.tier3,
                tier4: formData.tier4,
                tier5: formData.tier5,
                status: formData.status,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const packageDelete = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/preference/package/delete",
            {
                packageId: dataPass.packageId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//campaign
export const listCampaign = async () => {
    try {
        const $response = await axiosInstance.post(
            "/campaign/list",
            {},
            {
                
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            // localStorage.removeItem("token_expired");
            // localStorage.removeItem("token_credential");
            // routers.push("/auth/login");
        }
    }
};

export const addCampaign = async (formData) => {
    try {
          const token = localStorage.getItem("token_credential");
        if (!token) {
            throw new Error("Token not found");
        }
        const $response = await axiosInstance.post(
            "/campaign/add",
            {
                image: formData.image[0]?.file, // Assuming single file
                name: formData.name,
                type: formData.type,
                highlight: formData.highlight,
                tnc: formData.tnc,
                startDate: formData.start_date,
                endDate: formData.end_date,
                redemptionLimit: formData.redemption_limit,
                limitedTo: formData.limited_to,
                status: formData.status,
            //pointRequired: formData.point_required,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const editCampaign = async (formData) => {
    try {
          const token = localStorage.getItem("token_credential");
        if (!token) {
            throw new Error("Token not found");
        }
        const $response = await axiosInstance.post(
            "/campaign/update",
            {
                id: formData.id,
                image: formData.image[0]?.file, // Assuming single file\
                name: formData.name,
                type: formData.type,
                highlight: formData.highlight,
                tnc: formData.tnc,
                startDate: formData.start_date,
                endDate: formData.end_date,
                redemptionLimit: formData.redemption_limit,
                limitedTo: formData.limited_to,
                status: formData.status,
                //pointRequired: formData.point_required,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
//
//
// client profile
export const clientProfileDetail = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/dashboard/view",
            {
                client_id: dataPass.client_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const uploadProfilePic = async (formData) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/profile_pic/edit",
            {
                profile_pic: formData.profile_pic,
                client_id: formData.client_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const addPaymentJourney = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/payment_journey/add",
            {
                client_id: dataPass.client_id,
                payment_amount: dataPass.payment_amount,
                payment_date: dataPass.payment_date,
                payment_remarks: dataPass.payment_remarks,
                payment_iteration: dataPass.payment_iteration,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const editPaymentJourney = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/payment_journey/edit",
            {
                client_id: dataPass.client_id,
                payment_id: dataPass.id,
                payment_amount: dataPass.payment_amount,
                payment_date: dataPass.payment_date,
                payment_remarks: dataPass.payment_remarks
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const deletePaymentJourney = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/payment_journey/delete",
            {
                payment_id: dataPass.payment_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
//
//user profile
export const userProfile = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/user/profile",
            {
                userId: dataPass.user_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const updateProfile = async (formData) => {
    try {
        const $response = await axiosInstance.post(
            "/user/update",
            {
                userId : formData.user_id,
                name: formData.user_name,
                ic_number: formData.ic_number,
                phone: formData.phone,
                address1: formData.address1,
                address2: formData.address2,
                post_code: formData.postCode,
                state: formData.state,
                country: 'Malaysia',
                bank_account_number : formData.bank_account_number,
                bank_name : formData.bank_name,
                bank_account_holder: formData.bank_account_holder,
                profile_img: formData.image
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const currentSubscription = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "user/subscription",
            {
                userId : dataPass.user_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const verifyPassword = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/password/verify",
            {
                userId: dataPass.user_id,
                oldPassword: dataPass.password,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const changePassword = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/password/change",
            {
                userId: dataPass.user_id,
                newPassword: dataPass.new_password,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const goldRedemption = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/redeem/add",
            {
                userId: dataPass.user_id,
                totalGold: dataPass.total_gold_redeem,
                minWeightRedeem: dataPass.min_weight_redeem,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//Setting Company
export const companyDetails = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/company/detail",
            {
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const updateCompany= async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/company/update",
            {
                name: dataPass.name,
                email: dataPass.email,
                phone: dataPass.phone,
                address1: dataPass.address1,
                address2: dataPass.address2,
                postCode: dataPass.postCode,
                state: dataPass.state,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const updateGold= async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/company/gold-setup",
            {
                price : dataPass.price,
                minWeightGold : dataPass.min_weight_gold
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//My shop
export const createSubscription = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/subscription/create",
            {
                productId: dataPass.product_id,
                agentId: dataPass.agent_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const purchaseProduct = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/product/purchase",
            {
                productId: dataPass.product_id,
                agentId: dataPass.agent_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//dashboard
export const dashboardData = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/master/dashboard",
            {
                userId: dataPass.user_id,
                startDate: dataPass.date_from,
                endDate: dataPass.date_to,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
// manage order
export const listGoldRedemption = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "redeem/list",
            {
                userId: dataPass.userId,
            },
            {
                
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            // localStorage.removeItem("token_expired");
            // localStorage.removeItem("token_credential");
            // routers.push("/auth/login");
        }
    }
};
export const updateGoldOrderStatus = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "redeem/update",
            {
                orderId: dataPass.orderId,
                status: dataPass.status,
                trackingNumber: dataPass.trackingNumber,
                shippingMethod: dataPass.shippingMethod,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};



