<style scoped>
.round {
    position: relative;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 20%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: -1px;
    width: 24px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 5px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.selected {
    color: #04318c;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 3px solid #04318c;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

/* .round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
} */

.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
    border-color: #66bb6a;
}

/* The Modal (background) */
.modal2 {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content2 {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  overflow:hidden; 
  border: 1px solid #888;
  width: 40%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.image-container {
    position: relative;
    display: inline-block; /* or use width: 100%; to make it full width */
}

.img-fluid {
    display: block;
}

.centered-content {
    position: absolute;
    top: 27%;
    left: 50%;
    height: 20vh;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 60%;
}

.logo {
    display: block;
    margin: 0 auto;
}

.centered-text {
    width:100%;
    background-color: rgba(255, 255, 255); /* Optional: to make the background semi-transparent */
    padding: 15px;
    border-radius: 15px; /* Optional: to give the div rounded corners */
    margin-top: 10px; /* Adjust this value to control the spacing between the logo and the text */
}

.select-home {
  width:100%;
  border: 1px solid #BBBBBB;
  color:#BBBBBB;
}

.select-type {
  width:50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-type {
  background:#CA952B;
  color:#FEFEFE;
  width:100%;
}

.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  padding: 0px !important;
}

.banner {
  margin-top: 0px;
}
.bottom {
  margin-bottom: 0px;
}

@media only screen and (max-width: 767px) {
  .banner {
    margin-top: 90px;
  }
  .bottom {
    margin-bottom: 90px;
  }
}
</style>
<template> 
  <!-- ENQUIRY FORM -->
  <div class="p-5 row banner" style="background-color:#fefefe">
    <!-- LEFT SIDE IMAGE -->
    <div class="col-12 col-sm-6">
      <img class="img-fluid for-light" src="../../assets/images/website/contactUsForm.jpg" style="width:100%;height:100%" alt="loginpage"/>
    </div>
    <!-- RIGHT SIDE FORM -->
    <div class="col-12 col-sm-6">
      <h3>Any Enquiry?</h3>
      <div style="font-size:1rem">Fill in the enquiry form and let us help you.</div>
      <div class="bg-white p-3 rounded" style="width:100%">
        <div class="py-2" style="font-size: 1rem">
            <div style="font-weight: 450">Name</div>
            <input class="form-control" type="text" placeholder="Name" v-model="this.name"/>
        </div>
        <div class="py-2" style="font-size: 1rem">
            <div style="font-weight: 450">Email</div>
            <input class="form-control" type="text" placeholder="Email" v-model="this.email"/>
        </div>
        <div class="py-2" style="font-size: 1rem">
            <div style="font-weight: 450">Subject</div>
            <input class="form-control" type="text" placeholder="Subject" v-model="this.subject"/>
        </div>
        <div class="py-2" style="font-size: 1rem">
            <div style="font-weight: 450">Message</div>
            <textarea class="form-control" ype="text" placeholder="Insert Detail" v-model="this.message"></textarea>
        </div>
        <div class="py-1"></div>
        <!-- button submit form -->
        <div class="row" align="right">
          <div class="col-12 col-md-8"></div>
          <div class="col-12 col-md-4">
            <button class="btn btn-primary btn-block" style="width:100%" align="center" @click="submitEnquiryForm()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-1"></div>

  <!-- CONTACT DETAILS -->
  <div align="center">
    <h2>Need further help?</h2>
    <div  class="px-2" style="font-size: 1rem;">Contact us through our contact details below for futher enquiries</div>
  </div>
  <div class="py-3"></div>
  <div class="d-flex">
    <div style="width:20%"></div>
    <div class="row" style="width:60%;justify-content: space-around;">
      <div class="p-2 col-12 col-md-4">
        <div class="p-2 rounded" style="background-color:rgb(	216,178,102, 0.5);height:25vh">
          <div align="center">
            <svg xmlns="http://www.w3.org/2000/svg" width="20%" height="20%" fill="#CA952B" class="bi bi-envelope-check" viewBox="0 0 16 16">
              <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z"/>
              <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686"/>
            </svg>
            <div class="py-2"></div>
            <h5 class="p-1">Email</h5>
            <div class="p-1">Our Team Ready to help, contact us at</div>
            <h6 style="color:#CA952B">cashgold@gmail.com</h6>
          </div>
        </div>
      </div>
      <div class="p-2 col-12 col-md-4">
        <div class="p-2 rounded" style="background-color:rgb(	216,178,102, 0.5);height:25vh">
          <div align="center">
            <svg xmlns="http://www.w3.org/2000/svg" width="20%" height="20%" fill="#CA952B" class="bi bi-building-fill-check" viewBox="0 0 16 16">
              <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514"/>
              <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v7.256A4.5 4.5 0 0 0 12.5 8a4.5 4.5 0 0 0-3.59 1.787A.5.5 0 0 0 9 9.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .39-.187A4.5 4.5 0 0 0 8.027 12H6.5a.5.5 0 0 0-.5.5V16H3a1 1 0 0 1-1-1zm2 1.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5m3 0v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5m3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5M7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5M4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
            </svg>
            <div class="py-2"></div>
            <h5 class="p-1">Office</h5>
            <div class="p-1">Come to our office at</div>
            <h6 style="color:#CA952B">IOI Conezion, M-3-01 & M-3-02, 62502 Putrajaya, W.P. Putrajaya</h6>
          </div>
        </div>
      </div>
      <div class="p-2 rounded col-12 col-md-4">
        <div class="p-2 rounded" style="background-color:rgb(	216,178,102, 0.5);height:25vh">
          <div align="center">
            <svg xmlns="http://www.w3.org/2000/svg" width="20%" height="20%" fill="#CA952B" class="bi bi-headset" viewBox="0 0 16 16">
              <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5"/>
            </svg>
            <div class="py-2"></div>
            <h5 class="p-1">Customer support</h5>
            <div class="p-1">Call/whatsapp us at</div>
            <h6 style="color:#CA952B">013-7686541</h6>
          </div>
        </div>
      </div>
    </div>
    <div style="width:20%"></div>
    <!-- <div style="width:50%;">
      <div class="d-flex py-3" style="justify-content: space-evenly;">
        <div class="p-3 rounded" style="background-color:rgb(202, 149, 43, 0.7)">
        </div>
      </div>
    </div> -->
  </div>
  <div class="py-3"></div>
</template>
<script>
import { submitEnquiry } from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";
import { ref } from "vue";

export default {
    components: {
    },
    data() {
        return {
          selectedTab: 'Featured',
          name: null,
          email: null,
          subject: null,
          message: null,
        };
    },
    computed: {
    },
    mounted() {
    },
    created() {
    },
    methods: {
      selectTab(tab) {
        this.selectedTab = tab;
      },
      //TOAST NOTIFICATION
      notificationError(message) {
        toast(message, {
            theme: "colored",
            type: "error",
        });
      },
      notificationWarning(message) {
        toast(message, {
            theme: "colored",
            type: "warning",
        });
      },
      notificationSuccess(message) {
        toast(message, {
            theme: "colored",
            type: "success",
        });
      },

      redirectDetails() {
        this.$router.push("/setting/scheduler");
      },

      async submitEnquiryForm() {
        try {
          const dataPass = {
            name : this.name,
            email : this.email,
            subject : this.subject,
            message : this.message,
          };

          await submitEnquiry(dataPass).then((response) => {
            if (response.data.success == true) {
              this.notificationSuccess(response.data.message);
            } else {
              this.notificationError("Something went wrong!");
            }
          });
        } catch (error) {
          this.notificationError("Something went wrong!");
        }
      },
    },
};
</script>
