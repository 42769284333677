<style scoped>
.inactivePage {
    color: #cccccc;
}
.activePage {
    color: #CA952B;
}
.activeButtonPage {
    color: #CA952B;
}
.inactiveButtonPage {
    color: #cccccc;
}
.form-control::placeholder {
    color: #d9d9d9 !important;
}
.selected-type {
  border-bottom:2px solid #CA952B;
  width:100%;
}
.search-table {
    table-layout: fixed;
    margin: 0px auto 0px auto;
}
.search-table,
td,
th {
    border-collapse: collapse;
}
th {
    padding: 10px 7px;
    font-size: 15px;
    color: #52526c;
    background: #f5f6f9;
}
td {
    padding: 5px 10px;
    height: 35px;
}

.search-table-outter {
    overflow-x: scroll;
}

/* file upload button */
input[type="file"]::file-selector-button {
    border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
}

/* file upload button hover state */
input[type="file"]::file-selector-button:hover {
    background-color: #f3f4f6;
}

/* file upload button active state */
input[type="file"]::file-selector-button:active {
    background-color: #e5e7eb;
}

.w3-container {
    color: #fff !important;
    background-color: #2196F3 !important;
    padding: 0.01em 16px;
    border-radius: 8px;
}
</style>

<template>
    <div class="h-5" style="height: 10px"></div>

    <div class="container-fluid">
        <div class="py-3"></div>
        <h4>My Profile</h4>
        <div class="py-1"></div>
        <div class="row">
            <div class="col-lg-4 col-md-12 p-3">
                <div class="card p-3" style="align-items: center;">
                    <img style="border-radius:50%;max-height: 10vh;width: 100px;" :src="this.profile_image ? this.profile_image : this.defaultImage" />
                    <div class="py-1"></div>
                    <div class="row">
                        <div class="col-1"></div>
                        <div class="col-10">
                           <input type="file" ref="fileInput1" single @change="handleFileChange1" /> 
                        </div>
                        <div class="col-1"></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-12 p-3">
                <div class="rounded" style="background-color: #fefefe;">
                    <!-- TAB  -->
                    <div >
                        <div class="d-flex" style="width: 100%;">
                            <div class="select-type" @click="changeTab('account')">
                                <div class="p-3" align="center" :class="{ 'selected-type': selectedTab === 'account' }" style="cursor:pointer">Account Details</div>
                            </div>
                            <div class="select-type" @click="changeTab('subscription')" v-if="this.role=='agent'">
                                <div class="p-3" align="center" :class="{ 'selected-type': selectedTab === 'subscription' }" style="cursor:pointer">Subscription</div>
                            </div>
                            <div class="select-type" @click="changeTab('password')">
                                <div class="p-3" align="center" :class="{ 'selected-type': selectedTab === 'password' }" style="cursor:pointer">Change Password</div>
                            </div>
                        </div>
                    </div>
                    <!-- TAB DETAILS -->
                    <!-- account -->
                    <div class="p-3 row" v-if="this.selectedTab === 'account'">
                        <div class="col-lg-6 col-md-12">
                            <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Name</div>
                            <div style="display: flex; align-items: center;">
                                <input v-model="this.user_name" class="form-control" type="text" placeholder="Ahmad"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">IC Number</div>
                            <div style="display: flex; align-items: center;">
                                <input v-model="this.ic_number" class="form-control" type="text" placeholder="8978189199922"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Phone</div>
                            <div style="display: flex; align-items: center;">
                                <input v-model="this.phone" class="form-control" type="number" placeholder="0136654321"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Email Address</div>
                            <div style="display: flex; align-items: center;">
                                <input class="form-control" type="text" v-model="this.email" placeholder="ikram134033@gmail.com" disabled/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Address 1</div>
                            <div style="display: flex; align-items: center;">
                                <input v-model="this.address1" class="form-control" type="text" placeholder="input your address"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Address 2</div>
                            <div style="display: flex; align-items: center;">
                                <input v-model="this.address2" class="form-control" type="text" placeholder="input your address"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Postcode</div>
                            <div style="display: flex; align-items: center;">
                                <input v-model="this.post_code" class="form-control" type="text" placeholder="57640"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">State</div>
                            <div style="display: flex; align-items: center;">
                                <input v-model="this.state" class="form-control" type="text" placeholder=""/>
                            </div>
                        </div>
                        <!-- bank details -->
                        <div class="col-lg-6 col-md-12">
                            <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Bank Name</div>
                            <div style="display: flex; align-items: center;">
                                <input v-model="this.bank_name" class="form-control" type="text" placeholder="Ahmad"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Account Holder Name</div>
                            <div style="display: flex; align-items: center;">
                                <input v-model="this.bank_owner" class="form-control" type="text" placeholder="8978189199922"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Account Number</div>
                            <div style="display: flex; align-items: center;">
                                <input v-model="this.bank_number" class="form-control" type="number" placeholder="0136654321"/>
                            </div>
                        </div>
                        <div class="py-3"></div>
                        <div class="row col-12" align="right">
                            <div class="col-12 col-md-8"></div>
                            <div class="col-12 col-md-4" align="center">
                                <button class="btn" @click="updateProfile()" type="button" style=" width: 100%;color:#fefefe;background-color: #CA952B !important;border-color: #CA952B !important;">Save</button>
                            </div>
                        </div>
                    </div>
                    <!-- subscription record -->
                    <div class="p-3" v-if="this.selectedTab === 'subscription'">
                        <div v-if="this.current_subs_id==null">
                            <div class="py-3"></div>
                            <div align="center">
                                <div  style="color:#F04438;">No Active subscription. <div><a href="/shop/main" style="">Subscribe Now</a></div></div>    
                            </div>
                        </div>
                        <div v-else>
                            <div class="py-2 row">
                                <div class="col-7 col-sm-8"><h5>Subscription</h5></div>
                                <div class="col-5 col-sm-4" align="right">
                                    <button class="btn btn-primary" type="button" style="position:relative;bottom:10px;width: 100%; color: #fefefe;" @click="redirectToShop()">Change Plan</button>
                                </div>
                            </div>
                            <div style="border-bottom: 1px solid #cccccc"></div>
                            <div class="row py-3">
                                <h6 class="col-12 col-md-2 py-2" style="position:relative;top:30px">Current subscription</h6>
                                <div class="col-12 col-md-10 py-2 d-flex py-3">
                                    <img style="border-radius:50%;height:80px;" :src="this.current_subs_image" />
                                    <div class="px-2"></div>
                                    <div style="position:relative;top:25px;font-size:1.2rem">{{ this.current_subs_name }}</div>
                                </div>
                                <h6 class="col-12 col-md-2 py-2">Package description</h6>
                                <div class="col-12 col-md-10 py-2">{{ this.current_subs_description }}</div>
                                <h6 class="col-12 col-md-2 py-3">Redemption progress</h6>
                                <div class="col-12 col-md-10 py-3 d-flex">
                                    <div class="px-1" style="width:15%;position:relative;top:15px;" align="center">
                                        <svg version="1.1" fill="#CA952B" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 107.33" style="enable-background:new 0 0 122.88 107.33;width: 70%;" xml:space="preserve">
                                            <g>
                                                <path class="st0" d="M12.49,28.62l-0.26,0.21c1.72,2.11,2.43,4.6,2.14,7.46c-0.29,2.86-1.5,5.15-3.61,6.87l0.21,0.26 c2.11-1.72,4.6-2.43,7.46-2.13c12.88,1.34,3.67,8.8,4.99-4.07c0.29-2.86,1.5-5.15,3.61-6.87l-0.21-0.26 c-2.11,1.72-4.6,2.43-7.46,2.14C16.5,31.93,14.21,30.73,12.49,28.62L12.49,28.62L12.49,28.62z M31.26,64.84l8.86-31.19 c0.32-1.14,1.36-1.89,2.49-1.89v0H80.4c1.26,0,2.3,0.9,2.54,2.08l9.1,31.15c0.4,1.37-0.39,2.8-1.76,3.2 c-0.24,0.07-0.48,0.1-0.72,0.1v0.01H33.7c-1.43,0-2.59-1.16-2.59-2.59C31.11,65.4,31.16,65.11,31.26,64.84L31.26,64.84z M62,103.86 l8.86-31.19c0.32-1.14,1.36-1.89,2.49-1.89v0h37.79c1.26,0,2.3,0.9,2.54,2.08l9.1,31.15c0.4,1.37-0.39,2.8-1.76,3.2 c-0.24,0.07-0.48,0.1-0.72,0.1v0.01H64.44c-1.43,0-2.59-1.16-2.59-2.59C61.85,104.43,61.9,104.14,62,103.86L62,103.86z M75.3,75.96 l-7.43,26.18h48.99l-7.65-26.18H75.3L75.3,75.96z M0.15,103.86l8.86-31.19c0.32-1.14,1.36-1.89,2.49-1.89v0h37.79 c1.26,0,2.3,0.9,2.54,2.08l9.1,31.15c0.4,1.37-0.39,2.8-1.76,3.2c-0.24,0.07-0.48,0.1-0.72,0.1v0.01H2.59 c-1.43,0-2.59-1.16-2.59-2.59C0,104.43,0.05,104.14,0.15,103.86L0.15,103.86z M13.45,75.96l-7.43,26.18H55l-7.65-26.18H13.45 L13.45,75.96z M44.56,36.94l-7.43,26.18h48.99l-7.65-26.18H44.56L44.56,36.94z M44.31,0l-0.26,0.21c1.72,2.11,2.43,4.6,2.14,7.46 c-0.29,2.86-1.5,5.15-3.61,6.87l0.21,0.26c2.11-1.72,4.6-2.43,7.47-2.13c2.87,0.3,5.16,1.5,6.87,3.6l0.26-0.21 c-1.72-2.11-2.43-4.6-2.14-7.46c0.29-2.86,1.5-5.15,3.61-6.87l-0.21-0.26c-2.11,1.72-4.6,2.43-7.46,2.14 C48.32,3.31,46.03,2.11,44.31,0L44.31,0L44.31,0z M86.63,2.31l-0.47,0.39c3.14,3.86,4.45,8.41,3.91,13.65 c-0.54,5.24-2.74,9.43-6.6,12.57l0.39,0.47c3.86-3.14,8.41-4.45,13.66-3.9c5.24,0.54,9.43,2.75,12.57,6.6l0.47-0.39 c-3.14-3.86-4.45-8.41-3.91-13.65c0.54-5.24,2.74-9.43,6.6-12.57L112.85,5c-3.86,3.14-8.41,4.45-13.65,3.91 C93.96,8.37,89.77,6.17,86.63,2.31L86.63,2.31L86.63,2.31z"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div style="width:85%">
                                        <div>
                                            <div>Total Gold Collected:<span style="font-weight:600">{{ this.total_gold_have }}g</span></div>
                                            <div>Minimum weight(g) to redeem:<span style="font-weight:600">{{ this.max_redeem }}g</span></div>
                                            <div>Gold Available for redeem:<span style="font-weight:600">{{ this.redeemAvailable }} ({{ this.weightGoldCanRedeem }}g)</span></div>
                                        </div>
                                        <div class="py-2"></div>
                                        <div>Progress to redeem next gold:</div>
                                        <div>
                                            <!-- <progress id="progress" :value="this.redemption_progress" :max="this.max_redeem" style="width:100%"></progress> -->
                                            <div style="color: #000 !important;background-color: #f1f1f1 !important;border-radius: 16px;">
                                                <div class="w3-container" :style="{ width: this.percentageProgress + '%' }" align="center">{{ this.percentageProgress }}%</div>
                                            </div>
                                        </div>
                                        <div class="py-1"></div>
                                        <div class="row">
                                            <div class="col-3 col-sm-4 col-md-8"></div>
                                            <div class="col-6 col-sm-8 col-md-4">
                                                <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#redeemGold" type="button" style="width: 100%; color: #fefefe;" :disabled="this.disabledRedeem">Redeem</button>
                                            </div>
                                            <div class="col-3 col-md-12"></div>
                                        </div>
                                    </div>
                                </div>
                                <h6 class="col-3 py-2">Monthly plan</h6>
                                <div class="col-9 py-2">RM {{ this.current_subs_price }} /month</div>
                            </div>
                            <h5>Subscription Record</h5>
                            <div >
                                <div class="search-table-outter wrapper p-4">
                                    <table class="search-table inner">
                                        <tr>
                                            <th style="min-width: 50px;">#</th>
                                            <th style="min-width: 250px;">Package Name</th>
                                            <th style="min-width: 150px;">Status</th>
                                            <th style="min-width: 250px;">Renewal Date</th>
                                            <th style="min-width: 150px;text-align:center">Total Price</th>
                                            <th style="min-width: 250px;text-align:center">Action</th>
                                        </tr>
                                        <tr v-for="(item, index) in this.subs_record" :key="index" >
                                            <td>{{ index+1 }}</td>
                                            <td style="text-align:center"><div class="py-2" style="text-align: left;">{{ item.package_name }}</div></td>
                                            <td><div class="py-2;">{{ item.package_status}}</div></td>
                                            <td><div>{{ item.date_transaction_paid}}</div></td>
                                            <td style="text-align: center"><div class="py-2" style="text-align: center;">RM {{ item.price}}</div></td>
                                            <td style="text-align: center"><div class="py-2" @click="viewInvoice()" style="text-align: center;cursor: pointer;color:#CA952B">View invoice</div></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>   
                        </div>
                    </div>
                    <!-- password -->
                    <div class="p-3 row" v-if="this.selectedTab === 'password'">
                        <!-- VERIFY PASS -->
                        <div v-if="this.verifyPassword==false">
                            <div class="col-12">
                                <div>Please type your account password</div>
                            </div>
                            <div class="col-12">
                                <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Old Password</div>
                                <div style="display: flex; align-items: center;">
                                    <input v-model="this.old_password" class="form-control" type="password" placeholder="Ahmad"/>
                                </div>
                            </div>
                            <div class="py-3"></div>
                            <div class="row">
                                <div class="col-3 col-md-4"></div>
                                <div class="col-6 col-md-8">
                                    <button class="btn" type="button" @click="verify()" style=" width: 100%;color:#fefefe;background-color: #CA952B !important;border-color: #CA952B !important;">
                                        Verify
                                    </button>
                                </div>
                                <div class="col-3 col-md-4"></div>
                            </div>
                        </div>
                        <!-- CHANGE PASSWORD -->
                        <div v-else>
                            <div class="col-12">
                                <div>Please type a new password</div>
                            </div>
                            <div class="col-12">
                                <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">New Password</div>
                                <div style="display: flex; align-items: center;">
                                    <input @input="checkPass()" v-model="this.new_password" class="form-control" type="password" placeholder="password"/>
                                </div>
                                <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Confirm New Password</div>
                                <div style="display: flex; align-items: center;">
                                    <input @input="checkPass()" v-model="this.confirm_password" class="form-control" type="password" placeholder="password"/>
                                </div>
                            </div>
                            <div class="py-3"></div>
                            <div class="row">
                                <div class="col-1 col-md-4"></div>
                                <div class="col-10 col-md-8">
                                    <button class="btn" type="button" @click="changePass()" style=" width: 100%;color:#fefefe;background-color: #CA952B !important;border-color: #CA952B !important;" :disabled="!this.matchPassword">
                                        Update Password
                                    </button>
                                </div>
                                <div class="col-1 col-md-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="py-3"></div>
        </div>
        <!-- POPUP REDEEM GOLD -->
        <div class="modal fade modal-bookmark" id="redeemGold" tabindex="-1" role="dialog" aria-hidden="true" >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Redeemption Details</h5>
                        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div style=" max-height: 60vh; overflow: scroll; overflow-x: hidden; ">
                        <div class="modal-body row" style="justify-content: space-between">
                            <div class="py-2 col-12 d-flex" style="font-size: 1rem;justify-content: space-evenly;">
                                <div style="font-weight: 450;width:50%">Total gold available for redeem:</div>
                                <input v-model="this.redeemAvailable" style="width:50%" value="" class="form-control" type="text" placeholder="500" disabled/>
                            </div>
                            <div class="py-2 col-12 d-flex" style="font-size: 1rem;justify-content: space-evenly;">
                                <div style="font-weight: 450;width:50%">Number of gold to redeem</div>
                                <div style="width:50%;display: flex; align-items: center;">
                                    <LabeledInput :value="this.goldToRedeem" :max="this.redeemAvailable" @input="updatedValue1"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ACTION BUTTON -->
                    <div class="row p-3">
                        <div class="col-12 d-flex" style="justify-content: flex-end" >
                            <div>
                                <button @click="redeemGold()" data-bs-dismiss="modal" class="btn btn-primary"  type="button" style=" width: 100%; color: #fefefe; ">
                                    Redeem Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- POPUP VIEW INVOICE -->
        <!-- <div class="modal fade modal-bookmark" id="redeemGold" tabindex="-1" role="dialog" aria-hidden="true" >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Invoice</h5>
                        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>
import { userProfile, currentSubscription, updateProfile, verifyPassword, changePassword, goldRedemption } from "@/helper/apiservice/axios-http";
import LabeledInput from '@/components/plusMinusInput.vue';
import { toast } from "vue3-toastify";
import router from "@/router";

export default {
    name: "Setup Client",

    components: {
        LabeledInput
    },

    data() {
        return {
            selectedTab: "account",
            defaultImage: "../../assets/images/user/6.jpg",
            selectedFiles1: null,
            user_name: null,
            profile_image: null,
            ic_number: null,
            phone: null,
            email_address: null,
            address1: null,
            address2: null,
            post_code: null,
            state: '',
            bank_name: null,
            bank_owner: null,
            bank_number: null,

            verifyPassword: false,
            old_password: null,
            matchPassword: false,
            new_password: null,

            current_subs_id: null,
            current_subs_name: null,
            current_subs_price: null,
            current_subs_desc: null,
            current_subs_image: null,
            redemption_progress: null, // progress bar detection
            subs_record: null, //array subscription list
            max_redeem: null,
            redeemed_gold: null,
            redeemAvailable: null,
            percentageProgress: 0,
            total_gold_have: 0,
            weightGoldCanRedeem: 0,
            disabledRedeem: true,

            goldToRedeem: 0,
            value1: 0,
            role: localStorage.getItem('role'),
        };
    },

    beforeMount() {
    },

    mounted() {
        this.loadProfileDetails();
        this.currentSubscription();
    },

    created() {
    },
    computed: {
    },

    methods: {
        //TOAST NOTIFICATION
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },

        handleFileChange1(event) {
            // Clear existing files
            this.selectedFiles1 = [];

            // Iterate through selected files
            Array.from(event.target.files).forEach((file) => {
                // Check if the file is an image
                if (file.type.startsWith("image/")) {
                    // Create a FileReader to read the image file
                    const reader = new FileReader();

                    // Define FileReader onload event to set image preview
                    reader.onload = () => {
                        this.selectedFiles1.push({
                            file: file,
                            preview: reader.result, // Set the image preview URL
                        });
                        this.image = this.selectedFiles1[0].preview;
                    };

                    // Read the image file as a data URL
                    reader.readAsDataURL(file);
                }
            });
        },

        //FILTER CHANGE TAB
        changeTab(tabProgressName) {
            this.selectedTab = tabProgressName;
            // this.FilterHandling();
        },
        //CHECK PASSWORD TO MATCH EACH OTHER
        checkPass() {
            if (this.new_password == this.confirm_password) {
                this.matchPassword = true;
            } else {
                this.matchPassword = false;
            }
        },
        redirectToShop(){
            this.$router.push("/shop/main")
        },

        //fetch user detaiils from API
        async loadProfileDetails() {
            try {
                const dataPass = {
                    user_id : localStorage.getItem("user_id"),
                };
                await userProfile(dataPass).then((response) => {
                    if (response.data.success == true) {
                        // profile details
                        this.profile_image = response.data.data.details.profile_img;
                        this.user_name = response.data.data.details.name;
                        this.email = response.data.data.email;
                        this.ic_number = response.data.data.details.ic_number;
                        this.phone = response.data.data.details.phone;
                        this.address1 = response.data.data.details.address1;
                        this.address2 = response.data.data.details.address2;
                        this.state= response.data.data.details.state;
                        this.post_code = response.data.data.details.postCode;
                        // bank details
                        this.bank_name = response.data.data.details.bank_name;
                        this.bank_owner = response.data.data.details.bank_account_holder;
                        this.bank_number = response.data.data.details.bank_account_number;
                        
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                console.log(error)
                this.notificationError("Something went wrong!");
            }
        },
        async currentSubscription() {
            try {
                const dataPass = {
                    user_id : localStorage.getItem("user_id"),
                };
                await currentSubscription(dataPass).then((response) => {
                    if (response.data.success == true) {
                        if (response.data.data.subscriptions!=null) {
                            // subscription details
                            this.current_subs_id = response.data.data.subscriptions.current_subscription_id;
                            this.current_subs_name = response.data.data.subscriptions.current_subscription_name;
                            this.current_subs_image = response.data.data.subscriptions.current_subscription_image;
                            this.current_subs_price = response.data.data.subscriptions.current_subscription_price;
                            this.current_subs_description = response.data.data.subscriptions.status;
                            this.subs_record = response.data.data.subscriptions.subscription_payment_list;

                            //gold progress calculation
                            this.max_redeem = parseFloat(response.data.data.min_weight_gold_redeem);
                            this.total_gold_have = parseFloat(response.data.data.total_gold_accumulative);
                            // this.max_redeem = 0.5;
                            // this.total_gold_have = 5.73;

                            this.redeemAvailable = Math.floor(this.total_gold_have / this.max_redeem);
                            // Calculate the remainder and fix floating-point precision issues
                            this.redemption_progress = parseFloat((this.total_gold_have % this.max_redeem).toFixed(2));
                            this.percentageProgress = parseInt((this.redemption_progress/this.max_redeem)* 100);
                            this.weightGoldCanRedeem = this.redeemAvailable*this.max_redeem;

                            if (this.redeemAvailable >= this.max_redeem) {
                                this.disabledRedeem = false;
                            }

                            console.log("Redeem Available:", this.redeemAvailable);
                            console.log("percentage :", this.percentageProgress);
                        }
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                console.log(error)
                this.notificationError("Something went wrong!");
            }
        },
        async updateProfile() {
            try {
                this.formData = new FormData();
                this.formData.image = this.selectedFiles1;
                this.formData.user_id = localStorage.getItem("user_id");
                this.formData.user_name = this.user_name;
                this.formData.ic_number = this.ic_number;
                this.formData.phone = this.phone,
                this.formData.address1 = this.address1,
                this.formData.address2 = this.address2,
                this.formData.postCode = this.post_code,
                this.formData.state = this.state,
                this.formData.bank_name = this.bank_name,
                this.formData.bank_account_holder = this.bank_owner,
                this.formData.bank_account_number = this.bank_number,

                await updateProfile(this.formData).then((response) => {
                    if (response.data.success == true) {
                        this.notificationSuccess("Profile Updated");
                        this.loadProfileDetails();
                        //reset profile pic file
                        this.$refs.profile_pic.value = null;
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
        async verify() {
            try {
                const dataPass = {
                    user_id : localStorage.getItem("user_id"),
                    password : this.old_password,
                };
                await verifyPassword(dataPass).then((response) => {
                    if (response.data.success == true) {
                        if (response.data.message == true) {
                            this.notificationSuccess("Password verified");
                            // display change password form
                            this.verifyPassword = true;
                            this.old_password = null;
                        } else {
                            //wrong password
                        }
                        
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
        async changePass() {
            try {
                const dataPass = {
                    user_id : localStorage.getItem("user_id"),
                    new_password : this.new_password,
                };
                await changePassword(dataPass).then((response) => {
                    if (response.data.success == true) {
                        this.notificationSuccess("Password changed");
                        // display verify password back
                        this.verifyPassword = false;
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
        async redeemGold() {
            try {
                const dataPass = {
                    user_id : localStorage.getItem("user_id"),
                    total_gold_redeem : this.goldToRedeem,
                    min_weight_redeem : this.max_redeem,
                };
                await goldRedemption(dataPass).then((response) => {
                    if (response.data.success == true) {
                        this.notificationSuccess("Gold Redeemed!");
                        this.currentSubscription();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
        updatedValue1(value) {
            this.goldToRedeem=value*this.max_redeem;
        },
        viewInvoice() {
            console.log('view invoice');
        },

    },
};
</script>