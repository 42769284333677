<style scoped>
.round {
    position: relative;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 20%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: -1px;
    width: 24px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 5px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.selected {
    color: #04318c;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 3px solid #04318c;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

/* .round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
} */

.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
    border-color: #66bb6a;
}

/* The Modal (background) */
.modal2 {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content2 {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  overflow:hidden; 
  border: 1px solid #888;
  width: 40%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.image-container {
    position: relative;
    display: inline-block; /* or use width: 100%; to make it full width */
}

.img-fluid {
    display: block;
}

.centered-content {
    position: absolute;
    top: 27%;
    left: 50%;
    height: 20vh;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 60%;
}

.logo {
    display: block;
    margin: 0 auto;
}

.centered-text {
    width:100%;
    background-color: rgba(255, 255, 255); /* Optional: to make the background semi-transparent */
    padding: 15px;
    border-radius: 15px; /* Optional: to give the div rounded corners */
    margin-top: 10px; /* Adjust this value to control the spacing between the logo and the text */
}

.select-home {
  width:100%;
  border: 1px solid #BBBBBB;
  color:#BBBBBB;
}

.select-type {
  width:50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-type {
  background:#CA952B;
  color:#FEFEFE;
  width:100%;
}

.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  padding: 0px !important;
}

</style>
<template> 
  <div class="d-flex" style="justify-content: space-around;">
    <div style="width:70%;">
      <div class="p-4"></div>
      <h3 align="center">Terms & Condition</h3>
      <div class="p-2"></div>
      <div style="text-indent: 50px;">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla at metus auctor, pharetra dolor a, accumsan urna. Fusce eleifend, velit vel pulvinar euismod, sem mi tristique dolor, ac hendrerit quam risus a lectus. Nam pellentesque mauris sit amet neque viverra, ut aliquet tellus finibus. Maecenas molestie efficitur consectetur. Nam venenatis aliquam magna vitae auctor. Vestibulum vitae tincidunt metus. Etiam tempus lacinia nibh, sed maximus tortor ultrices eget. Donec condimentum sollicitudin laoreet. Donec vitae aliquam risus. Praesent ornare malesuada fringilla. Praesent in ultricies ante, a lacinia diam.
        Phasellus molestie sodales lacus ut tristique. Duis placerat venenatis nunc. In sed laoreet ex. Vivamus dignissim elit id aliquet sollicitudin. Vestibulum fringilla scelerisque egestas. Quisque vitae finibus dolor, laoreet tincidunt nisl. Donec luctus elit id urna feugiat volutpat. Proin ut justo sed enim iaculis aliquet. Donec maximus dolor eget diam luctus, quis fermentum elit dictum. Nam ornare aliquam placerat.
        Fusce eleifend magna ante, eget tincidunt nisi rutrum ac. Aenean nec diam ipsum. Sed ullamcorper magna eget odio facilisis, a ullamcorper tortor ullamcorper. Cras sagittis et ex a laoreet. Nam non augue vel massa bibendum faucibus. Morbi nibh turpis, varius non turpis quis, accumsan pharetra purus. Duis dapibus elit in lacus porta, vitae efficitur purus ultrices. Duis in mauris vitae diam ornare aliquam. Morbi hendrerit diam eget metus aliquam gravida. Donec malesuada volutpat enim, id pretium nisl luctus a. Cras sed blandit dui. Pellentesque efficitur iaculis nisl in mollis. Duis ac varius leo, id facilisis sem. Nam sed nibh commodo, bibendum odio vel, convallis sapien. Integer ultrices suscipit arcu, eget dapibus augue porttitor vel. Vivamus fringilla arcu a sem consequat tincidunt.
      </div>
      <div class="p-1"></div>
      <div style="text-indent: 50px;">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla at metus auctor, pharetra dolor a, accumsan urna. Fusce eleifend, velit vel pulvinar euismod, sem mi tristique dolor, ac hendrerit quam risus a lectus. Nam pellentesque mauris sit amet neque viverra, ut aliquet tellus finibus. Maecenas molestie efficitur consectetur. Nam venenatis aliquam magna vitae auctor. Vestibulum vitae tincidunt metus. Etiam tempus lacinia nibh, sed maximus tortor ultrices eget. Donec condimentum sollicitudin laoreet. Donec vitae aliquam risus. Praesent ornare malesuada fringilla. Praesent in ultricies ante, a lacinia diam.
        Phasellus molestie sodales lacus ut tristique. Duis placerat venenatis nunc. In sed laoreet ex. Vivamus dignissim elit id aliquet sollicitudin. Vestibulum fringilla scelerisque egestas. Quisque vitae finibus dolor, laoreet tincidunt nisl. Donec luctus elit id urna feugiat volutpat. Proin ut justo sed enim iaculis aliquet. Donec maximus dolor eget diam luctus, quis fermentum elit dictum. Nam ornare aliquam placerat.
        Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla consequat nunc egestas egestas scelerisque. Nam placerat vestibulum urna, id luctus mauris tristique eget. Nam fringilla bibendum maximus. Sed ac mi nisi. Curabitur at maximus ante. Morbi auctor sollicitudin euismod. Etiam facilisis, enim sit amet condimentum venenatis, dui quam posuere enim, id fringilla ante augue sit amet felis. Phasellus a sagittis nisl. Nam in ex libero. Phasellus lacinia ex purus, eu gravida dui congue a. Donec massa odio, blandit at ultrices et, molestie at velit. Nulla luctus orci enim, non tincidunt elit congue nec.
      </div>
      <div class="p-4"></div>
    </div>
  </div>
</template>
<script>
import { toast } from "vue3-toastify";
import { ref } from "vue";

export default {
    components: {
    },
    data() {
        return {
          selectedTab: 'Featured'
        };
    },
    computed: {
    },
    mounted() {
    },
    created() {
    },
    methods: {
      selectTab(tab) {
        this.selectedTab = tab;
      },
      redirectDetails() {
        this.$router.push("/setting/scheduler");
      }
    },
};
</script>
