    <style scoped>
    .inactivePage {
        color: #cccccc;
    }
    .activePage {
        color: #CA952B;
    }
    .activeButtonPage {
        color: #CA952B;
    }
    .inactiveButtonPage {
        color: #cccccc;
    }
    .form-control::placeholder {
        color: #d9d9d9 !important;
    }
    .selected {
        color: #CA952B;
        font-size: 1rem;
        font-weight: 500;
        border-bottom: 3px solid #CA952B;
        box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
    .search-table {
        table-layout: fixed;
        margin: 0px auto 0px auto;
    }
    .search-table,
    td,
    th {
        border-collapse: collapse;
    }
    th {
        padding: 10px 7px;
        font-size: 15px;
        color: #52526c;
        background: #f5f6f9;
    }
    td {
        padding: 5px 10px;
        height: 35px;
    }

    .search-table-outter {
        overflow-x: scroll;
    }

    /* file upload button */
    input[type="file"]::file-selector-button {
        border-radius: 4px;
        padding: 0 16px;
        height: 40px;
        cursor: pointer;
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.16);
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
        margin-right: 16px;
        transition: background-color 200ms;
    }

    /* file upload button hover state */
    input[type="file"]::file-selector-button:hover {
        background-color: #f3f4f6;
    }

    /* file upload button active state */
    input[type="file"]::file-selector-button:active {
        background-color: #e5e7eb;
    }
    </style>

    <template>
        <div class="h-5" style="height: 10px"></div>

        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="p-4 row">
                            <div class="col-lg-10 col-md-8 col-4 d-flex align-items-center py-2" style="font-size: 1.2rem; font-weight: 500;">
                                Product
                            </div>
                            <div class="col-lg-2 col-md-4 col-8">
                                <button @click="clearModalProduct()" data-bs-toggle="modal" data-bs-target="#addPackage" class="p-2" 
                                    style="border-radius: 5px; background-color: #CA952B; color: #fefefe; width: 100%; border: none; display: flex; justify-content: space-evenly; align-items: center;">
                                    <svg xmlns="http://www.w3.org/2000/svg" style="width: 10%" fill="currentColor" class="bi bi-plus-square" viewBox="0 0 16 16">
                                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                    </svg>
                                    Add New Product
                                </button>
                            </div>
                        </div>
                    </div>


                    <div v-if="this.tableloader === false">
                        <div class="card">
                            <div class="p-4 row" style="align-items: center">
                                <div class="col-lg-2 col-sm-12" style="font-size: 1rem; font-weight: 500;" >
                                    <span>List of Products</span>
                                </div>
                                <div class="col-lg-8 col-8 col-sm-9 py-2">
                                    <div class="faq-form">
                                        <input class="form-control" v-model="inputText" @input="FilterHandling(inputText)" type="text" placeholder="Search name.." />
                                        <vue-feather class="search-icon" type="search" ></vue-feather>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-4 col-sm-3 d-flex" style="cursor: pointer;justify-content: right;">
                                    <div style=" font-size: 1rem; font-weight: 450; padding-right: 10px;" >Filter</div>
                                    <div style="position: relative; top: 2px">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#CA952B" class="bi bi-caret-down-fill" viewBox="0 0 16 16" >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <!-- DATA Table -->
                            <div class="row py-1 px-4">
                                <div style="border-bottom: 1px solid #cccccc">
                                    <div class="col-sm-10 row">
                                        <div style="cursor: pointer" class="p-3 col-sm-3 col-4" align="center" :class="{ selected: selectedTab === '', }" @click="changeTab('')">
                                            All
                                        </div>
                                        <div style="cursor: pointer" class="p-3 col-sm-3 col-4" align="center" :class="{ selected: selectedTab === 'subscription', }" @click="changeTab('subscription')" >
                                            Subscription
                                        </div>
                                        <div style="cursor: pointer" class="p-3 col-sm-3 col-4" align="center" :class="{ selected: selectedTab === 'product', }" @click="changeTab('product')" >
                                            Product
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="search-table-outter wrapper p-4" v-if="this.clientTableDataResult" style="overflow-x: auto;">
                                <table class="search-table inner" style="width: 100%; table-layout: fixed; min-width: 600px;">
                                    <tr>
                                        <th style="min-width: 50px;width: 5%;">#</th>
                                        <th style="min-width: 100px;text-align:center">Product</th>
                                        <th style="min-width: 350px;text-align:center"></th>
                                        <th style="min-width: 200px;text-align: center">Type</th>
                                        <th style="min-width: 200px;text-align: center">Status</th>
                                        <th style="min-width: 150px;">
                                            <span v-if="this.type=='subscription'">Monthly Charges</span>
                                            <span v-else>Price</span>
                                        </th>
                                        <th style="min-width: 150px;">weight (g)</th>
                                        <th style="text-align: center;min-width: 250px;">Action</th>
                                    </tr>
                                    <tr v-for="(item, index) in this.clientTableDataResult" :key="index" >
                                        <td style="">{{ (currentPage - 1) * itemsPerPage + index + 1 }}.</td>
                                        <td style="text-align:left">
                                            <div class="py-2" style="text-align: left;">
                                                <img style="height: 40px;" class="img-fluid for-light" :src="checkImageIcon(item.image)" alt="packagepage" />
                                            </div>
                                        </td>
                                        <td style="text-align:center">
                                            <div class="py-2;">
                                                <span>{{ item.name !== null ? item.name : "-" }}</span>
                                            </div>
                                        </td>
                                        <td style="text-align: center">
                                            <div class="py-2" style="text-align: center;">
                                                <span>{{ item.type !== null ? item.type : "-" }}</span>
                                            </div>
                                        </td>
                                        <td> RM {{ item.status !== null ? item.price : "-"}}</td>
                                        <td> RM {{ item.price !== null ? item.price : "-"}}</td>
                                        <td> {{ item.weight !== null ? item.weight : "-"}}</td>
                                        <td style="text-align: center;">
                                            <div style="display: flex;justify-content: center;">
                                                <div style="cursor: pointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" @click=" intializeEditPackage(item) " width="20" height="20" fill="#CA952B" class="bi bi-pen-fill" viewBox="0 0 16 16" data-bs-toggle="modal" data-bs-target="#addPackage" >
                                                        <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
                                                    </svg>
                                                </div>
                                                <div style="width: 20px"></div>
                                                <div class="deleteYearCard" style="cursor: pointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" @click=" deletePackage(item.id) " width="20" height="20" fill="#CA952B" class="bi bi-trash-fill" viewBox="0 0 16 16" >
                                                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <!-- EMPTY DATA -->
                            <div v-else align="center" style="height: 300px">
                                <img style=" border-radius: 5px; width: 240px; height: 220px;" alt="" src="../../assets/images/no_data_image.png"/>
                                <h6>No Data</h6>
                            </div>

                            <!-- TABLE PAGINATION -->
                            <div class="row pagination" v-if="maxPage > 1">
                                <div class="col-md-7 col-sm-12 p-3">
                                    <div class="inactivePage" style="padding-left: 30px">
                                        {{ "Page " + this.currentPage + " out of " + this.maxPage }}
                                    </div>
                                </div>
                                <div class="col-md-5 col-sm-12 p-3">
                                    <div class="d-flex" style=" padding-right: 30px; justify-content: center; " >
                                        <button class="p-1" :class="{ ctiveButtonPage: this.currentPage != '1', inactiveButtonPage: this.currentPage == '1', }" @click="prevPage()" style=" width: 30%; border-top-left-radius: 8px; border-bottom-left-radius: 8px; border: 1px solid #dee2e6; ">
                                            Previous
                                        </button>
                                        <div @click="setFirstPage()" v-if="this.currentPage != 1" class="inactivePage p-1" align="center" style=" cursor: pointer; width: 10%; border: 1px solid #dee2e6; ">
                                            ...
                                        </div>
                                        <div @click="goToPage(pageNumber)" v-for="pageNumber in limitedPages" :key="pageNumber" :class="{ activePage: currentPage === pageNumber, inactivePage: currentPage !== pageNumber, }" class="p-1" align="center"  style=" cursor: pointer; width: 10%; border: 1px solid #dee2e6; " >
                                            {{ pageNumber }}
                                        </div>
                                        <div @click="setLastPage()" v-if="this.currentPage != this.maxPage"  class="inactivePage p-1" align="center" style=" cursor: pointer; width: 10%; border: 1px solid #dee2e6; " >
                                            ...
                                        </div>
                                        <button class="p-1" :class="{ activeButtonPage: currentPage === '1' && currentPage !== this.maxPage, inactiveButtonPage: currentPage === this.maxPage, }" @click="nextPage()" style=" width: 30%; border-top-right-radius: 8px; border-bottom-right-radius: 8px; border: 1px solid #dee2e6; " >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-else style="padding-top: 30px">
                        <h6 class="mb-0 text-center">Please Wait...</h6>
                        <div class="loader-box">
                            <div class="loader-3"></div>
                        </div>
                    </div>

                    <!-- POPUP ADD PRODUCT -->
                    <div class="modal fade modal-bookmark" id="addPackage" tabindex="-1" role="dialog" aria-hidden="true" >
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title"><span v-if="this.packageId === null">Create</span><span v-else>Edit</span> Product</h5>
                                    <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" @click="clearModalProduct()"></button>
                                </div>
                                <div style=" max-height: 60vh; overflow: scroll; overflow-x: hidden; ">
                                    <div class="modal-body row" style="justify-content: space-between">
                                        <div class="py-2 col-6" style="font-size: 1rem">
                                            <div style="font-weight: 450">Type</div>
                                            <select v-model="this.type" class="form-select form-control-primary" name="select" required >
                                                <option value="" disabled selected>Select Type</option>
                                                <option value="subscription">Subscription</option>
                                                <option value="product">Product</option>
                                            </select>
                                        </div>
                                        <div class="py-2 col-12" style="font-size: 1rem" >
                                            <div style="font-weight: 450">Product Image (Recommended size 800*800)</div>
                                            <div style="text-align: left">
                                                <img style=" height: 100px; padding: 10px 0px 10px 0px; " class="img-fluid for-light" :src="this.image"/>
                                            </div>
                                            <div>
                                                <input type="file" single @change="handleFileChange1" />
                                            </div>
                                        </div>
                                        <div class="py-2 col-12" style="font-size: 1rem" >
                                            <div style="font-weight: 450">Name</div>
                                            <input v-model="this.name" class="form-control" type="text" placeholder="The Icon" required />
                                        </div>
                                        <div class="py-2 col-6" style="font-size: 1rem">
                                            <div style="font-weight: 450"> Weight (g)</div>
                                            <input v-model="this.weight" class="form-control" type="text" placeholder="500"  required />
                                        </div>
                                        <div class="py-2 col-6" style="font-size: 1rem">
                                            <div style="font-weight: 450"> 
                                                <span v-if="this.type=='subscription'">Monthly Charges</span>
                                                <span v-else>Price</span>
                                            </div>
                                            <input v-model="this.price" class="form-control" type="text" placeholder="5000"  required />
                                        </div>
                                        <div class="py-2 col-6" style="font-size: 1rem">
                                            <div style="font-weight: 450"> Status </div>
                                            <select v-model="this.status" class="form-select form-control-primary" name="select" required >
                                                <option value="" disabled selected>Select Status</option>
                                                <option value="active">Active</option>
                                                <option value="inactive">Inactive</option>
                                                <option value="suspend">Suspend</option>
                                            </select>
                                        </div>
                                        <div class="py-2 col-12" style="font-size: 1rem">
                                            <div style="font-weight: 450">Descriptions</div>
                                            <textarea v-model="this.description" class="form-control" style="height: 150px" type="text" placeholder="Detail about the package.." required></textarea>
                                        </div>
                                        <div v-if="this.type=='subscription'">
                                            <div class="py-2 col-12" style="font-size: 1rem">
                                                <div style="font-weight: 450">Commission Tier 1(%)</div>
                                                <input v-model="this.tier1" class="form-control" type="text" placeholder="5" required />
                                            </div>
                                            <div class="py-2 col-12" style="font-size: 1rem">
                                                <div style="font-weight: 450">Commission Tier 2(%)</div>
                                                <input v-model="this.tier2" class="form-control" type="text" placeholder="5" required />
                                            </div>
                                            <div class="py-2 col-12" style="font-size: 1rem">
                                                <div style="font-weight: 450">Commission Tier 3(%)</div>
                                                <input v-model="this.tier3" class="form-control" type="text" placeholder="5" required />
                                            </div>
                                            <div class="py-2 col-12" style="font-size: 1rem">
                                                <div style="font-weight: 450">Commission Tier 4(%)</div>
                                                <input v-model="this.tier4" class="form-control" type="text" placeholder="5" required />
                                            </div>
                                            <div class="py-2 col-12" style="font-size: 1rem">
                                                <div style="font-weight: 450">Commission Tier 5(%)</div>
                                                <input v-model="this.tier5" class="form-control" type="text" placeholder="5" required />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- ACTION BUTTON -->
                                <div class="row p-3">
                                    <div class="col-12 d-flex" style="justify-content: flex-end" >
                                        <div v-if="this.packageId !== null">
                                            <button @click=" deletePackage(this.packageId) " data-bs-dismiss="modal" class="btn btn-danger" type="button" style="width: 100%">
                                                Delete
                                            </button>
                                        </div>
                                        <div style="padding-right: 20px"></div>
                                        <div>
                                            <button @click="saveProduct()" data-bs-dismiss="modal" class="btn btn-primary"  type="button" style=" width: 100%; color: #fefefe; ">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>

    <script>
    import {
        listProduct,
        productAdd,
        productEdit,
        productDelete,
    } from "@/helper/apiservice/axios-http";
    import { toast } from "vue3-toastify";

    export default {
        name: "Setting Product",

        data() {
            return {
                searchText: "",
                selectedTab: "",
                clientTableData: null,
                clientTableDataResult: null,
                tableloader: false,
                indexNumbering: 1,
                formData: [],
                selectedPackage: "",
                currentPage: 1,
                itemsPerPage: 5,
                maxPage: 1,

                selectedFiles1: [],
                productId: null,
                type: '',
                name: null,
                weight: null,
                price: null,
                status: '',
                description: null,
                tier1: null,
                tier2: null,
                tier3: null,
                tier4: null,
                tier5: null,
            };
        },

        beforeMount() {
            this.loadListProduct();
        },

        mounted() {},

        created() {},

        computed: {
            limitedPages() {
                const totalPages = Math.ceil(
                    this.clientTableData.length / this.itemsPerPage
                );
                const displayPages = 3; // Number of pages to display
                const threshold = 3; // Threshold after which pages should shift
                let startPage = Math.max(
                    1,
                    this.currentPage - Math.floor(displayPages / 2)
                );
                let endPage = Math.min(totalPages, startPage + displayPages - 1);
                if (this.currentPage >= threshold) {
                    startPage = this.currentPage - 1;
                    endPage = Math.min(
                        totalPages,
                        this.currentPage + displayPages - 2
                    );
                }
                return Array.from(
                    { length: endPage - startPage + 1 },
                    (_, index) => startPage + index
                );
            },
        },

        methods: {
            //FILTER CHANGE TAB
            changeTab(tabProgressName) {
                this.selectedTab = tabProgressName;
                this.FilterHandling();
            },

            // PREVIOUS PAGE FUNCTION
            prevPage() {
                if (this.currentPage > 1) {
                    this.currentPage--;
                    this.PageIndexNumber();
                    this.PaginationHandling();
                }
            },

            // NEXT PAGE FUNCTION
            nextPage() {
                if (this.currentPage < this.maxPage) {
                    this.currentPage++;
                    this.PageIndexNumber();
                    this.PaginationHandling();
                }
            },

            // SEND TO FIRST PAGE
            setFirstPage() {
                this.currentPage = 1;
                this.PageIndexNumber();
                this.PaginationHandling();
            },

            // SEND TO LAST PAGE
            setLastPage() {
                this.currentPage = this.maxPage;
                this.PageIndexNumber();
                this.PaginationHandling();
            },

            goToPage(page) {
                this.currentPage = page;
                this.PageIndexNumber();
                this.PaginationHandling();
            },

            PageIndexNumber() {
                if (this.currentPage == 1) {
                    this.indexNumbering = 1;
                } else {
                    if (this.currentPage == 2) {
                        this.indexNumbering = this.itemsPerPage;
                    } else {
                        this.indexNumbering =
                            (this.currentPage - 1) * this.itemsPerPage;
                    }
                }
            },

            // FILTER FUNCTION DATA MANAGEMENT
            FilterHandling(text) {
                //FILTER FIRST ORIGINAL DATA GIVEM
                if (this.selectedTab != "") {
                    console.log(this.clientTableDataResult);
                    this.clientTableDataResult = this.clientTableData.filter(
                        (item) => item.type === this.selectedTab
                    );
                } else {
                    //If FILTER ALL, FETCH ALL DATA FROM API
                    this.clientTableDataResult = this.clientTableData;
                }

                if (text != undefined) {
                    this.searchText = text;
                }

                // Filter based on search text
                if (this.clientTableDataResult !== null) {
                    // Filter based on search text
                    if (this.searchText.trim() !== "") {
                        const searchText = this.searchText.toLowerCase().trim();
                        this.clientTableDataResult =
                            this.clientTableDataResult.filter(
                                (item) =>
                                    (item.pack_name &&
                                        item.pack_name
                                            .toLowerCase()
                                            .includes(searchText))
                            );
                    }
                }

                //RESET PAGE BACK TO 1
                this.currentPage = 1;
                // Slice the data based on pagination
                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                const endIndex = startIndex + this.itemsPerPage;

                //ASSIGN HOW MANY PAGES FROM THE FILTERED DATA RESULT
                this.maxPage = Math.ceil(
                    this.clientTableDataResult.length / this.itemsPerPage
                );

                //Assign FINAL Value OF PAGE DATA
                if (this.clientTableDataResult.length > 0) {
                    this.clientTableDataResult = this.clientTableDataResult.slice(
                        startIndex,
                        endIndex
                    );
                } else {
                    this.clientTableDataResult = null;
                }
            },

            PaginationHandling() {
                //FILTER THE SELECTED DATA
                if (this.selectedTab != "") {
                    this.clientTableDataResult = this.clientTableData.filter(
                        (item) => item.type === this.selectedTab
                    );
                } else {
                    this.clientTableDataResult = this.clientTableData;
                }

                // Apply text search filter if searchText is not empty
                if (this.searchText != "") {
                    const searchText = this.searchText.toLowerCase().trim();
                    this.clientTableDataResult = this.clientTableDataResult.filter(
                        (item) =>
                            (item.pack_name &&
                                item.pack_name
                                    .toLowerCase()
                                    .includes(searchText))
                    );
                }

                // Slice the data based on pagination
                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                const endIndex = startIndex + this.itemsPerPage;

                this.maxPage = Math.ceil(
                    this.clientTableDataResult.length / this.itemsPerPage
                );

                //Assign FINAL Value OF PAGE DATA
                if (this.clientTableDataResult.length > 0) {
                    this.clientTableDataResult = this.clientTableDataResult.slice(
                        startIndex,
                        endIndex
                    );
                    this.clientTableDataResult.forEach((dataObj) => {
                        if (this.currentPage > 1) {
                            this.indexNumbering++;
                        }

                        dataObj.numbering = this.indexNumbering;
                        if (this.currentPage == 1) {
                            this.indexNumbering++;
                        }
                    });
                } else {
                    this.clientTableDataResult = null;
                }
            },

            formatDate(inputDateStr) {
                // Create a new Date object from the input string
                const inputDate = new Date(inputDateStr);

                // Extract year, month, and day components from the Date object
                const year = inputDate.getFullYear();
                const month = inputDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month (0 = January)
                const day = inputDate.getDate();

                // Pad single-digit month, day, and minutes values with leading zeros
                const formattedMonth = month.toString().padStart(2, '0');
                const formattedDay = day.toString().padStart(2, '0');

                // Construct the formatted date string in dd/mm/yyyy format
                const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

                return formattedDate;
            },

            //--------API HANDLING -----------------//
            checkImageIcon(image) {
                return image || this.packageImgIconPath;
            },
            notificationError(message) {
                toast(message, {
                    theme: "colored",
                    type: "error",
                });
            },
            notificationWarning(message) {
                toast(message, {
                    theme: "colored",
                    type: "warning",
                });
            },
            notificationSuccess(message) {
                toast(message, {
                    theme: "colored",
                    type: "success",
                });
            },

            handleFileChange1(event) {
                // Clear existing files
                this.selectedFiles1 = [];

                // Iterate through selected files
                Array.from(event.target.files).forEach((file) => {
                    // Check if the file is an image
                    if (file.type.startsWith("image/")) {
                        // Create a FileReader to read the image file
                        const reader = new FileReader();

                        // Define FileReader onload event to set image preview
                        reader.onload = () => {
                            this.selectedFiles1.push({
                                file: file,
                                preview: reader.result, // Set the image preview URL
                            });
                            this.image = this.selectedFiles1[0].preview;
                        };

                        // Read the image file as a data URL
                        reader.readAsDataURL(file);
                    }
                });
            },

            async loadListProduct() {
                this.tableloader = true;

                try {
                    await listProduct(this.$router).then((response) => {
                        if (response.data.message === "success") {
                            this.clientTableData = response.data.data;
                            this.tableloader = false;
                            this.currentPage = 1;
                            this.PaginationHandling();
                            this.FilterHandling();
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            },

            // delete data
            async deletePackage(id) {
                const dataPass = {
                    packageId: id,
                };

                try {
                    await packageDelete(dataPass).then((response) => {
                        if (response.data.message === "success") {
                            this.clearModalProduct();
                            this.loadListProduct();
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            },

            intializeEditPackage(dataTable) {
                this.productId = dataTable.id;
                this.selectedFiles1 = [];
                this.image = dataTable.image;
                if (dataTable.type == null || dataTable.type == '') {
                    this.type = '';
                } else {
                    this.type = dataTable.type;
                }
                this.name = dataTable.name;
                this.price = dataTable.price;
                this.weight = dataTable.weight;
                this.description = dataTable.description;
                if (dataTable.status == null || dataTable.status == '') {
                    this.status = '';
                } else {
                    this.status = dataTable.status;
                }
                this.tier1 = dataTable.tier1;
                this.tier2 = dataTable.tier2;
                this.tier3 = dataTable.tier3;
                this.tier4= dataTable.tier4;
                this.tier5 = dataTable.tier5;
                console.log(this.productId);
            },

            clearModalProduct() {
                this.productId = null;
                this.selectedFiles1 = [];
                this.type = '';
                this.name = null;
                this.price = null;
                this.weight = null;
                this.description = null;
                this.status = '';
                this.tier1 = null;
                this.tier2 = null;
                this.tier3 = null;
                this.tier4 = null;
                this.tier5 = null;
            },

            // save data
            async saveProduct() {
                this.formData = new FormData();
                this.formData.productId= this.productId;
                this.formData.type = this.type;
                this.formData.image = this.selectedFiles1;
                this.formData.name = this.name;
                this.formData.price = this.price;
                this.formData.weight = this.weight;
                this.formData.description = this.description;
                this.formData.status = this.status;
                this.formData.tier1 = this.tier1;
                this.formData.tier2 = this.tier2;
                this.formData.tier3 = this.tier3;
                this.formData.tier4= this.tier4;
                this.formData.tier5 = this.tier5;

                if (this.productId === null) {
                    try {
                        await productAdd(this.formData).then((response) => {
                            if (response.data.message === "success") {
                                this.clearModalProduct();
                                this.loadListProduct();
                            } else {
                                this.notificationError("Something went wrong!");
                            }
                        });
                    } catch (error) {
                        this.notificationError("Something went wrong!");
                    }
                } else {
                    try {
                        await productEdit(this.formData).then((response) => {
                            if (response.data.message === "success") {
                                this.clearModalProduct();
                                this.loadListProduct();
                            } else {
                                this.notificationError("Something went wrong!");
                            }
                        });
                    } catch (error) {
                        this.notificationError("Something went wrong!");
                    }
                }
            },
        },
    };
    </script>
